import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useContext,
} from "react"
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  SwipeableDrawer,
  Typography,
  withWidth,
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import { AppContext } from "context"
import { makeStyles } from "@material-ui/styles"
import { useMenu, useAuth } from "hooks"
import {
  MenuItem,
  UserMenu,
  OnlineStatusAvatar,
} from "components"
import Logo from "assets/logo.svg"
import {
  NO_AUTH_ROUTES,
  MENU_ITEMS,
} from "lib/navigation"
import { animateScroll } from "react-scroll"
import {
  useNavigate,
  useLocation,
} from "react-router-dom"

const drawerWidth = 240

const Drawer = ({
  open,
  width,
  toggleMenu,
  ...props
}) => {
  const classes = useStyles()
  const history = useNavigate()

  const { pathname } = useLocation()

  const [isMobile, setIsMobile] = useState(false)

  const { authenticated, currentUser } =
    useContext(AppContext)
  console.log(currentUser)
  const { fetchMe, logoutUser } = useAuth()

  const {
    open: openMenu,
    anchorEl,
    closeMenu,
    handleToggleMenu,
  } = useMenu()

  const handleLogoutClick = () => logoutUser()

  const handleClick = (url) => {
    closeMenu()
    toggleMenu()
    animateScroll.scrollToTop()
    history(url)
  }

  useEffect(() => {
    if (
      !currentUser &&
      !NO_AUTH_ROUTES.includes(pathname)
    ) {
      fetchMe()
    }
  }, [authenticated])

  useLayoutEffect(() => {
    setIsMobile(
      ["xs", "sm"].includes(width) ? true : false
    )
  }, [width])

  return (
    <SwipeableDrawer
      open={open}
      variant={isMobile ? "temporary" : "permanent"}
      onOpen={toggleMenu}
      onClose={toggleMenu}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        height="100%"
      >
        <List
          component="nav"
          className={classes.list}
        >
          <Box p={4}>
            <center>
              <img
                src={Logo}
                className={classes.logo}
              />
            </center>
          </Box>
          {MENU_ITEMS[
            currentUser ? "loggedIn" : "loggedOut"
          ].map((menu, idx) => (
            <MenuItem
              key={idx}
              active={pathname === menu.value}
              value={menu.value}
              text={menu.text}
              icon={menu.icon}
              handleClick={handleClick}
            />
          ))}
        </List>
      </Box>
      {currentUser && (
        <List className={classes.onlineStatusList}>
          <ListItem>
            <ListItemIcon>
              <OnlineStatusAvatar
                online={true}
                user={currentUser}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="h5"
                  className={classes.text}
                >
                  {`${currentUser.first_name} ${currentUser.last_name}`}
                </Typography>
              }
              secondary={
                <Typography
                  variant="body2"
                  className={classes.text}
                >
                  {currentUser.role}
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <IconButton onClick={handleToggleMenu}>
                <ExpandMore
                  className={classes.icon}
                />
              </IconButton>
              <UserMenu
                open={openMenu}
                anchorEl={anchorEl}
                handleToggleMenu={handleToggleMenu}
                handleLogoutClick={handleLogoutClick}
                handleClick={handleClick}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}
    </SwipeableDrawer>
  )
}

export default withWidth()(Drawer)

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 200,
  },
  list: {
    backgroundColor: theme.palette.secondary.main,
    width: "300px",
    height: "100%",
  },
  icon: {
    color: "white",
  },
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: theme.typography.fontWeightMedium,
  },
  button: {
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    fontSize: "14px",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  text: {
    color: theme.palette.common.white,
  },
  secondaryIcon: {
    marginLeft: "auto",
    height: 20,
    width: 20,
  },
  onlineStatusList: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}))
