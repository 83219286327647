import React, { useEffect, useState } from "react"
import api from "api"
import { useAlerts, useLoaders } from "hooks"
import { useNavigate } from 'react-router-dom';

/* useResource
  A base hook that allows for CRUD operations of a REST API that follows
  standard REST patterns of GET POST PUT and DELETE to create, update, create and
  destroy objects.

  @param id - The resource ID to auto fetch
  @param url - The API endpoint. The is set dynamically using setEndpoint
  @param name - The name of the resource needed when using POST and PUT
*/

const useResource = ({
  url = "/",
  name,
  ...props
}) => {
  const history = useNavigate()
  const { isLoading, showLoading, hideLoading } =
    useLoaders()
  const {
    showAlertError,
    showAlertSuccess,
    showAlertWarning,
  } = useAlerts()

  const [isLoaded, setIsLoaded] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [id, setId] = useState(props.id)
  const [resource, setResource] = useState({
    id: props.id,
  })
  const [resourceDTO, setResourceDTO] = useState({
    id: props.id,
  })
  const [resources, setResources] = useState([])
  const [meta, setMeta] = useState({})
  const [params, setParams] = useState({})
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [numPages, setNumPages] = useState(1)

  const [sortKey, setSortKey] = useState(
    `${name}s.id`
  )
  const [sortDirection, setSortDirection] =
    useState("desc")

  const [totalCount, setTotalCount] = useState(0)
  const [errors, setErrors] = useState([])

  /**
   * 
   * @param {*} id 
   * @param {*} plural Plural form for the resource from the URL. It will be appended at the end of the URL.
   * @param {*} customUrl Override the URL
   * @returns 
   */
  const findOne = async (id, customUrl = "") => {
    if (!id) showAlertError("No ID was provided")
    try {
      showLoading()
      //setIsLoaded(false)
      setId(id)
      const res = await api.get(customUrl == "" ? `${url}/${id}` : `${customUrl}/${id}`)
      // console.log(res.data)
      setResource(res.data)
      const resClone = JSON.parse(JSON.stringify(res.data));
      setResourceDTO(resClone)
      setMeta(res.meta)
      setIsLoaded(true)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
    hideLoading()
  }

  const findMany = async (
    params,
    page = 1,
    loadMore = false,
    customUrl = ""
  ) => {

    try {
      showLoading()
      setParams(params)
      setPage(page)
      if (!loadMore) {
        setIsLoaded(false)
      }
      const res = await api.get(customUrl == "" ? url : customUrl, {
        params: {
          ...params,
          page,
        },
      })

      // delete res.data.data[0].tags

      hideLoading()
      if (res.data) {
        if (!loadMore) {
          // Data is set differently depending on how the API data is structured
          if(res.data.current_page !== undefined) {
            setResources(res.data.data)
          } else {
            // console.log('cur page')
            setResources(res.data)
          }
        } else {
          setResources([...resources, ...res.data])
        }
        // if (res.meta) {
        //   console.log('abc')
        //   setMeta(res.meta)
        //   setPage(res.meta.page)
        //   setPerPage(res.meta.per_page)
        //   setNumPages(res.meta.num_pages)
        //   setTotalCount(res.meta.total_count)
        // } 
        // // Set meta data related to the new API
        // else {
        //   console.log('res meta')
          
        // }
        
        // Set pagination data if the request has it
        if(Array.isArray(res.data) === false) {
          setPage(res.data.current_page)
          setPerPage(res.data.per_page)
          setNumPages(res.data.last_page)
          setTotalCount(res.data.total)
        }

        // Data is set differently depending on how the API data is structured
        if(res.data.current_page !== undefined) {
          setIsEmpty(
            res?.data?.data?.length > 0 ? false : true
          )
        } else {
          // console.log('set2')
          setIsEmpty(
            res?.data?.length > 0 ? false : true
          )
        }

        setIsLoaded(true)
        return res.data
      }
      hideLoading()
    } catch (e) {
      handleErrors(e)
    }
    hideLoading()
  }

  const loadMore = () => {
    let nextPage = page + 1
    let appendResults = true
    findMany(params, nextPage, appendResults)
  }

    /**
   * 
   * @param {*} resource 
   * @param {*} files 
   * @param {*} customUrl 
   * @returns 
   */
  const create = async (
    resource,
    files,
    customUrl = ""
  ) => {
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
      // console.log(resource)
      let formData = new FormData()
      for (const item in resource) {
        formData.append(
          item,
          resource[item]
        )
      }
      if (files && Object.keys(files).length) {
        for (const key in files) {
          formData.append(
            key,
            files[key].file
          )
        }
      }
      
      // for (var key of formData.entries()) {
      //   console.log(key);
      // }

      const res = await api.post(
        customUrl == "" ? `${url}/create` : customUrl,
        formData,
        config
      )
      if (res.data && res.data.id) {
        setResource(res.data)
        setIsLoaded(true)
        setId(res.data.id)
      }
      hideLoading()
      return res.data
    } catch (e) {
      console.log(e)
      showAlertError("There was an issue saving")
      handleErrors(e)
    }
  }

  // const create = async (
  //   resource,
  //   customUrl = ""
  // ) => {
  //   console.log(resource)
  //   try {
  //     showLoading()
  //     const res = await api.post(
  //       customUrl == "" ? `${url}/create` : customUrl,
  //       {
  //         ...resource,
  //       }
  //     )
  //     if (res.data && res.data.id) {
  //       setResource(res.data)
  //       setIsLoaded(true)
  //       setId(res.data.id)
  //     }
  //     hideLoading()
  //     return res.data
  //   } catch (e) {
  //     console.log(e)
  //     showAlertError("There was an issue saving")
  //     handleErrors(e)
  //   }
  // }

  // /**
  //  * 
  //  * @param {*} resource 
  //  * @param {*} customUrl 
  //  * @returns 
  //  */
  // const update = async (resource, customUrl="") => {
  //   setId(resource.id)
  //   try {
  //     showLoading()
  //     const res = await api.post(
  //       customUrl == "" ? `${url}/update/${resource.id}` : `${customUrl}/${resource.id}`,
  //       {
  //         ...resource,
  //       }
  //     )
  //     hideLoading()
  //     return res.data
  //   } catch (e) {
  //     showAlertError("There was an issue updating")
  //     handleErrors(e)
  //   }
  // }

    /**
   * 
   * @param {*} resource 
   * @param {*} customUrl 
   * @param {*} files 
   * @returns 
   */
    const update = async (resource, customUrl="", files=null) => {
      console.log("*******IN CASE SOMETHING DOESN'T WORK WITH UPDATE, I'VE COMMENTED THE ORIGINAL UPDATE CODE AND ADDED THE FORMDATA UPDATE FUNCTION")
      setId(resource.id)
      try {
        showLoading()
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
        // console.log(resource)
        let formData = new FormData()
        for (const item in resource) {
          formData.append(
            item,
            resource[item]
          )
        }
        if (files && Object.keys(files).length) {
          for (const key in files) {
            formData.append(
              key,
              files[key].file
            )
          }
        }
        
        // for (var key of formData.entries()) {
        //   console.log(key);
        // }
  
        const res = await api.post(
          customUrl == "" ? `${url}/update/${resource.id}` : `${customUrl}/${resource.id}`,
          formData,
          config
        )
        if (res.data && res.data.id) {
          setResource(res.data)
          setIsLoaded(true)
          setId(res.data.id)
        }
  
        hideLoading()
        return res.data
      } catch (e) {
        showAlertError("There was an issue updating")
        handleErrors(e)
      }
    }

  const destroy = async (resource, customUrl = "") => {
    try {
      showLoading()
      const res = await api.delete(
        customUrl == "" ? `${url}/${resource.id}` : `${customUrl}/${resource.id}`,
      )
      
      setResource({ data: {} })
      hideLoading()
    } catch (e) {
      handleErrors(e)
    }
  }

  const paginate = (page, customUrl="") => {
    findMany(params, page, false, customUrl)
  }

  const uploadFile = async (
    file,
    attributeName,
    _id
  ) => {
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
      let formData = new FormData()
      formData.append(
        `${name}[${attributeName}]`,
        file
      )
      const res = await api.post(
        `${url}/${_id || id}/upload_file`,
        formData,
        config
      )
    } catch (e) {
      showAlertError("There was an issue uploading")
      handleErrors(e)
    }
  }

  const deleteFile = async (type) => {
    // showLoading()
    // await api.post(`${url}/${id}/delete_file`, {
    //   type,
    // })
    // hideLoading()
  }

  const handleChange = (ev) => {
    const { name } = ev.target
    const value =
      ev.target.type === "checkbox"
        ? ev.target.checked
        : ev.target.value
    setResource({
      ...resource,
      [name]: value,
    })
  }

  const handleErrors = (e) => {
    hideLoading()
    setIsLoaded(false)
    setErrors(e)
    console.error("useResource Error:", e)
    if (e?.status == 401) {
      showAlertError("Please Sign In to continue")
      localStorage.removeItem("token")
      setTimeout(
        () => (window.location.href = "/login"),
        1000
      )
    }
    return false
  }

  const reloadOne = () => findOne(id)
  const reloadMany = (customUrl = "") => findMany(params, 1, false, customUrl)

  const handleSort = (sortBy) => {
    // console.log("handleSort")
    sortDirection == "asc"
      ? setSortDirection("desc")
      : setSortDirection("asc")
    setSortKey(sortBy)
    history(
      `?sort_key=${sortKey}&sort_direction=${sortDirection}`
    )
  }

  useEffect(() => {
    if (props.id) setId(props.id)
  }, [props.id])

  return {
    id,
    isLoading,
    isLoaded,
    isEmpty,
    resource,
    resourceDTO,
    resources,
    setResource,
    setResources,
    errors,
    meta,
    findOne,
    findMany,
    update,
    create,
    destroy,
    paginate,
    loadMore,
    handleChange,
    uploadFile,
    deleteFile,
    params,
    page,
    perPage,
    numPages,
    totalCount,
    reloadOne,
    reloadMany,
    sortKey,
    sortDirection,
    handleSort,
    setPage
  }
}

export default useResource
