import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useLessons, useForm, useAlerts } from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Tab,
  Tabs,
  Container,
} from "@material-ui/core"
import {
  VideoUploader,
  ImageUploader,
  Subheader,
} from "components"
import { Check } from "@material-ui/icons"
import LessonForm from "containers/lessons/LessonForm"
import { validateLesson } from "validations/lessons"
import { UpdateResourceDTO } from "lib/helpers";
import usePositions from "hooks/usePositions";

const LessonEdit = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()
  const [tabValue, setTabValue] = useState(0)

  const {
    lessonId,
    isLoading,
    isLoaded,
    lesson,
    lessonDTO,
    handleChange,
    updateLesson,
    findLesson,
    uploadFile,
    deleteFile,
  } = useLessons({ id: id })

  const {
    positionId,
    positions,
    findPositions,
  } = usePositions({
    id: id,
  })
  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const [previewVideo, setPreviewVideo] = useState()
  const [video, setVideo] = useState()
  const [formattedPositions, setformattedPositions] = useState([])

  // const [lessonDTO, setLessonDTO] = useState();

  const handleSaveClick = async () => {
    const valid = validateLesson(lesson, {
      lesson_video: video,
      lesson_preview_video: previewVideo
    })

    if (valid.isValid) {
      if(Array.isArray(lesson.what_you_need)) {
        lesson.what_you_need = lesson.what_you_need.join(',')
      }

      lesson.control_points = String(lesson.control_points)
      lesson.defending_points = String(lesson.defending_points)
      lesson.passing_points = String(lesson.passing_points)
      lesson.shooting_points = String(lesson.shooting_points)
      lesson.smart_points = String(lesson.smart_points)
      lesson.star_points = String(lesson.star_points)
      lesson.take_on_points = String(lesson.take_on_points)
      lesson.course_id = String(lesson.course_id)

      lesson.control_points = lesson.control_points == null ? 0 :lesson.control_points
      lesson.defending_points = lesson.defending_points == null ? 0 :lesson.defending_points
      lesson.dribbling_points = lesson.dribbling_points == null ? 0 :lesson.dribbling_points
      lesson.passing_points = lesson.passing_points == null ? 0 :lesson.passing_points
      lesson.shooting_points = lesson.shooting_points == null ? 0 :lesson.shooting_points
      lesson.star_points = lesson.star_points == null ? 0 :lesson.star_points
      lesson.take_on_points = lesson.take_on_points == null ? 0 :lesson.take_on_points
      lesson.smart_points = lesson.smart_points == null ? 0 :lesson.smart_points

      lesson.premium = lesson.premium ? 1 : 0
      console.log(lesson)
      let tempLesson = JSON.parse(JSON.stringify(lesson));
      
      if(lesson.lesson_preview_video != undefined) {
        tempLesson.lesson_preview_video = lesson.lesson_preview_video
      }

      if(lesson.lesson_video != undefined) {
        tempLesson.lesson_video = lesson.lesson_video
      }

      console.log(tempLesson)
      for(const prop in lesson) {
        if(prop != "id"){
          // Remove props if there is no change and update DTO obj if props change
          if(lesson[prop] == lessonDTO[prop]) {
            delete tempLesson[prop]
          } else if((Array.isArray(lesson[prop]) || typeof lesson[prop] === 'object') && JSON.stringify(lesson[prop]) == JSON.stringify(lessonDTO[prop])) {
            delete tempLesson[prop]
          } else if(lessonDTO[prop] != undefined) {
            tempLesson[prop] = lesson[prop]
          }
        }
      }

      delete tempLesson.created_at
      delete tempLesson.updated_at
      delete tempLesson.image_url
      delete tempLesson.video
      delete tempLesson.skills_lesson

      // console.log("DTO", lessonDTO)
      // console.log("RESOURCE", lesson)
      console.log(tempLesson)
      console.log(lesson)
      await updateLesson(tempLesson, '/api/admin/lessons/update')
      showAlertSuccess("Lesson has been updated")
      // console.log("REMOVE THIS COMMENT FROM HERE");
      history(`/lessons/${id}`)
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  const handleUpload = async (file, type) => {
    if(type == "previewVideo") {
      setPreviewVideo(file.preview)
      console.log(file)
      lesson.lesson_preview_video = file
    } else if(type == "video") {
      setVideo(file.preview)
      lesson.lesson_video = file
    }
    console.log(lesson)
    // await uploadFile(file, type)
    // findLesson(id)
  }

  const handleDelete = async (type) => {
    await deleteFile(type)
    findLesson(id)
  }

  useEffect(() => {
    if (id) findLesson(id)
    findPositions()
  }, [])

  useEffect(() => {
    setPreviewVideo(lesson.video?.preview_video_url)
    setVideo(lesson.video?.video_url)
  }, [lesson])

  // useEffect(() => {
  //   if(Array.isArray(positions) && positions.length > 0) {
  //     let tempPos = []
  //     positions.forEach((position) => {
  //       tempPos.push({
  //         value: position.id,
  //         label: position.name
  //       })
  //     })

  //     setformattedPositions(tempPos)
  //   }

  //   // console.log(formattedPositions)
  // }, [positions])

  // console.log(lesson)
  // console.log(positions)

  // console.log(lesson.position)
  // console.log(positions)
  return (
    <>
      <Subheader
        title="Edit Lesson"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>,
        ]}
      />
      <Container>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab label="Details" value={0} />
          <Tab label="Media" value={1} />
        </Tabs>
        <Paper>
          <Box p={4}>
            {tabValue == 1 ? (
              <div>
                <VideoUploader
                  label="Upload preview video"
                  videoUrl={previewVideo}
                  handleUpload={(file) =>
                    handleUpload(file, "previewVideo")
                  }
                  handleDelete={() =>
                    setPreviewVideo(undefined)
                  }
                />
                <VideoUploader
                  label="Upload video"
                  videoUrl={video}
                  handleUpload={(file) =>
                    handleUpload(file, "video")
                  }
                  handleDelete={() =>
                    setVideo(undefined)
                  }
                />
                {/* <VideoUploader
                  label="Upload web preview video"
                  videoUrl={lesson.web_video_url}
                  handleUpload={(file) =>
                    handleUpload(
                      file,
                      "web_video_url"
                    )
                  }
                  handleDelete={() =>
                    handleDelete("web_video_url")
                  }
                /> */}
              </div>
            ) : (
              <LessonForm
                lesson={lesson}
                handleChange={handleChange}
                editPage={true}
                positions={positions}
              />
            )}
            <Box py={2}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Check />}
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default LessonEdit
