import React from "react"
import { Grid } from "@material-ui/core"
import {
  ClubAutosuggest,
  Autosuggest,
  SwitchInput,
  TextInput,
  MultiAutosuggest,
} from "components"
import {
  USER_ROLES,
  PREFERRED_FOOT,
  PAYMENT_PLATFORMS,
  POSITIONS,
} from "lib/constants"
import api from "api"
import { API_URL } from "config/secrets"
// import { COUNTRIES } from "lib/constants/countries"





// const Countries = findCountries().then((cnt) => {
//   return cnt.data
// });
// console.log(await findCountries());

// const getCountries = () => {
//   let resp = fetch(`${API_URL}/api/countries`)
//     .then(j => console.log(j))
//     // .then(data => console.log(data))
//     // return resp.data
// }

// const [countries1, setCountries] = useState()

// const getCountries = async () => {
//   let resp = await api.get(`/api/countries`)
//   return resp.data
// }

// getCountries().then(data => setCountries(data));

// console.log(countries1)


const UserForm = ({
  user,
  isEditing,
  countries,
  nationalities,
  handleChange,
  editPage
}) => {
  console.log(countries);
  return (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={user.first_name}
        label="First name*"
        name="first_name"
        placeholder="First name"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={user.last_name}
        label="Last name*"
        name="last_name"
        placeholder="Last name"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={user.email}
        label="Email*"
        name="email"
        placeholder="Email"
        handleChange={handleChange}
        disabled={editPage}
      />
    </Grid>
    {editPage == false && (
      <>
        <Grid item xs={12} sm={12}>
          <TextInput
            value={user.password}
            label="Password*"
            name="password"
            type="password"
            placeholder="Password"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            value={user.password_confirmation}
            label="Password confirmation*"
            name="password_confirmation"
            type="password"
            placeholder="Password confirmation"
            handleChange={handleChange}
          />
        </Grid>
      </>
    )}
    <Grid item xs={12} sm={12}>
      <Autosuggest
        options={countries}
        value={user.country_id}
        label="Country*"
        name="country_id"
        placeholder="Country"
        handleChange={handleChange}
        editPage={editPage}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <Autosuggest
        options={nationalities}
        value={user.nationality_id}
        label="Nationality"
        name="nationality_id"
        placeholder="Nationality"
        handleChange={handleChange}
        editPage={editPage}
      />
    </Grid>
    {/* <Grid item xs={12} sm={12}>
      <Autosuggest
        options={countries}
        value={user.passport_country}
        label="Passport Country"
        name="passport_country"
        placeholder="Passport country"
        handleChange={handleChange}
      />
    </Grid> */}
    <Grid item xs={12} sm={12}>
      <Autosuggest
        options={USER_ROLES}
        value={user.role}
        label="Role*"
        name="role"
        placeholder="Role"
        handleChange={handleChange}
        editPage={editPage}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <Autosuggest
        value={user.preferred_foot}
        label="Preferred foot"
        name="preferred_foot"
        placeholder="Preferred foot"
        options={PREFERRED_FOOT}
        handleChange={handleChange}
        editPage={editPage}
      />
    </Grid>
    {/* <Grid item xs={12} sm={12}>
      <MultiAutosuggest
        options={POSITIONS}
        value={user.positions || []}
        label="Positions"
        name="positions"
        placeholder="Positions"
        handleChange={handleChange}
      />
    </Grid> */}
    <Grid item xs={12} sm={12}>
      <TextInput
        value={user.dob}
        label="Birthday*"
        name="dob"
        placeholder="Birthday"
        type="date"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={user.height}
        label="Height"
        name="height"
        placeholder="Height (CM)"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={user.weight}
        label="Weight"
        name="weight"
        placeholder="Weight (KG)"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={user.shirt_number}
        label="Shirt number"
        name="shirt_number"
        placeholder="Shirt number"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={user.favorite_boot}
        label="Favorite boot"
        name="favorite_boot"
        placeholder="Favorite boot"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={user.favorite_brand}
        label="Favorite brand"
        name="favorite_brand"
        placeholder="Favorite brand"
        handleChange={handleChange}
      />
    </Grid>
    {/* <Grid item xs={12} sm={12}>
      <TextInput
        value={user.current_club}
        label="Current club"
        name="current_club"
        placeholder="Current club"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={user.promo_code}
        label="Promo code"
        name="promo_code"
        placeholder="Promo code"
        handleChange={handleChange}
      />
    </Grid> */}
    <Grid item xs={12} sm={12}>
      <ClubAutosuggest
        value={user.club_id}
        label="Club ID"
        name="club_id"
        placeholder="Select club"
        handleChange={handleChange}
        editPage={editPage}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <ClubAutosuggest
        value={user.favorite_club_id}
        label="Favorite club"
        name="favorite_club"
        placeholder="Favorite club"
        handleChange={handleChange}
        editPage={editPage}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <SwitchInput
        value={user.agent ? true : false}
        label="Agent*"
        name="agent"
        placeholder="Agent"
        handleChange={handleChange}
      />
    </Grid> 
    <Grid item xs={12} sm={12}>
      <SwitchInput
        value={user.contract ? true : false}
        label="Contract*"
        name="contract"
        placeholder="Contract"
        handleChange={handleChange}
      />
    </Grid>
    {/* <Grid item xs={12} sm={12}>
      <Autosuggest
        options={PAYMENT_PLATFORMS}
        value={user.payment_platform}
        label="Payment Platform"
        name="payment_platform"
        placeholder="Payment Platform"
        handleChange={handleChange}
      />
    </Grid> */}
    {/* <Grid item xs={12} sm={12}>
      <SwitchInput
        value={user.subscribed ? true : false}
        label="Subscribed"
        name="subscribed"
        placeholder="subscribed"
        handleChange={handleChange}
      />
    </Grid> */}
    {/* <Grid item xs={12} sm={12}>
      <SwitchInput
        value={user.club_approved ? true : false}
        label="Club approved"
        name="club_approved"
        placeholder="Club approved"
        handleChange={handleChange}
      />
    </Grid> */}
    {/* <Grid item xs={12} sm={12}>
      <TextInput
        disabled
        value={user.veriff_id}
        label="Veriff session ID"
        name="veriff_id"
        placeholder="Veriff session ID"
        handleChange={handleChange}
      />
    </Grid> */}
    {/* <Grid item xs={12} sm={12}>
      <SwitchInput
        value={user.veriff_verified ? true : false}
        label="Veriff verified"
        name="veriff_verified"
        placeholder="Veriff verified"
        handleChange={handleChange}
      />
    </Grid> */}
    {/* <Grid item xs={12} sm={12}>
      <SwitchInput
        value={user.featured ? true : false}
        label="Player to watch"
        name="featured"
        placeholder="Player to watch"
        handleChange={handleChange}
      />
    </Grid> */}
  </Grid>
  )
}

export default UserForm
