import React, { useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useVideos, useAlerts } from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import { Subheader } from "components"
import VideoDetails from "containers/videos/VideoDetails"

const VideoShow = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const {
    videoId,
    isLoading,
    isLoaded,
    video,
    findVideo,
  } = useVideos({
    id: id,
  })

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleEditClick = () =>
    history(`/videos/${id}/edit`)

  useEffect(() => {
    if (id) findVideo(id, '/api/video/captured')
  }, [])

  console.log(video)

  return (
    <>
      <Subheader
        title={
          isLoading
            ? "Loading..."
            : `Video ${video.id}`
        }
        buttons={[
          // <Button
          //   onClick={handleEditClick}
          //   color="primary"
          //   variant="contained"
          // >
          //   Edit
          // </Button>,
        ]}
      />
      <Container>
        <Paper>
          <Box p={4}>
            <VideoDetails video={video} />
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default VideoShow
