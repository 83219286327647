import React from "react"
import {
  Typography,
  makeStyles,
} from "@material-ui/core"
import MuiRating from "@material-ui/lab/Rating"
import { Lens } from "@material-ui/icons"
import { withStyles } from "@material-ui/core/styles"

const Rating = ({
  value,
  label,
  max = 5,
  size,
  ...rest
}) => {
  return (
    <div>
      <Typography component="legend">
        {label}
      </Typography>
      <StyledRating
        readOnly
        value={parseInt(value || 0)}
        max={max}
        size={size}
        icon={<Lens fontSize="inherit" />}
      />
    </div>
  )
}

export default Rating

const StyledRating = withStyles((theme) => ({
  iconFilled: {
    color: theme.palette.primary.main,
  },
  iconHover: {
    color: theme.palette.primary.main,
  },
}))(MuiRating)
