import React from "react"
import { useMenu } from "hooks"
import {
  Hidden,
  Button,
  IconButton,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Avatar,
  Checkbox,
} from "@material-ui/core"
import { Image, Label } from "components"
import { MoreHoriz } from "@material-ui/icons"

const CourseItem = ({
  course,
  isSelectable,
  selectedIds = [],
  handleSelect,
  handleClick,
  handleEditClick,
  handleDeleteClick,
  ...props
}) => {
  const {
    open,
    anchorEl,
    handleToggleMenu,
    handleClose,
  } = useMenu()

  const renderMenu = () => (
    <Menu
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
    >
      <MenuItem
        onClick={(e) => handleEditClick(course, e)}
      >
        Edit
      </MenuItem>
      <MenuItem
        onClick={(e) => handleDeleteClick(course, e)}
      >
        Delete
      </MenuItem>
    </Menu>
  )

  const isSelected = selectedIds.includes(course.id)
  
  



  return (
    <TableRow>
      {isSelectable && (
        <TableCell>
          <Checkbox
            checked={isSelected ? true : false}
            onChange={() => handleSelect(course)}
            name="course"
            color="primary"
          />
        </TableCell>
      )}
      <TableCell>
        <Button onClick={() => handleClick(course)}>
          <Image
            src={course.image_url}
            variant="rounded"
            size={80}
          />
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(course)}>
          {course.title}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(course)}>
          {course?.categories?.map((c, i) => (
            <Label label={c.name} key={i} />
          ))}

          {/* {Object.keys(course.category).forEach((key, i) => (
            <Label label={course.category[key]} key={i} />
          ))} */}

          {
            Array.isArray(course.category) ?
              course.category.forEach((c, i) => (
                <Label label={c} key={i} />
              ))
            : ""
          }
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(course)}>
          {course.difficulty}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(course)}>
          {course.trainer}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(course)}>
          {course.featured ? "Yes" : "No"}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(course)}>
          {course.published ? "Yes" : "No"}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(course)}>
          {course.recommended ? "Yes" : "No"}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(course)}>
          {course.premium ? "Yes" : "-"}
        </Button>
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={(e) => handleToggleMenu(e)}
        >
          <MoreHoriz />
          {renderMenu()}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default CourseItem
