import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import {
  useBenefits,
  useForm,
  useAlerts,
  useUploadFileInMemory,
} from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { ImageUploader, Subheader } from "components"
import { Check } from "@material-ui/icons"
import BenefitForm from "containers/benefits/BenefitForm"
import { validateBenefit } from "validations/benefits";

const BenefitEdit = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const [image, setImage] = useState()

  const {
    benefitId,
    isLoading,
    isLoaded,
    benefit,
    benefits,
    benefitDTO,
    handleChange,
    updateBenefit,
    findBenefit,
    findBenefits,
    uploadFile,
    deleteFile,
  } = useBenefits({ id: id })

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleSaveClick = async () => {
    const valid = validateBenefit(benefit, {
      image: image
    })
    if (valid.isValid) {
      benefit.active = benefit.active ? 1 : 0
      benefit.is_promotion = benefit.is_promotion ? 1 : 0

      let tempBenefit = JSON.parse(JSON.stringify(benefit));

      for(const prop in benefit) {
        if(prop != "id"){
          // Remove props if there is no change and update DTO obj if props change
          if(benefit[prop] == benefitDTO[prop]) {
            // console.log(prop)
            delete tempBenefit[prop]
          } else if((Array.isArray(benefit[prop]) || typeof benefit[prop] === 'object') && JSON.stringify(benefit[prop]) == JSON.stringify(benefitDTO[prop])) {
            // console.log(prop)
            delete tempBenefit[prop]
          } else {
            tempBenefit[prop] = benefit[prop]
          }
        }
      }
      
      delete tempBenefit.image_url
      
      await updateBenefit(tempBenefit, "/api/admin/benefits/update")
      showAlertSuccess("Benefit has been updated")
      // console.log("REMOVE COMMENT FROM HERE")
      history(`/benefits/${id}`)
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }


  // const handleUpload = async (file, type) => {
  //   await uploadFile(file, type)
  //   findBenefit(id)
  // }

  const handleUpload = async (file, type) => {
    if(type == "image") {
      setImage(file.preview)
      benefit.image = file
    }
    // await uploadFile(file, type)
    // findCourse(id)
  }

  const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory()

  const handleDelete = async (type) => {
    await deleteFile(type)
    findBenefit(id)
  }

  useEffect(() => {
    if (id) findBenefit(id, "/api/benefit")

    findBenefits()
  }, [])

  useEffect(() => {
    setImage(benefit.image_url)
  }, [benefit])

  console.log(benefits)
  return (
    <>
      <Subheader
        title="Edit Benefit"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>,
        ]}
      />
      <Container>
        <Paper>
          <Box p={4}>
            <center>
            <ImageUploader
              label="Upload image"
              imageUrl={image}
              handleUpload={(file) =>
                handleUpload(file, "image")
              }
              handleDelete={() =>
                setImage(undefined)
              }
            />
            </center>
            <BenefitForm
              benefit={benefit}
              handleChange={handleChange}
              editPage={true}
              categories={benefits}
            />
            <Box py={2}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Check />}
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default BenefitEdit
