import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useLessons, useAlerts } from "hooks"
import {
  Box,
  Paper,
  Button,
  Tab,
  Tabs,
  Typography,
  Container,
} from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import { Subheader } from "components"
import LessonDetails from "containers/lessons/LessonDetails"
import LessonMedia from "containers/lessons/LessonMedia"
import CourseItem from "./CourseItem"
import VideoItem from "./VideoItem"
import { List } from "@material-ui/core"
import { orderBy } from "lodash"
import { CATEGORIES, GetLabelByValue, WHAT_YOU_NEED } from "lib/constants";

const LessonShow = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const [urls, setUrls] = useState([])
  const [tabValue, setTabValue] = useState(0)

  const {
    lessonId,
    isLoading,
    isLoaded,
    lesson,
    findLesson,
  } = useLessons({
    id: id,
  })

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleEditClick = () =>
    history(`/lessons/${id}/edit`)

  const handleCourseClick = (course) => {
    if (course.id) {
      history(`/courses/${course.id}`)
    }
  }

  const handleVideoClick = (video) => {
    if (video.id) {
      history(`/videos/${video.id}`)
    }
  }

  useEffect(() => {
    if (id) findLesson(id)
  }, [])

  useEffect(() => {
    // if (course?.lessons?.length) {
    //   setLessons(course.lessons)
    // }
    
    // Categories
    // if(lesson.category != undefined) {
    //   if(!Array.isArray(lesson.category)) {
    //     lesson.category = JSON.parse(lesson.category)
    //   }

    //   for(let i = 0; i < lesson.category.length; i++) {
    //     lesson.category[i] = GetLabelByValue(CATEGORIES, lesson.category[i])
    //   }
    // }

    // What you need
    if(lesson.what_you_need != undefined) {
      if(!Array.isArray(lesson.what_you_need)) {
        lesson.what_you_need = JSON.parse(lesson.what_you_need)
      }

      for(let i = 0; i < lesson.what_you_need.length; i++) {
        lesson.what_you_need[i] = GetLabelByValue(WHAT_YOU_NEED, lesson.what_you_need[i])
      }
    }
  }, [lesson])


  return (
    <>
      <Subheader
        title={
          isLoading ? "Loading..." : lesson.title
        }
        buttons={[
          <Button
            onClick={handleEditClick}
            color="primary"
            variant="contained"
          >
            Edit
          </Button>,
        ]}
      />
      <Container>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab label="Details" value={0} />
          <Tab label="Media" value={1} />
        </Tabs>
        <Paper>
          <Box p={4}>
            {tabValue == 0 ? (
              <LessonDetails lesson={lesson} />
            ) : (
              <LessonMedia lesson={lesson} />
            )}
          </Box>
        </Paper>
        <Box m={4} />
        {lesson.course && (
          <>
            <Typography variant="h5">
              Course
            </Typography>
            <Paper>
              <List>
                <CourseItem
                  course={lesson.course}
                  handleClick={() =>
                    handleCourseClick(lesson.course)
                  }
                />
              </List>
            </Paper>
            <Box m={4} />
          </>
        )}
        {lesson.videos?.length > 0 && (
          <>
            <Typography variant="h5">
              Videos
            </Typography>
            <Paper>
              <List>
                {orderBy(
                  lesson.videos,
                  ["title"],
                  ["desc"]
                ).map((video, i) => (
                  <VideoItem
                    key={i}
                    video={video}
                    handleClick={() =>
                      handleVideoClick(video)
                    }
                  />
                ))}
              </List>
            </Paper>
          </>
        )}
        <Box p={4} />
      </Container>
    </>
  )
}

export default LessonShow
