export const GOOGLE_API_KEY =
  process.env.REACT_APP_GOOGLE_API_KEY

export const USER_ROLES = [
  { value: "admin", label: "Admin" },
  // { value: "club", label: "Club" },
  // { value: "scout", label: "Scout" },
  { value: "user", label: "User" },
]

export const PAYMENT_PLATFORMS = [
  { value: "ios", label: "iOS" },
  { value: "web", label: "Web" },
  { value: "manual", label: "Manual" },
]

export const DIFFICULTY = [
  { value: "beginner", label: "Beginner" },
  { value: "intermediate", label: "Intermediate" },
  { value: "advanced", label: "Advanced" },
  { value: "professional", label: "Professional" },
]

// export const CATEGORIES = [
//   // positions
//   { value: "center_back", label: "Centre Back" },
//   { value: "full_back", label: "Full Back" },
//   { value: "midfielder", label: "Midfielder" },
//   { value: "winger", label: "Winger" },
//   { value: "striker", label: "Striker" },
//   // skills
//   { value: "control", label: "Ball Control" },
//   { value: "passing", label: "Passing" },
//   { value: "dribbling", label: "Dribbling" },
//   { value: "shooting", label: "Shooting" },
//   { value: "defending", label: "Defending" },
//   { value: "star", label: "Star Quality" },
// ]

export const CATEGORIES = [
  { value: 1, label: "dribbling" },
  { value: 2, label: "star" },
  { value: 3, label: "control" },
  { value: 4, label: "passing" },
  { value: 5, label: "shooting" },
  { value: 6, label: "defending" },

]

export const POSITIONS = [
  { value: "center_back", label: "Centre Back" },
  { value: "full_back", label: "Full Back" },
  { value: "midfielder", label: "Midfielder" },
  { value: "winger", label: "Winger" },
  { value: "striker", label: "Striker" }
]

export const WHAT_YOU_NEED = [
  { value: "1_player", label: "1 Player" },
  { value: "2_players", label: "2 Players" },
  { value: "3_players", label: "3 Players" },
  { value: "4_players", label: "4 Players" },
  { value: "5_players", label: "5 Players" },
  { value: "cone", label: "Cone" },
  { value: "goal", label: "Goal" },
  { value: "phone", label: "Phone" },
  { value: "football", label: "Football" },
  { value: "mannequin", label: "Mannequin" },
  { value: "poles", label: "Poles" },
]

export const PREFERRED_FOOT = [
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
]

export const SKILLS = [
  { value: "control_points", label: "Ball Control" },
  { value: "defending_points", label: "Defending" },
  { value: "dribbling_points", label: "Dribbling" },
  { value: "passing_points", label: "Passing" },
  { value: "shooting_points", label: "Shooting" },
  { value: "star_points", label: "Star Points" },
  { value: "take_on_points", label: "Take on Points" },
  { value: "smart_points", label: "Smart Points" },
]

/**
 * 
 * @param {*} constantsArr Array of constants from this file. (index.js)
 * @param {*} value Value to be returned
 */
export const GetLabelByValue = (constantsArr, value) => {
  for(let i = 0; i < constantsArr.length; i++) {
    if(constantsArr[i].value == value) {
      return constantsArr[i].label
    }
  }
}