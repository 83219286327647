import React from "react"
import api from "api"
import {
  useAlerts,
  useLoaders,
  useResource,
} from "hooks"
import { validateVideo } from "validations/videos"

const useVideos = ({ id, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } =
    useAlerts()

  const {
    resourceId: videoId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: video,
    resources: videos,
    findOne: findVideo,
    findMany: findVideos,
    update: updateVideo,
    create: createVideo,
    save: saveVideo,
    destroy: deleteVideo,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    refresh: reloadVideo,
    reloadMany: reloadVideos,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
  } = useResource({
    id: id,
    url: "/api/video/captures",
    name: "video",
  })

  const findVideoTags = async () => {
    let resp = await api.get(`/api/video/capture/tags`)
    return resp.data
  }

  return {
    videoId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    video,
    videos,
    findVideo,
    findVideos,
    saveVideo,
    updateVideo,
    createVideo,
    deleteVideo,
    uploadFile,
    deleteFile,
    findVideoTags,
    handleChange,
    handleChangePage,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    reloadVideo,
    reloadVideos,
  }
}

export default useVideos
