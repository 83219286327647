import React from "react"
import { Grid } from "@material-ui/core"
import {
  AsyncAutosuggest,
  Autosuggest,
  CheckboxGroupInput,
  RadioInput,
  SwitchInput,
  TextInput,
} from "components"
import Autosuggest2 from "components/Autosuggest2"

const BenefitForm = ({
  benefit,
  isEditing,
  handleChange,
  editPage,
  categories
}) => (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={benefit.title}
        label="Title*"
        name="title"
        placeholder="Title"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={benefit.expires_at}
        label="Expires at"
        name="expires_at"
        type="date"
        placeholder="mm/dd/yyyyy"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        rows={3}
        multiline
        value={benefit.body}
        label="Body*"
        name="body"
        placeholder="Body"
        handleChange={handleChange}
      />
    </Grid>

    <Grid item xs={12} sm={12}>
      <TextInput
        value={benefit.external_url}
        label="External URL"
        name="external_url"
        placeholder="External URL"
        handleChange={handleChange}
      />
    </Grid>

    <Grid item xs={12} sm={12}>
      {/* <AsyncAutosuggest
        label="Category"
        name="category"
        value={benefit.category || []}
        remoteUrl="/api/benefits?per_page=999999"
        displayField="title"
        singleUrl="/api/benefit"
        placeholder="Select category"
        handleChange={handleChange}
        editPage={editPage}
      /> */}


      {/* <Autosuggest2
        label="Category*"
        name="category"
        value={benefit.category}
        options={categories}
        placeholder="Select category"
        handleChange={handleChange}
        editPage={editPage}
        valueField="id"
        labelField="title"
      /> */}



    </Grid>

    <Grid item xs={12} sm={12}>
      <SwitchInput
        value={benefit.is_promotion ? true : false}
        label="Promotion*"
        name="is_promotion"
        placeholder="Promotion"
        handleChange={handleChange}
      />
    </Grid>

    <Grid item xs={12} sm={12}>
      <SwitchInput
        value={benefit.active ? true : false}
        label="Active*"
        name="active"
        placeholder="Active"
        handleChange={handleChange}
      />
    </Grid>
  </Grid>
)

export default BenefitForm
