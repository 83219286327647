import React from "react"
import { useMenu } from "hooks"
import {
  Hidden,
  Button,
  IconButton,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Avatar,
  Checkbox,
} from "@material-ui/core"
import { Image, Label } from "components"
import { MoreHoriz } from "@material-ui/icons"

const CoachFeedbackItem = ({
  feedback,
  isSelectable,
  selectedIds = [],
  handleSelect,
  handleClick,
  handleEditClick,
  handleDeleteClick,
  tabValue,
  ...props
}) => {
  const {
    open,
    anchorEl,
    handleToggleMenu,
    handleClose,
  } = useMenu()

  const renderMenu = () => (
    <Menu
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
    >
      {
        tabValue == 0 && (
          <MenuItem
            onClick={(e) => handleEditClick(feedback, e)}
          >
            Rate
          </MenuItem>
        )
      }

      <MenuItem
        onClick={(e) => handleDeleteClick(feedback, e)}
      >
        Delete
      </MenuItem>
    </Menu>
  )

  const isSelected = selectedIds.includes(feedback.id)

  return (
    <TableRow>
      {isSelectable && (
        <TableCell>
          <Checkbox
            checked={isSelected ? true : false}
            onChange={() => handleSelect(feedback)}
            name="feedback"
            color="primary"
          />
        </TableCell>
      )}
      <TableCell>
        <Button onClick={() => handleClick(feedback)}>
          <Image
            src={feedback.thumbnail_url}
            variant="rounded"
            size={80}
          />
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(feedback)}>
          {feedback.user_full_name}
        </Button>
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={(e) => handleToggleMenu(e)}
        >
          <MoreHoriz />
          {renderMenu()}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default CoachFeedbackItem
