import React from "react"
import { List, ListItem } from "@material-ui/core"

const LessonMedia = ({ lesson }) => (
  <List>
    <ListItem>
      <img
        src={lesson.image_url}
        width="160"
      />
    </ListItem>
    {lesson.video.video_url && (
      <ListItem>
        <video
          src={lesson.video.video_url}
          controls
          autoplay
          muted
          height="240"
          width="auto"
        />
      </ListItem>
    )}
    {lesson.video.preview_video_url && (
      <ListItem>
        <video
          src={lesson.video.preview_video_url}
          controls
          autoplay
          muted
          height="240"
          width="auto"
        />
      </ListItem>
    )}
  </List>
)

export default LessonMedia
