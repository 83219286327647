import React, {
  useEffect,
  useRef,
  useState,
} from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useCourses, useAlerts, useTrainers } from "hooks"
import {
  List,
  Box,
  Paper,
  Tabs,
  Tab,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { ImageList, Subheader } from "components"
import CourseDetails from "containers/courses/CourseDetails"
import CourseMedia from "containers/courses/CourseMedia"
import LessonItem from "containers/courses/LessonItem"
import TrainerItem from "containers/courses/TrainerItem"
import { orderBy } from "lodash"
import SortableList from "common/SortableList"
import { GetLabelByValue, WHAT_YOU_NEED } from "lib/constants";
import useCategories from "hooks/useCategories";

const CourseShow = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const {
    courseId,
    isLoading,
    isLoaded,
    course,
    findCourse,
    resortLessons,
  } = useCourses({
    id: id,
  })

  const {
    trainerId,
    isLoadingTrainer,
    isLoadedTrainer,
    trainer,
    findTrainer,
  } = useTrainers({
    id: id,
  })

  const {
    categories,
    findCategories
  } = useCategories({ id: id })

  const [lessons, setLessons] = useState([])
  const [tabValue, setTabValue] = useState(0)

  const handleEditClick = () =>
    history(`/courses/${id}/edit`)

  const handleLessonClick = (lesson) => {
    if (lesson.id) {
      history(`/lessons/${lesson.id}`)
    }
  }

  const handleTrainerClick = (trainer) => {
    if (trainer.id) {
      history(`/trainers/${trainer.id}`)
    }
  }

  useEffect(() => {
    if (id) findCourse(id, '/api/course')
    findCategories()
  }, [])

  useEffect(() => {
    if (course?.lessons?.length) {
      setLessons(course.lessons)
    }
    
    // Categories
    if(course.category != undefined) {
      if(!Array.isArray(course.category)) {
        course.category = JSON.parse(course.category)
      }

      for(let i = 0; i < course.category.length; i++) {
        course.category[i] = GetLabelByValue(categories, course.category[i])
      }
    }

    // What you need
    if(course.what_you_need != undefined) {
      if(!Array.isArray(course.what_you_need)) {
        course.what_you_need = JSON.parse(course.what_you_need)
      }

      for(let i = 0; i < course.what_you_need.length; i++) {
        course.what_you_need[i] = GetLabelByValue(WHAT_YOU_NEED, course.what_you_need[i])
      }
    }

    // Get trainer
    if (course.trainer_id) findTrainer(course.trainer_id, "/api/trainer")
  }, [course])


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const onDragEnd = (result) => {
    resortLessons(result)
    setLessons(result)
  }

  return (
    <>
      <Subheader
        title={
          isLoading ? "Loading..." : course.title
        }
        buttons={[
          <Button
            onClick={handleEditClick}
            color="primary"
            variant="contained"
          >
            Edit
          </Button>,
        ]}
      />
      <Container>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab label="Details" value={0} />
          <Tab label="Media" value={1} />
        </Tabs>
        <Paper>
          <Box p={2}>
            {tabValue == 0 ? (
              <CourseDetails course={course} />
            ) : (
              <CourseMedia course={course} />
            )}
          </Box>
        </Paper>
        <Box m={4} />
        {lessons?.length > 0 && (
          <>
            <Typography variant="h5">
              Lessons
            </Typography>
            <SortableList
              id="sort-lessons"
              onDragEnd={onDragEnd}
              list={orderBy(
                lessons,
                ["position", "title"],
                ["asc", "desc"]
              )}
              ListContainer={List}
              ListItem={({ item }) => (
                <LessonItem
                  key={item.id}
                  lesson={item}
                  handleClick={() =>
                    handleLessonClick(item)
                  }
                />
              )}
            />
            <Box m={4} />
          </>
        )}

        {trainer && (
          <>
            <Typography variant="h5">
              Trainer
            </Typography>
            <Paper>
              <List>
                <TrainerItem
                  trainer={trainer}
                  handleClick={() =>
                    handleTrainerClick(trainer)
                  }
                />
              </List>
            </Paper>
          </>
        )}
        <Box p={4} />
      </Container>
    </>
  )
}

export default CourseShow
