import React, { useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useUsers, useAlerts } from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { Subheader } from "components"
import UserDetails from "containers/users/UserDetails"

const UserShow = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const {
    userId,
    isLoading,
    isLoaded,
    user,
    findUser,
  } = useUsers({ id: id })

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleEditClick = () =>
    history(`/users/${id}/edit`)

  useEffect(() => {
    if (id) findUser(id)
  }, [])

  return (
    <>
      <Subheader
        title={isLoading ? "Loading..." : user.name}
        buttons={[
          <Button
            variant="contained"
            onClick={handleEditClick}
            color="primary"
          >
            Edit
          </Button>,
        ]}
      />
      <Container>
        <Paper>
          <Box p={4}>
            <UserDetails user={user} />
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default UserShow
