import React from "react"
import { List, ListItem } from "@material-ui/core"
import { Label, LineItem, Image } from "components"
import { COUNTRIES } from "lib/constants/countries"
import { PREFERRED_FOOT, USER_ROLES } from "lib/constants"

const UserDetails = ({ user }) => (
  <List>
    {/* <ListItem>
      <Image
        src={user.image_url}
        variant="rounded"
        size={240}
      />
    </ListItem> */}
    <LineItem label="First name" value={user.first_name} />
    <LineItem label="Last name" value={user.last_name} />
    <LineItem label="Birthday" value={user.dob} />
    <LineItem label="Email" value={user.email} />
    <LineItem
      label="Preferred foot"
      value={
        user.preferred_foot
          ? PREFERRED_FOOT.find(
              (c) => c.value == user.preferred_foot
            )?.label
          : "-"
      }
    />
    <LineItem label="Country" value={user.country} />
    <LineItem label="Nationality" value={user.nationality} />
    <LineItem
      label="Role"
      value={
        user.role
          ? USER_ROLES.find(
              (c) => c.value == user.role
            )?.label
          : "-"
      }
    />
    <LineItem
      label="Height (CM)"
      value={user.height}
    />
    <LineItem
      label="Weight (KG)"
      value={user.weight}
    />
    <LineItem
      label="Shirt number"
      value={user.shirt_number}
    />
    <LineItem
      label="Club"
      value={user.club}
    />
    {/* <LineItem
      label="Club ID"
      value={user?.club}
    /> */}
    <LineItem
      label="Favorite boot"
      value={user.favorite_boot}
    />
    <LineItem
      label="Favorite club"
      value={user.favorite_club}
    />
    <LineItem
      label="Favorite brand"
      value={user.favorite_brand}
    />
    <LineItem
      label="Agent"
      value={
        <Label
          label={user.agent ? "Yes" : "No"}
        />
      }
    />
    <LineItem
      label="Contract"
      value={
        <Label
          label={user.contract ? "Yes" : "No"}
        />
      }
    />


    {/* <LineItem
      label="Club approved"
      value={
        <Label
          label={user.club_approved ? "Yes" : "No"}
        />
      }
    />
    <LineItem
      label="Payment platform"
      value={<Label label={user.payment_platform} />}
    /> */}
    <LineItem
      label="Subscribed"
      value={
        <Label label={user.subscribed ? "Yes" : "No"} />
      }
    />
    {/* <LineItem
      label="Featured"
      value={
        <Label label={user.featured ? "Yes" : "No"} />
      }
    /> */}
    {/* <LineItem
      label="Veriff verified"
      value={
        <Label
          label={user.veriff_verified ? "Yes" : "No"}
        />
      }
    /> */}
  </List>
)

export default UserDetails
