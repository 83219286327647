import React from "react"
import Typography from "@material-ui/core/Typography"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

const SwitchInput = ({
  value,
  label,
  name,
  handleChange,
}) => {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={handleChange}
            name={name}
            color="primary"
          />
        }
        label={
          <Typography variant="body1">
            {label}
          </Typography>
        }
      />
    </FormGroup>
  )
}

export default SwitchInput
