import React from "react"
import {
  Person,
  PeopleOutlined,
  LocalOffer,
  Videocam,
  School,
  Description,
  SportsSoccer,
  DirectionsRun,
  Tv,
  Event,
} from "@material-ui/icons"

export const MENU_ITEMS = {
  loggedIn: [
    // {
    //   value: "/articles",
    //   text: "Articles",
    //   icon: Description,
    // },
    {
      value: "/benefits",
      text: "Benefits",
      icon: LocalOffer,
    },
    // {
    //   value: "/clubs",
    //   text: "Clubs",
    //   icon: SportsSoccer,
    // },
    {
      value: "/courses",
      text: "Courses",
      icon: School,
    },
    // {
    //   value: "/editorials",
    //   text: "Editorials",
    //   icon: Videocam,
    // },
    // { value: "/events", text: "Events", icon: Event },
    {
      value: "/lessons",
      text: "Lessons",
      icon: DirectionsRun,
    },
    {
      value: "/trainers",
      text: "Trainers",
      icon: PeopleOutlined,
    },
    // { value: "/topics", text: "Topics", icon: Tv },
    { value: "/users", text: "Users", icon: Person },
    {
      value: "/videos",
      text: "Videos",
      icon: Videocam,
    },
    {
      value: "/feedback",
      text: "Coach feedback",
      icon: Videocam,
    },
  ],
  loggedOut: [
    { value: "/login", text: "Sign In" },
    {
      value: "/forgot_password",
      text: "Reset password",
    },
  ],
}

export const NO_AUTH_ROUTES = [
  "/",
  "/login",
  "/forgot_password",
  "/verify_pin",
  "/reset_password",
]
