import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useVideos, useAlerts } from "hooks"
import {
  Box,
  Paper,
  Button,
  Container,
  ListItem,
  List,
} from "@material-ui/core"
import { Label, LineItem, Subheader } from "components"
import { Check } from "@material-ui/icons"
import VideoForm from "containers/videos/VideoForm"
import CoachFeedbackForm from "./CoachFeedbackForm";
import useCoachFeedback from "hooks/useCoachFeedback";
import { validateCoachFeedback } from "validations/coachFeedback";
import api from "api";
// import EditorJS from '@editorjs/editorjs';
// import TextEditor from "components/TextEditor";

const CoachFeedbackRate = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const {
    coachFeedback,
    handleChange,
    updateCoachFeedback,
    findCoachFeedback,
    findFeedback,
    rateFeedback
  } = useCoachFeedback({ id: id })

  const [tags, setTags] = useState()

  const [feedbackData, setFeedbackData] = useState()

  const { showAlertSuccess, showAlertError } = useAlerts()

  const handleSaveClick = async () => {
    console.log(coachFeedback)
    const valid = validateCoachFeedback(coachFeedback)
    if (valid.isValid) {
      const feedbackDTO = {
        captured_video_id: feedbackData?.id,
        ...coachFeedback
      }
  
      console.log(feedbackDTO)
  
      await rateFeedback(feedbackDTO)
      showAlertSuccess("Feedback has been rated.")
      // window.location.href = "/feedback";
      history(`/feedback`)
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  useEffect(() => {

    let resp = api.get(`/api/admin/video/feedback/${id}`)
    resp.then((r) => setFeedbackData(r.data))
    setFeedbackData(resp)
    console.log(resp)
    // if (id) {
    //   setFeedbackData(await findFeedback(id))
    // }
    // return () => {
    //   console.log('This will be logged on unmount');
    // };
  }, [])

  useEffect(() => {
    // Redirect to feedback if coach already gave feedback in the past
    if(feedbackData?.feedback != null) {
      showAlertSuccess("Feedback has already been rated. You can't rate this feedback anymore.")
       history(`/feedback`)
    }
  }, [feedbackData])
  console.log(feedbackData)
  console.log(coachFeedback)
  return (
    <>
      <Subheader
        title="Rate Coach Feedback"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>,
        ]}
      />
      <Container>
        <Paper>
          <Box p={4}>
              <List>
                <ListItem>
                  <video
                    src={feedbackData?.video_url}
                    controls
                    autoplay
                    muted
                    height="240"
                    width="auto"
                  />
                </ListItem>
                <LineItem
                  label="Description"
                  value={feedbackData?.description}
                />
                <LineItem
                  label="Feedback tags"
                  value={feedbackData?.feedback_tags?.map(
                    (label, i) => (
                      <Label key={i} label={label.name} />
                    )
                  )}
                />
              </List>


            <CoachFeedbackForm
              feedback={coachFeedback}
              tags={tags}
              handleChange={handleChange}
            />

            {/* <TextEditor /> */}
            <Box py={2}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Check />}
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default CoachFeedbackRate
