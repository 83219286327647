import { isEmpty } from "validations"

export const validateCoachFeedback = (feedback) => {
  let isValid = true
  let messages = []
  let fields = ["sho", "dri", "con", "phy", "pas", "def"]
  let fieldsLongName = ["Shooting", "Dribbling", "Control", "Physical", "Passing", "Defensive"]

  if (isEmpty(feedback.description)) {
    isValid = false
    messages.push("Feedback field is required")
  }

  fields.forEach((field, i) => {
    if (isNaN(feedback[field])) {
      isValid = false
      messages.push(`${fieldsLongName[i]} is not numeric.`)
    } else if (feedback[field] < 0 ||feedback[field] > 100) {
      isValid = false
      messages.push(`${fieldsLongName[i]} must be between 0 and 100.`)
    }
  })

  let validation = {
    isValid,
    messages,
  }

  return validation
}
