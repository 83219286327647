import React from "react"
import { AsyncAutosuggest } from "components"

const ClubAutosuggest = ({
  value,
  name = "club_id",
  label,
  position,
  handleChange,
  editPage,
  ...props
}) => (
  <AsyncAutosuggest
    name={name}
    label={label}
    value={value}
    position={position}
    displayField="name"
    remoteUrl="/api/clubs?per_page=999999"
    // singleUrl="/api/club"
    placeholder="Select club"
    handleChange={handleChange}
    editPage={editPage}
  />
)

export default ClubAutosuggest
