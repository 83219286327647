import React from "react"
import { List } from "@material-ui/core"
import { Label, LineItem } from "components"

const VideoDetails = ({ video }) => (
  <div>
    {video?.video_url && (
      <video controls src={video.video_url} width="100%" />
    )}
    <List>
      <LineItem
        label="Description"
        value={video.description}
      />
      <LineItem
        label="Tags"
        value={video?.tags?.map((tag, i) => (
          <Label key={i} label={tag.name} />
        ))}
      />
    </List>
  </div>
)

export default VideoDetails
