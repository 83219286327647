import { isEmpty } from "validations"

export const validateTrainer = (trainer, files = null) => {
  let isValid = true
  let messages = []

  if (isEmpty(trainer.first_name)) {
    isValid = false
    messages.push("First name is required")
  }

  if (isEmpty(trainer.last_name)) {
    isValid = false
    messages.push("Last name is required")
  }
  
  if (isEmpty(trainer.description)) {
    isValid = false
    messages.push("Description is required")
  }

  // Media related queries
  if(files?.image == undefined) {
    isValid = false
    messages.push("Image is required")
  }


  let validation = {
    isValid,
    messages,
  }

  return validation
}
