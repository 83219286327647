import React from "react"
import api from "api"
import {
  useAlerts,
  useLoaders,
  useResource,
} from "hooks"
import { validateLesson } from "validations/lessons"

const useLessons = ({ id, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } =
    useAlerts()

  const {
    resourceId: lessonId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: lesson,
    resourceDTO: lessonDTO,
    resources: lessons,
    findOne: findLesson,
    findMany: findLessons,
    update: updateLesson,
    create: createLesson,
    save: saveLesson,
    destroy: deleteLesson,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    reloadOne: reloadLesson,
    reloadMany: reloadLessons,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
  } = useResource({
    id: id,
    url: "/api/lesson",
    name: "lesson",
  })

  return {
    lessonId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    lesson,
    lessonDTO,
    lessons,
    findLesson,
    findLessons,
    saveLesson,
    updateLesson,
    createLesson,
    uploadFile,
    deleteFile,
    deleteLesson,
    handleChange,
    handleChangePage,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    reloadLesson,
    reloadLessons,
  }
}

export default useLessons
