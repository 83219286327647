import React, { useState, useEffect } from "react"
import {
  Chip,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { get } from "lodash"

const MultiAutosuggest2 = ({
  value,
  options,
  label,
  name,
  placeholder = "Select",
  handleChange,
  handleInputChange,
  valueField,
  labelField,
  ...props
}) => {
  const classes = useStyles()
  const [defaultValue, setDefaultValue] = useState([])
  const [formattedOptions, setformattedOptions] = useState([])
  const [formattedValue, setformattedValue] = useState([])

  const handleOnChange = (event, newValue) => {
    // console.log(newValue)
    // setDefaultValue(newValue)
    handleChange({
      target: {
        name: name,
        value: newValue.map((v) => v.value),
      },
    })
  }

  const setInitialValues = () => {
    let initialValues = []
    initialValues = formattedOptions?.filter((o) =>
      value.includes(o.value)
    )
    console.log(formattedOptions)
    console.log(value)
    console.log(initialValues)
    setDefaultValue(initialValues)
    // setDefaultValue(["x1", "x2", "x3"])

  }

  useEffect(() => {
    if (value) {
      setInitialValues()
    }
  }, [value])

  // useEffect(() => {
  //   if (formattedValue) {
  //     setInitialValues()
  //   }
  // }, [formattedValue])

  // Format the input data in a way that can be read by the dropdown
  useEffect(() => {
    if(Array.isArray(options) && options.length > 0) {
      let tempPos = []
      options.forEach((opt, i) => {
        tempPos.push({
          value: get(opt, valueField),
          // value: `x${i}`,
          label: get(opt, labelField)
        })
      })
      setformattedOptions(tempPos)
    }
  }, [options])

  useEffect(() => {
    if(Array.isArray(value) && value.length > 0) {
      let tempPos = []
      value.forEach((opt) => {
        tempPos.push({
          value: get(opt, valueField),
          label: get(opt, labelField)
        })
      })
      // console.log(value)
      // console.log(tempPos)
      setformattedValue(tempPos)
    }
  }, [value])

  // console.log(formattedValue)
  // console.log(formattedOptions)
  // console.log(defaultValue)
  console.log(formattedOptions)
  return (
    <div className={classes.container}>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.label}
      >
        {label}
      </Typography>

        <Autocomplete
          multiple
          classes={{ inputRoot: classes.inputRoot }}
          value={defaultValue}
          defaultValue={defaultValue}
          onChange={handleOnChange}
          options={formattedOptions}
          getOptionLabel={(option) => option.label !== undefined ? option.label.toString() : ''}
          renderTags={(tagValue, getTagProps) => {
            console.log(tagValue)
            tagValue.map((option, index) => {
              console.log(option)
              console.log(index)
              return (
                <Chip
                  label={option.label}
                  {...getTagProps({ index })}
                />
              )
            })
          }
          }

          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="outlined"
                placeholder={placeholder}
              />
            )
          }}
        />
      
    </div>
  )
}

export default MultiAutosuggest2

const useStyles = makeStyles((theme) => ({
  root: {},
  inputRoot: {
    backgroundColor: "white",
    padding: "5px 5px !important",
  },
  label: {
    marginBottom: 0,
  },
}))
