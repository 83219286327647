import React from "react"
import { List, ListItem } from "@material-ui/core"

const CourseDetails = ({ course }) => {
  const images = [
    {
      value: "image_url",
      label: "image",
      width: 160,
    },
    {
      value: "hero_image_url",
      label: "hero image",
      width: 160,
    },
  ]

  const videos = [
    {
      value: "preview_video_url",
      label: "preview video",
      width: "100%",
    }
  ]

  return (
    <List>
      {images.map((image, index) => (
        <ListItem key={index}>
          <img
            src={course[image.value]}
            width={image.width}
          />
        </ListItem>
      ))}

      <ListItem>
        {course.preview_video_url && (
          <video
            src={course.preview_video_url}
            controls
            autoplay
            muted
            height="240"
            width="auto"
          />
        )}
      </ListItem>
    </List>
  )
}

export default CourseDetails
