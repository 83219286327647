import React from "react"
import { TextInput } from "components"
import { Link as RouterLink } from "react-router-dom"
import {
  Box,
  Button,
  Grid,
  Link,
  Typography,
} from "@material-ui/core"

const UserForm = ({
  user,
  handleKeyPress,
  handleChange,
  handleSubmit,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={12}>
      <TextInput
        label="Email"
        required={true}
        name="email"
        value={user.email}
        onKeyPress={handleKeyPress}
        placeholder="Email"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        label="Password"
        required={true}
        name="password"
        value={user.password}
        type="password"
        onKeyPress={handleKeyPress}
        placeholder="Password"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <Button
        fullWidth
        size="large"
        color="primary"
        onClick={handleSubmit}
        variant="contained"
      >
        Sign In
      </Button>
    </Grid>
    <Box my={4} />
    {/* <Grid item xs={12} sm={12}>
      <Typography variant="body2">
        <Link
          component={RouterLink}
          to="/forgot_password"
        >
          Reset your password
        </Link>
      </Typography>
    </Grid> */}
  </Grid>
)

export default UserForm
