import React, { useState } from "react"
import {
  Box,
  Hidden,
  IconButton,
  makeStyles,
} from "@material-ui/core"
import { Close, Menu } from "@material-ui/icons"
import { Drawer } from "components"

const Header = (props) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const toggleMenu = () => setOpen(!open)

  return (
    <div>
      <Hidden mdUp>
        <Box className={classes.box}>
          <IconButton
            onClick={toggleMenu}
            color="secondary"
          >
            {open ? <Close /> : <Menu />}
          </IconButton>
        </Box>
      </Hidden>
      <Drawer open={open} toggleMenu={toggleMenu} />
    </div>
  )
}

export default Header

const useStyles = makeStyles((theme) => ({
  box: {
    position: "fixed",
    top: 10,
    left: 10,
  },
}))
