import api from "api"
import React, { useState } from "react"

const useCountries = (props) => {
  const [countries, setCountries] = useState()

  const findCountries = async () => {
    const res = await api.get(`/api/countries`)
    if (res.data) {
      // Map response from {id: "", name: ""} to {value: "", label: ""}
      const mapped = []
      for(let i = 0; i < res.data.length; i++) 
      {
        mapped.push({
          value: res.data[i].id,
          label: res.data[i].name
        })
      }
      setCountries(mapped)
    }
  }



  // const getCountries = async () => {
  //   let resp = await api.get(`/api/countries`)
  //   return resp.data
  // }

  // getCountries().then(data => setCountries(data));

  return {
    countries,
    findCountries
  }
}

export default useCountries
