import React, { useState } from "react"
import api from "api"
import {
  useAlerts,
  useLoaders,
  useResource,
  useCountries,
  useNationalities
} from "hooks"
import { validateUser } from "validations/users"

const useUsers = ({ id, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()
  const {
    resourceId: userId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: user,
    resourceDTO: userDTO,
    resources: users,
    findOne: findUser,
    findMany: findUsers,
    update: updateUser,
    create: createUser,
    save: saveUser,
    destroy: deleteUser,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    reload: reloadUser,
    reloadMany: reloadUsers,
    paginate,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
  } = useResource({
    id: id,
    url: "/api/admin/users",
    name: "user",
  })
  const {
    countries,
    findCountries
  } = useCountries()
  const {
    nationalities,
    findNationalities
  } = useNationalities()

  const resetUser = async (userId) => {
    try {
      showLoading()
      await api.post(
        `/api/v1/admin/users/${userId}/reset`
      )
      showAlertSuccess("Account successfully updated")
    } catch (e) {
      showAlertError("There was an error resetting")
    }
    hideLoading()
  }

  const updateUserStatus = async (userId, status) => {
    try {
      showLoading()
      await api.post(
        `/api/v1/admin/users/${userId}/update_status`,
        {
          status,
        }
      )
      showAlertSuccess("Account successfully updated")
    } catch (e) {
      showAlertError(
        "There was an error updating the status"
      )
    }
    hideLoading()
  }

  return {
    userId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    currentUser: user,
    user,
    userDTO,
    users,
    findUser,
    findUsers,
    saveUser,
    updateUser,
    createUser,
    deleteUser,
    uploadFile,
    deleteFile,
    resetUser,
    handleChange,
    handleChangePage,
    updateUserStatus,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    reloadUser,
    reloadUsers,
    sortKey,
    sortDirection,
    handleSort,
    countries,
    findCountries,
    nationalities,
    findNationalities
  }
}

export default useUsers
