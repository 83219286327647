import React, { useEffect } from "react"
import {
  useUsers,
  useAlerts,
  useNavigation,
  useSelected,
} from "hooks"
import {
  Layout,
  SortableTableHeader,
  Column,
  Subheader,
  Page,
  Placeholder,
  Search,
} from "components"
import Pagination from "@material-ui/lab/Pagination"
import {
  Checkbox,
  Container,
  Box,
  Paper,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Toolbar,
} from "@material-ui/core"
import {
  Add,
  Search as SearchIcon,
} from "@material-ui/icons"
import UserItem from "containers/users/UserItem"
import UserToolbar from "containers/users/UserToolbar"
import { useNavigate } from 'react-router-dom';

const TABLE_HEADERS = [

  {
    value: "first_name",
    label: "First name",
    sortable: true,
    hidden: false,
  },
  {
    value: "last_name",
    label: "Last name",
    sortable: true,
    hidden: false,
  },
  {
    value: "email",
    label: "Email",
    sortable: true,
    hidden: false,
  },
  {
    value: "role",
    label: "Role",
    sortable: true,
    hidden: false,
  },
  {
    value: "featured",
    label: "Featured",
    sortable: true,
    hidden: false,
  },
  {
    value: "paid",
    label: "Paid",
    sortable: true,
    hidden: false,
  },
]

const UserList = ({ ...props }) => {
  const history = useNavigate()
  const {
    isLoading,
    isLoaded,
    isEmpty,
    users,
    findUsers,
    deleteUser,
    paginate,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    reloadUsers,
    sortKey,
    sortDirection,
    handleSort,
  } = useUsers({})

  const {
    handleClick,
    handleEditClick,
    handleAddClick,
  } = useNavigation({
    history,
    url: "/users",
  })

  const {
    selected,
    selectedIds,
    handleSelect,
    handleClear,
    handleSelectAll,
    totalSelected,
  } = useSelected()

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleDeleteClick = async (user) => {
    await deleteUser(user, "/api/admin/users")
    showAlertSuccess("User has been deleted")
    reloadUsers()
  }

  useEffect(() => {
    findUsers(
      {
        ...query,
        // per_page: 20
        // sort_key: sortKey,
        // sort_direction: sortDirection,
      },
      page
    )
  }, [query, page, sortKey, sortDirection])

  const isSelectable = false

  return (
    <>
      <Subheader
        title="Users"
        buttons={[
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleAddClick}
            color="primary"
          >
            User
          </Button>,
        ]}
      />
      <Container maxWidth="lg">
        <>
          <Paper>
            <Search
              isLoading={isLoading}
              query={query}
              handleSearch={findUsers}
            />
            {!isEmpty ? (
              <TableContainer
                component={Paper}
                elevation={0}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {isSelectable && (
                        <TableCell>
                          <Checkbox
                            checked={
                              selectedIds?.length ===
                              users?.length
                            }
                            onChange={() =>
                              handleSelectAll(users)
                            }
                            color="primary"
                          />
                        </TableCell>
                      )}
                      {TABLE_HEADERS.filter(
                        (h) => !h.hidden
                      ).map((header, idx) => (
                        <SortableTableHeader
                          key={idx}
                          handleSort={handleSort}
                          value={`users.${header.value}`}
                          sortable={header.sortable}
                          sortKey={sortKey}
                          sortDirection={
                            sortDirection
                          }
                        >
                          {header.label}
                        </SortableTableHeader>
                      ))}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user, idx) => (
                      <UserItem
                        key={idx}
                        user={user}
                        isSelectable={isSelectable}
                        selectedIds={selectedIds}
                        handleSelect={handleSelect}
                        handleClick={handleClick}
                        handleDeleteClick={
                          handleDeleteClick
                        }
                        handleEditClick={
                          handleEditClick
                        }
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Placeholder
                icon={<SearchIcon />}
                title="No users"
                subtitle="Please try another search"
              />
            )}
          </Paper>
          {!isEmpty && (
            <Box my={1}>
              <Pagination
                onChange={(ev, page) =>
                  paginate(page)
                }
                color="primary"
                size="small"
                shape="rounded"
                page={page}
                count={numPages}
              />
            </Box>
          )}
        </>
        <UserToolbar
          selected={selected}
          selectedIds={selectedIds}
          handleClear={handleClear}
          totalSelected={totalSelected}
        />
      </Container>
    </>
  )
}

export default UserList
