import palette from "./palette"

export default {
  h1: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 60,
    lineHeight: "1.1em",
    "@media (max-width:600px)": {
      fontSize: 52,
    },
  },
  h2: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 38,
    "@media (max-width:600px)": {
      fontSize: 28,
    },
    lineHeight: "1.3em",
  },
  h3: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    textIndent: 0,
    fontSize: 24,
    lineHeight: "1.3em",
  },
  h4: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "1.3em",
  },
  h5: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "2.0em",
  },
  h6: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "2.0em",
  },
  subtitle1: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "1.5em",
  },
  subtitle2: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 15,
    lineHeight: "2.0em",
  },
  body1: {
    fontFamily: "Roboto",
    fontWeight: 400,
    color: palette.text.primary,
    fontSize: 15,
    lineHeight: "1.5em",
  },
  body2: {
    fontFamily: "Roboto",
    color: palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "1.75em",
    textDecoration: "none",
  },
  button: {
    fontWeight: 400,
    textTransform: "none",
    fontFamily: "Roboto",
    color: palette.text.primary,
    fontSize: 15,
  },
  caption: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
  },
  overline: {
    fontFamily: "Roboto",
    color: palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.01071,
    fontWeight: 400,
    lineHeight: "13px",
    textTransform: "none",
  },
}
