import React from "react"
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  makeStyles,
} from "@material-ui/core"
import {
  Photo,
  ChevronRight,
  DragIndicator,
} from "@material-ui/icons"

const LessonItem = ({
  lesson,
  handleClick,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <ListItem>
      <ListItemIcon>
        <DragIndicator className={classes.icon} />
      </ListItemIcon>
      <ListItemIcon>
        <Avatar
          size={80}
          variant="rounded"
          src={lesson.image_url}
        >
          <Photo />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={lesson.title}
        secondary={`Lesson #${lesson.position}`}
      />
      <ListItemSecondaryAction>
        <IconButton onClick={handleClick}>
          <ChevronRight />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default LessonItem

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.grey,
  },
}))
