import React from "react"
import {
  Typography,
  Button,
  ListItem,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import {
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons"
import clsx from "clsx"
import styled from "styled-components"

const MenuItem = ({
  handleClick,
  indent,
  active,
  value,
  depth = 0,
  open,
  expandable = false,
  text,
  icon: Icon,
}) => {
  const classes = useStyles()

  let paddingLeft = 18

  if (depth > 0) {
    paddingLeft = 42 + 8 * depth
  }

  const style = {
    paddingLeft,
  }

  return (
    <ListItem
      className={classes.itemLeaf}
      disableGutters
    >
      <Button
        onClick={(e) => handleClick(value, e)}
        className={clsx(
          classes.button,
          `depth-${depth}`,
          active ? classes.active : null
        )}
        style={style}
        color="primary"
        startIcon={
          Icon && <Icon className={classes.icon} />
        }
      >
        {text}
        {expandable && (
          <>
            {open ? (
              <ExpandLess
                className={classes.expandIcon}
                color="inherit"
              />
            ) : (
              <ExpandMore
                className={classes.expandIcon}
                color="inherit"
              />
            )}
          </>
        )}
      </Button>
    </ListItem>
  )
}
export default MenuItem

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    borderRadius: "0",
    color: theme.palette.common.white,
    padding: "12px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  icon: {
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: "auto",
    height: 20,
    width: 20,
  },
  label: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  active: {
    color: "white",
    borderRight: `6px solid ${theme.palette.primary.main}`,
    fontWeight: theme.typography.fontWeightBold,
    "& $icon": {
      color: "white",
    },
  },
}))
