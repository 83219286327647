import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import {
  useLessons,
  useForm,
  useAlerts,
  useUploadFileInMemory,
} from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { Check } from "@material-ui/icons"
import {
  ImageUploader,
  Subheader,
  VideoUploader,
} from "components"
import LessonForm from "containers/lessons/LessonForm"
import { validateLesson } from "validations/lessons"
import usePositions from "hooks/usePositions";

const LessonNew = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const { memoryFile, removeFile, handleUploadFile } =
    useUploadFileInMemory()

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const {
    lesson,
    handleChange,
    createLesson,
    findLesson,
    deleteFile,
  } = useLessons({
    id: id,
  })

  const {
    positionId,
    isLoading,
    isLoaded,
    positions,
    findPositions,
  } = usePositions({
    id: id,
  })

  const [formattedPositions, setformattedPositions] = useState([])

  const handleSaveClick = async () => {
    console.log("handle save")
    const valid = validateLesson(lesson, memoryFile, false)
    if (valid.isValid) {
      lesson.course_id = String(lesson.course_id)
      lesson.premium = lesson.premium ? 1 : 0

      console.log(lesson.course_id)
      await createLesson(lesson, memoryFile, '/api/admin/lessons/create')
      showAlertSuccess("Lesson has been created")
      // console.log("REMOVE THIS COMMENT")
      history("/lessons")
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  const handleDelete = async (type) => {
    removeFile()
  }

  useEffect(() => {
    if (id) findLesson(id)
    findPositions()
  }, [])

  // useEffect(() => {
  //   if(Array.isArray(positions) && positions.length > 0) {
  //     let tempPos = []
  //     positions.forEach((position) => {
  //       tempPos.push({
  //         value: position.id,
  //         label: position.name
  //       })
  //     })

  //     setformattedPositions(tempPos)
  //   }

  //   console.log(formattedPositions)
  // }, [positions])

  const images = [
    { value: "image", label: "Image" }
  ]

  return (
    <>
      <Subheader
        title="Add Lessons"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>,
        ]}
      />
      <Container>
        <Box p={4}>
          <center>
            {/* {images.map((image, index) => (
              <ImageUploader
                key={index}
                label={`Upload ${image.label}*`}
                imageUrl={
                  lesson[image.value] ||
                  (memoryFile &&
                    memoryFile[image.value]?.url)
                }
                handleUpload={(file) =>
                  handleUploadFile(file, image.value)
                }
                handleDelete={() =>
                  handleDelete(image.value)
                }
              />
            ))} */}

            <VideoUploader
              label="Upload lesson video*"
              videoUrl={
                lesson.lesson_video ||
                memoryFile?.lesson_video?.url
              }
              handleUpload={(file) =>
                handleUploadFile(file, "lesson_video")
              }
              handleDelete={() =>
                handleDelete("lesson_video")
              }
            />

            <VideoUploader
              label="Upload lesson preview video*"
              videoUrl={
                lesson.lesson_preview_video ||
                memoryFile?.lesson_preview_video?.url
              }
              handleUpload={(file) =>
                handleUploadFile(file, "lesson_preview_video")
              }
              handleDelete={() =>
                handleDelete("lesson_preview_video")
              }
            />


            {/* <VideoUploader
              label="Upload preview video"
              videoUrl={
                lesson.video2_url ||
                memoryFile?.video2_url?.url
              }
              handleUpload={(file) =>
                handleUploadFile(file, "video2_url")
              }
              handleDelete={() =>
                handleDelete("video2_url")
              }
            />
            <VideoUploader
              label="Upload web preview video"
              videoUrl={
                lesson.web_video_url ||
                memoryFile?.web_video_url?.url
              }
              handleUpload={(file) =>
                handleUploadFile(
                  file,
                  "web_video_url"
                )
              }
              handleDelete={() =>
                handleDelete("web_video_url")
              }
            /> */}
          </center>
          <LessonForm
            positions={positions}
            lesson={lesson}
            handleChange={handleChange}
          />
          <Box py={2}>
            <Button
              startIcon={<Check />}
              color="primary"
              variant="contained"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default LessonNew
