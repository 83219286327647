import {
  isEmail,
  isEmpty,
  isMinLength,
  isWorkEmail,
} from "validations"

export const validateUser = (user, editPage=false) => {
  let isValid = true
  let messages = []

  if (
    isEmpty(user.first_name) ||
    isEmpty(user.last_name)
  ) {
    isValid = false
    messages.push("Full name is required")
  }

  if (isEmpty(user.email) && editPage == false) {
    isValid = false
    messages.push("Email is required")
  }

  if (isEmpty(user.password) && editPage == false) {
    isValid = false
    messages.push("Password is required")
  }

  if (!isMinLength(user.email, 4) && editPage == false) {
    isValid = false
    messages.push(
      "Email must be at least 4 characters"
    )
  }

  if (!isMinLength(user.password, 6) && editPage == false) {
    isValid = false
    messages.push(
      "Password must be at least 6 characters"
    )
  }

  if (!isEmail(user.email) && editPage == false) {
    isValid = false
    messages.push("Email is not a valid email")
  }

  if (isEmpty(user.role)) {
    isValid = false
    messages.push("Role is required")
  }

  if (isEmpty(user.agent)) {
    isValid = false
    messages.push("Agent is required")
  }

  if (isEmpty(user.contract)) {
    isValid = false
    messages.push("Contract is required")
  }

  if (isEmpty(user.country_id)) {
    isValid = false
    messages.push("Country is required")
  }

  if (isEmpty(user.dob)) {
    isValid = false
    messages.push("Birthday is required")
  }

  let validation = {
    isValid,
    messages,
  }

  return validation
}

export const validatePassword = (
  password,
  passwordConfirmation
) => {
  let isValid = true
  let messages = []

  if (
    isEmpty(password) ||
    isEmpty(passwordConfirmation)
  ) {
    isValid = false
    messages.push(
      "Password and password confirmation are required"
    )
  }

  if (!isMinLength(password, 6)) {
    isValid = false
    messages.push(
      "Password must be at least 6 characters"
    )
  }

  if (password != passwordConfirmation) {
    isValid = false
    messages.push("Passwords do not match")
  }

  let validation = {
    isValid,
    messages,
  }

  return validation
}
