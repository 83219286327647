import React, { useEffect, useState } from "react"
import {
  alpha,
  Typography,
  InputBase,
  makeStyles,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { get } from "lodash"

const Autosuggest2 = ({
  value,
  valueField,
  labelField,
  options,
  label,
  name,
  placeholder = "Select",
  handleChange,
  handleInputChange,
  editPage,
  ...props
}) => {
  const classes = useStyles()
  const [selected, setSelected] = useState(undefined)
  const [initial, setInitial] = useState(true)
  const [formattedOptions, setformattedOptions] = useState([])
  
  const handleOnChange = (event, newValue) => {
    setSelected(newValue)
    handleChange({
      target: {
        name: name,
        value: newValue?.value,
      },
    })
  }

  // This useEffect is used only for edit pages to update fields with values from db
  useEffect(() => {
    // console.log(value !== undefined)
    // console.log(initial == true)
    // console.log(formattedOptions.length !== 0)
    // console.log(Array.isArray(value) === false)
    // console.log(editPage == true)
    // console.log(value !== "")

    if (value !== undefined && initial == true && formattedOptions.length !== 0 && Array.isArray(value) === false && editPage == true && value !== "") {
      // console.log(value)
      // console.log(formattedOptions)
      setSelected(
        formattedOptions?.find((c) => c.value == value)
      )
      setInitial(false)
    }
  }, [formattedOptions, value]);

  // Format the input data in a way that can be read by the dropdown
  useEffect(() => {
    if(Array.isArray(options) && options.length > 0) {
      let tempPos = []
      options.forEach((opt) => {
        tempPos.push({
          value: get(opt, valueField),
          label: get(opt, labelField)
        })
      })
      setformattedOptions(tempPos)
    }
  }, [options])

  return (
    <div className={classes.container}>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.label}
      >
        {label}
      </Typography>
      <Autocomplete
        multiple={false}
        disableCloseOnSelect={false}
        classes={{
          paper: classes.paper,
          option: classes.option,
          popperDisablePortal: classes.popperDisablePortal,
        }}
        // value={selected || null}
        value={selected || []}
        onChange={(event, newValue) => {
          handleOnChange(event, newValue)
        }}
        onInputChange={(event, newInputValue) => {
          handleInputChange &&
            handleInputChange(newInputValue)
        }}
        noOptionsText="No options"
        clearOnBlur
        handleHomeEndKeys
        options={!formattedOptions ? [{ label: "Loading...", value: -1 }] : formattedOptions}
        getOptionLabel={(option) => option.label ? option.label : ''}
        getOptionSelected={(option, value) =>
          option?.label == value?.label
        }
        renderInput={(params) => (
          <InputBase
            placeholder={placeholder}
            ref={params.InputProps.ref}
            inputProps={{
              ...params.inputProps,
              autoComplete: "none",
            }}
            className={classes.inputBase}
          />
        )}
      />
    </div>
  )
}

export default Autosuggest2

const useStyles = makeStyles((theme) => ({
  root: {},
  iconSelected: {
    height: 20,
    width: 20,
  },
  inputBase: {
    padding: 0,
    width: "100%",
    "& input": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      appearance: "none",
      padding: theme.spacing(1.5, 1),
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      transition: theme.transitions.create([
        "border-color",
        "box-shadow",
      ]),
      border: "1px solid #ced4da",
      fontSize: 15,
      "&:focus": {
        boxShadow: `${alpha(
          "#999999",
          0.25
        )} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.light,
      },
    },
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(1),
    color: "#586069",
    fontSize: 15,
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  label: {
    marginBottom: 0,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
}))
