import React from "react"
import { AsyncAutosuggest } from "components"

const TrainerAutosuggest = ({
  value,
  label,
  handleChange,
  editPage,
}) => {
  // console.log(value)
  return (
    <AsyncAutosuggest
      name="trainer_id"
      label={label}
      value={value || ''}
      displayField={["first_name", "last_name"]}
      remoteUrl="/api/trainers?per_page=99999"
      placeholder="Select"
      handleChange={handleChange}
      editPage={editPage}
    />
  )
  }

export default TrainerAutosuggest
