import React from "react"
import { Grid } from "@material-ui/core"
import {
  Autosuggest,
  CheckboxGroupInput,
  RadioInput,
  TextInput,
  SwitchInput,
} from "components"

const TrainerForm = ({
  trainer,
  isEditing,
  handleChange,
}) => (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={6}>
      <TextInput
        value={trainer.first_name}
        label="First name*"
        name="first_name"
        placeholder="First name"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextInput
        value={trainer.last_name}
        label="Last name*"
        name="last_name"
        placeholder="Last name"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        rows={3}
        multiline
        value={trainer.description}
        label="Description*"
        name="description"
        placeholder="Description"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <SwitchInput
        value={trainer.featured ? 1 : 0}
        label="Featured"
        name="featured"
        placeholder="Featured"
        handleChange={handleChange}
      />
    </Grid>
  </Grid>
)

export default TrainerForm
