import React, { useState } from "react"
import api from "api"
import {
  useAlerts,
  useLoaders,
  useResource,
} from "hooks"

const useCategories = ({ id, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } =
    useAlerts()
  const [formattedCategories, setFormattedCategories] = useState([])


  const {
    resourceId: categoryId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: category,
    resources: categories,
    findOne: findCategory,
    findMany: findCategories,
    update: updateCategory,
    create: createCategory,
    save: saveCategory,
    destroy: deleteCategory,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    refresh: reloadCategory,
    refreshMany: reloadCategories,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
  } = useResource({
    id: id,
    url: "/api/course/category",
    name: "category",
  })

  const findCategoriesFormatted = async () => {
    const res = await api.get(`/api/course/category`)
    if (res.data) {
      // Map response from {id: "", name: ""} to {value: "", label: ""}
      const mapped = []
      for(let i = 0; i < res.data.length; i++) 
      {
        mapped.push({
          value: res.data[i].id,
          label: res.data[i].name
        })
      }
      setFormattedCategories(mapped)
    }
  }

  return {
    categoryId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    category,
    categories,
    findCategory,
    findCategories,
    saveCategory,
    updateCategory,
    createCategory,
    deleteCategory,
    uploadFile,
    deleteFile,
    handleChange,
    handleChangePage,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    reloadCategory,
    reloadCategories,
    formattedCategories,
    findCategoriesFormatted
  }
}

export default useCategories
