import React, { useEffect } from "react"
import {
  useBenefits,
  useAlerts,
  useNavigation,
  useSelected,
} from "hooks"
import {
  Layout,
  SortableTableHeader,
  Column,
  Subheader,
  Page,
  Placeholder,
} from "components"
import Pagination from "@material-ui/lab/Pagination"
import {
  Checkbox,
  Container,
  Box,
  Paper,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Toolbar,
} from "@material-ui/core"
import { Add, Search } from "@material-ui/icons"
import BenefitItem from "containers/benefits/BenefitItem"
import BenefitToolbar from "containers/benefits/BenefitToolbar"
// import SearchForm from "containers/benefits/SearchForm"
import SearchForm from "components/SearchForm"
import { useNavigate } from 'react-router-dom';

const TABLE_HEADERS = [
  {
    value: "image_url",
    label: "Image",
    sortable: true,
    hidden: false,
  },
  {
    value: "title",
    label: "Title",
    sortable: true,
    hidden: false,
  },
  {
    value: "expires_at",
    label: "Expires",
    sortable: false,
    hidden: false,
  },
  {
    value: "description",
    label: "Description",
    sortable: true,
    hidden: true,
  },
  {
    value: "category",
    label: "Category",
    sortable: true,
    hidden: true,
  },
  {
    value: "url",
    label: "URL",
    sortable: true,
    hidden: false,
  },
]

const BenefitList = ({ ...props }) => {
  const history = useNavigate()
  const {
    isLoading,
    isLoaded,
    isEmpty,
    benefits,
    findBenefits,
    deleteBenefit,
    paginate,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    reloadBenefits,
    sortKey,
    sortDirection,
    handleSort,
  } = useBenefits({})

  const {
    handleClick,
    handleEditClick,
    handleAddClick,
  } = useNavigation({
    history,
    url: "/benefits",
  })

  const {
    selected,
    selectedIds,
    handleSelect,
    handleClear,
    handleSelectAll,
    totalSelected,
  } = useSelected()

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleDeleteClick = async (benefit) => {
    await deleteBenefit(benefit, "/api/admin/benefits")
    showAlertSuccess("Benefit has been deleted")
    reloadBenefits()
  }

  useEffect(() => {
    findBenefits(
      {
        ...query,
        sort_key: sortKey,
        sort_direction: sortDirection,
      },
      page,
    )
  }, [query, page, sortKey, sortDirection])

  return (
    <>
      <Subheader
        title="Benefits"
        buttons={[
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleAddClick}
            color="primary"
          >
            Benefit
          </Button>,
        ]}
      />
      <Container maxWidth="lg">
        <>
          <Paper>
            <SearchForm
              isLoading={isLoading}
              query={query}
              handleSearch={findBenefits}
            />
            {!isEmpty ? (
              <TableContainer
                component={Paper}
                elevation={0}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEADERS.filter(
                        (h) => !h.hidden
                      ).map((header, idx) => (
                        <SortableTableHeader
                          key={idx}
                          handleSort={handleSort}
                          value={`benefits.${header.value}`}
                          sortable={header.sortable}
                          sortKey={sortKey}
                          sortDirection={
                            sortDirection
                          }
                        >
                          {header.label}
                        </SortableTableHeader>
                      ))}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {benefits.map((benefit, idx) => (
                      <BenefitItem
                        key={idx}
                        benefit={benefit}
                        isSelectable={false}
                        selectedIds={selectedIds}
                        handleSelect={handleSelect}
                        handleClick={handleClick}
                        handleDeleteClick={
                          handleDeleteClick
                        }
                        handleEditClick={
                          handleEditClick
                        }
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Placeholder
                icon={<Search />}
                title="No benefits"
                subtitle="Please try another search"
              />
            )}
          </Paper>
          {!isEmpty && (
            <Box my={1}>
              <Pagination
                onChange={(ev, page) =>
                  paginate(page)
                }
                color="primary"
                size="small"
                shape="rounded"
                page={page}
                count={numPages}
              />
            </Box>
          )}
        </>
        <BenefitToolbar
          selected={selected}
          selectedIds={selectedIds}
          handleClear={handleClear}
          totalSelected={totalSelected}
        />
      </Container>
    </>
  )
}

export default BenefitList
