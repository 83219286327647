import React, { useEffect } from "react"
import { Grid } from "@material-ui/core"
import {
  JSONInput,
  RatingInput,
  MultiAutosuggest,
  TextInput,
  SwitchInput,
  TrainerAutosuggest,
  FileUploader,
  FileUploadInput,
  AsyncAutosuggest,
} from "components"
import {
  // CATEGORIES,
  WHAT_YOU_NEED,
} from "lib/constants"
import MultiAutosuggest2 from "components/MultiAutosuggest2"
import Autosuggest2 from "components/Autosuggest2"
import MultiAutosuggest3 from "components/MultiAutosuggest3"
import MultiAutosuggest4 from "components/MultiAutosuggest4"
import useCategories from "hooks/useCategories"

const CourseForm = ({
  course,
  isEditing,
  handleChange,
  editPage,
  positions,
  filterTags,
  categories,
  selectedCategories
}) => {
  const {
    formattedCategories,
    findCategoriesFormatted
  } = useCategories({
    id: null
  })

	useEffect(() => {
    findCategoriesFormatted()
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={course.title}
          label="Title*"
          name="title"
          placeholder="Title"
          required
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={course.subtitle}
          label="Subtitle*"
          name="subtitle"
          placeholder="Subtitle"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          rows={3}
          multiline
          value={course.description}
          label="Description*"
          name="description"
          placeholder="Description"
          handleChange={handleChange}
        />
      </Grid>
      {/* <Grid item xs={12} sm={12}>
        {course.jw_captions && (
          <JSONInput
            value={course.jw_captions}
            label="JW Captions"
            name="jw_captions"
            placeholder="JW Player Captions"
            handleChange={handleChange}
          />
        )}
      </Grid> */}
      {/* <Grid item xs={12} sm={12}>
        <TextInput
          value={course.jw_player_id}
          label="JW Player ID"
          name="jw_player_id"
          placeholder="JW Player ID"
          handleChange={handleChange}
        />
      </Grid> */}
      {/* <Grid item xs={12} sm={12}>
        <TextInput
          value={course.video_url}
          label="Video URL"
          name="video_url"
          placeholder="Video URL"
          handleChange={handleChange}
        />
      </Grid> */}
      <Grid item xs={12} sm={12}>
        {/* <MultiAutosuggest4
          options={categories}
          value={course.categories || ""}
          label="Categories"
          name="categories"
          placeholder="Categories"
          handleChange={handleChange}
        /> */}


        {/* <MultiAutosuggest2
          options={categories}
          value={course.categories || []}
          label="Categories"
          name="category"
          placeholder="Categories"
          handleChange={handleChange}
          valueField="id"
          labelField="name"
        /> */}

        {/* <Autosuggest2
          label="Category"
          name="category"
          value={course?.position?.id}
          options={positions}
          placeholder="Select position"
          handleChange={handleChange}
          editPage={editPage}
          valueField="id"
          labelField="name"
        /> */}

        {/* <MultiAutosuggest
          options={CATEGORIES}
          value={course.categories || ""}
          label="Categories"
          name="categories"
          placeholder="Categories"
          handleChange={handleChange}
        /> */}

        <MultiAutosuggest3
          // defaultValue={[{ value: 1, label: "dribbling" }]} 
          defaultValue={selectedCategories}
          handleChange={handleChange}
          name="category"
          editPage={editPage}
          label={"Categories*"}
          placeholder={"Categories"}
          options={formattedCategories}
        />

      </Grid>


      {/* <Grid item xs={12} sm={12}>
        <AsyncAutosuggest
          value={course.category || []}
          label="Categories"
          name="category"
          placeholder="Categories"
          handleChange={handleChange}
          displayField="name"
          remoteUrl="/api/positions"
        />
      </Grid> */}


      <Grid item xs={12} sm={12}>
        <TrainerAutosuggest
          value={course.trainer_id}
          label="Trainer*"
          name="trainer_id"
          placeholder="Trainer"
          handleChange={handleChange}
          editPage={editPage}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <MultiAutosuggest
          options={WHAT_YOU_NEED}
          value={course.what_you_need || []}
          label="What you need*"
          name="what_you_need"
          placeholder="What you need"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Autosuggest2
          options={filterTags}
          value={course.filter_tag_id || []}
          label="Filter tag*"
          name="filter_tag_id"
          placeholder="Filter tag"
          handleChange={handleChange}
          editPage={editPage}
          valueField="id"
          labelField="name"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <RatingInput
          max={5}
          value={course.difficulty}
          label="Difficulty*"
          name="difficulty"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <SwitchInput
          value={course.suggested_training ? true : false}
          label="Suggested training"
          name="suggested_training"
          placeholder="Suggested training"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <SwitchInput
          value={course.featured ? true : false}
          label="Featured"
          name="featured"
          placeholder="Featured"
          handleChange={handleChange}
        />
      </Grid>
      {/* <Grid item xs={12} sm={12}>
        <SwitchInput
          value={course.recommended ? true : false}
          label="Recommended"
          name="recommended"
          placeholder="Recommended"
          handleChange={handleChange}
        />
      </Grid> */}
      <Grid item xs={12} sm={12}>
        <SwitchInput
          value={course.premium ? true : false}
          label="Premium"
          name="premium"
          placeholder="premium"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <SwitchInput
          value={course.active ? true : false}
          label="Active"
          name="active"
          placeholder="Active"
          handleChange={handleChange}
        />
      </Grid>
    </Grid>
  )
}

export default CourseForm
