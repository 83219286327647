import React from "react"
import {
  Avatar,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core"
import { Photo } from "@material-ui/icons"

const TrainerItem = ({
  trainer,
  handleClick,
  ...rest
}) => {
  return (
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <Avatar
          size={80}
          variant="rounded"
          src={trainer.image_url}
        >
          <Photo />
        </Avatar>
      </ListItemIcon>
      <ListItemText primary={`${trainer.first_name} ${trainer.last_name}`} />
    </ListItem>
  )
}

export default TrainerItem
