import React from "react"
import { Grid } from "@material-ui/core"
import {
  Autosuggest,
  TextInput,
  CourseAutosuggest,
  SwitchInput,
  RatingInput,
  MultiAutosuggest,
  JSONInput,
  AsyncAutosuggest,
} from "components"
import {
  SKILLS,
  DIFFICULTY,
  CATEGORIES,
  POINTS,
  WHAT_YOU_NEED,
  // POSITIONS,
} from "lib/constants"
import Autosuggest2 from "components/Autosuggest2"
import useCategories from "hooks/useCategories"
import { useEffect } from "react"

const LessonForm = ({
  lesson,
  isEditing,
  handleChange,
  editPage,
  positions
}) => {
  const {
    formattedCategories,
    findCategoriesFormatted
  } = useCategories({
    id: null
  })

	useEffect(() => {
    findCategoriesFormatted()
  }, [])


  // console.log(lesson.course_id)
  // console.log(positions)
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={lesson.title}
          label="Title*"
          name="title"
          placeholder="Title"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          rows={3}
          multiline
          value={lesson.description}
          label="Description*"
          name="description"
          placeholder="Description"
          handleChange={handleChange}
        />
      </Grid>
      {/* <Grid item xs={12} sm={12}>
        <TextInput
          value={lesson.jw_player_id}
          label="JW Player ID"
          name="jw_player_id"
          placeholder="JW Player ID"
          handleChange={handleChange}
        />
      </Grid> */}
      {/* <Grid item xs={12} sm={12}>
        <TextInput
          value={lesson.video_url}
          label="Video URL"
          name="video_url"
          placeholder="Video 1 URL"
          handleChange={handleChange}
        />
      </Grid> */}
      {/* <Grid item xs={12} sm={12}>
        <TextInput
          value={lesson.video2_url}
          label="Video preview URL"
          name="video2_url"
          placeholder="Video 2 URL"
          handleChange={handleChange}
        />
      </Grid> */}
      {/* <Grid item xs={12} sm={12}>
        <TextInput
          value={lesson.web_video_url}
          label="Web video preview URL"
          name="web_video_url"
          placeholder="Web video URL"
          handleChange={handleChange}
        />
      </Grid> */}
      <Grid item xs={12} sm={6}>
        <Autosuggest
          value={lesson.category || ""}
          label="Category*"
          name="category"
          placeholder="Category"
          options={formattedCategories}
          handleChange={handleChange}
          editPage={editPage}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autosuggest
          value={lesson.difficulty || ""}
          label="Difficulty*"
          name="difficulty"
          placeholder="Difficulty"
          options={DIFFICULTY}
          handleChange={handleChange}
          editPage={editPage}
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <TextInput
          value={lesson.difficulty}
          label="Difficulty"
          name="difficulty"
          placeholder="Difficulty"
          handleChange={handleChange}
        />
      </Grid> */}
      {/* <Grid item xs={12} sm={12}>
        {lesson.jw_captions && (
          <JSONInput
            value={lesson.jw_captions}
            label="JW Captions"
            name="jw_captions"
            placeholder="JW Player Captions"
            handleChange={handleChange}
          />
        )}
      </Grid> */}
      <Grid item xs={12} sm={12}>
        <MultiAutosuggest
          options={WHAT_YOU_NEED}
          value={lesson.what_you_need || []}
          label="What you need*"
          name="what_you_need"
          placeholder="What you need"
          handleChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        {/* <Autosuggest
          options={positions}
          value={lesson?.position?.id || []}
          label="Positions"
          name="position"
          placeholder="Positions"
          handleChange={handleChange}
          editPage={editPage}
        /> */}


        <Autosuggest2
          label="Position*"
          name="position"
          value={lesson?.position?.id}
          options={positions}
          placeholder="Select position"
          handleChange={handleChange}
          editPage={editPage}
          valueField="id"
          labelField="name"
        />



      </Grid>

      {/* <Grid item xs={12} sm={12}>
        <AsyncAutosuggest
          value={lesson?.position?.id}
          label="Positions"
          name="position"
          placeholder="Positions"
          handleChange={handleChange}
          displayField="name"
          remoteUrl="/api/positions"
          editPage={editPage}
        />
      </Grid> */}

      {/* {
      editPage == false && (
        <Grid item xs={12} sm={12}>
          <CourseAutosuggest
            value={lesson.course_id}
            label="Course"
            name="course_id"
            placeholder="Course"
            handleChange={handleChange}
            editPage={editPage}
          />
        </Grid>
      )} */}

      <Grid item xs={12} sm={12}>
        <CourseAutosuggest
          value={lesson.course_id || ""}
          label="Course*"
          name="course_id"
          placeholder="Course"
          handleChange={handleChange}
          editPage={editPage}
        />
      </Grid>
      {SKILLS.map((skill, i) => (
        <Grid item xs={12} sm={12} key={i}>
          <RatingInput
            value={lesson[skill.value]}
            label={skill.label}
            name={skill.value}
            handleChange={handleChange}
          />
        </Grid>
      ))}

      <Grid item xs={12} sm={12}>
        <SwitchInput
          value={lesson.premium ? true : false}
          label="Premium*"
          name="premium"
          placeholder="premium"
          handleChange={handleChange}
        />
      </Grid>
    </Grid>
  )
}


export default LessonForm
