import React from "react"
import clsx from "clsx"
import {
  Container,
  Box,
  makeStyles,
  withWidth,
} from "@material-ui/core"
import styled from "styled-components"

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.sharp,
      duration:
        theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.easeOut,
      duration:
        theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

const Layout = ({ children, width, ...props }) => {
  const classes = useStyles()
  const open = ["xs", "sm"].includes(width)

  return (
    <Box
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
    >
      {children}
    </Box>
  )
}

export default withWidth()(Layout)
