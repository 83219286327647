import React from "react"
import {
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import { LineItem, Image, Label } from "components"

const BenefitDetails = ({ benefit }) => (
  <List>
    {/* <ListItem>
      <img src={benefit.thumbnail_url} width={240} />
    </ListItem> */}
    <ListItem>
      <img src={benefit.image_url} width={240} />
    </ListItem>
    {/* <ListItem>
      <img src={benefit.web_image_url} width={500} />
    </ListItem> */}
    <LineItem label="Title" value={benefit.title} />
    <LineItem
      label="Expires at"
      value={benefit.expires_at}
    />
    <LineItem label="URL" value={benefit.external_url} />
    <LineItem
      label="Body"
      value={benefit.body}
    />
    {/* <LineItem label="Category" value={benefit.category} /> */}
    <LineItem
      label="Promotion"
      value={
        <Label
          label={benefit.is_promotion ? "Yes" : "No"}
        />
      }
    />
    <LineItem
      label="Active"
      value={
        <Label
          label={benefit.active ? "Yes" : "No"}
        />
      }
    />
  </List>
)

export default BenefitDetails
