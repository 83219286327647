import React, { useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useUsers, useForm, useAlerts } from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { ImageUploader, Subheader } from "components"
import { Check } from "@material-ui/icons"
import UserForm from "containers/users/UserForm"
import { validateUser } from "validations/users";

const UserEdit = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const {
    user,
    userDTO,
    handleChange,
    updateUser,
    findUser,
    uploadFile,
    deleteFile,
    resetUser,
    countries,
    findCountries,
    nationalities,
    findNationalities
  } = useUsers({ id: id })
  // console.log(user);
  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleSaveClick = async () => {
    const valid = validateUser(user, true)
    if (valid.isValid) {
      console.log(user)
      user.agent = user.agent ? 1 : 0
      user.contract = user.contract ? 1 : 0
      // delete user.trial

      let tempUser = JSON.parse(JSON.stringify(user));

      for(const prop in user) {
        if(prop != "id"){
          // Remove props if there is no change and update DTO obj if props change
          if(user[prop] == userDTO[prop]) {
            // console.log(prop)
            delete tempUser[prop]
          } else if((Array.isArray(user[prop]) || typeof user[prop] === 'object') && JSON.stringify(user[prop]) == JSON.stringify(userDTO[prop])) {
            // console.log(prop)
            delete tempUser[prop]
          } else {
            tempUser[prop] = user[prop]
          }
        }
      }

      await updateUser(tempUser)
      showAlertSuccess("User has been updated")
      // console.log("COME HERE TO REMOVE THE COMMENT UserEdit.js");
      history(`/users/${id}`)
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  const handleResetUser = async () => {
    await resetUser(user?.id)
    showAlertSuccess("User has been reset")
  }

  const handleUploadImage = async (file) => {
    await uploadFile(file, "image_url")
    findUser(id)
  }

  const handleDeleteImage = async (file) => {
    await deleteFile("image_url")
    findUser(id)
  }

  useEffect(() => {
    if (id) findUser(id)
    findCountries()
    findNationalities()
  }, [])

  return (
    <>
      <Subheader
        title="Edit User"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>,
        ]}
      />
      <Container>
        <Box p={4}>
          {/* <center>
            <ImageUploader
              imageUrl={user.image_url}
              handleUpload={handleUploadImage}
              handleDelete={handleDeleteImage}
            />
          </center> */}
          <UserForm
            user={user}
            countries={countries}
            nationalities={nationalities}
            handleChange={handleChange}
            editPage={true}
          />
          <Box py={2}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Check />}
              onClick={handleSaveClick}
            >
              Save
            </Button>
            <Button
              ml={4}
              onClick={handleResetUser}
              color="secondary"
            >
              Reset User
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default UserEdit
