import React from "react"
import api from "api"
import {
  useAlerts,
  useLoaders,
  useResource,
} from "hooks"
import { validatePosition } from "validations/trainers"

const usePositions = ({ id, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } =
    useAlerts()

  const {
    resourceId: positionId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: position,
    resources: positions,
    findOne: findPosition,
    findMany: findPositions,
    update: updatePosition,
    create: createPosition,
    save: savePosition,
    destroy: deletePosition,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    refresh: reloadPosition,
    refreshMany: reloadPositions,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
  } = useResource({
    id: id,
    url: "/api/positions",
    name: "position",
  })

  return {
    positionId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    position,
    positions,
    findPosition,
    findPositions,
    savePosition,
    updatePosition,
    createPosition,
    deletePosition,
    uploadFile,
    deleteFile,
    handleChange,
    handleChangePage,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    reloadPosition,
    reloadPositions,
  }
}

export default usePositions
