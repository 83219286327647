import { isEmpty } from "validations"

export const validateCourse = (course, files = null) => {
  let isValid = true
  let messages = []

  if (isEmpty(course.title)) {
    isValid = false
    messages.push("Title is required")
  }

  if (isEmpty(course.subtitle)) {
    isValid = false
    messages.push("Subtitle is required")
  }

  if (isEmpty(course.description)) {
    isValid = false
    messages.push("Description is required")
  }

  if (isEmpty(course.category) && isEmpty(course.categories)) {
    isValid = false
    messages.push("Categories are required")
  }

  if (isEmpty(course.difficulty)) {
    isValid = false
    messages.push("Difficulty is required")
  }

  if (isEmpty(course.trainer_id)) {
    isValid = false
    messages.push("Trainer is required")
  }

  if (isEmpty(course.what_you_need)) {
    isValid = false
    messages.push("What you need field is required")
  }

  if (isEmpty(course.filter_tag_id)) {
    isValid = false
    messages.push("Filter tag field is required")
  }

  // Media related queries
  if(files?.image == undefined) {
    isValid = false
    messages.push("Image is required")
  }

  if(files?.hero_image == undefined) {
    isValid = false
    messages.push("Hero image is required")
  }

  if(files?.preview_video == undefined) {
    isValid = false
    messages.push("Preview video is required")
  }

  let validation = {
    isValid,
    messages,
  }

  return validation
}
