import React, { useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import {
  useBenefits,
  useForm,
  useAlerts,
  useUploadFileInMemory,
} from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { Check } from "@material-ui/icons"
import { ImageUploader, Subheader } from "components"
import BenefitForm from "containers/benefits/BenefitForm"
import { validateBenefit } from "validations/benefits";

const BenefitNew = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const { memoryFile, removeFile, handleUploadFile } =
    useUploadFileInMemory()

  const {
    benefit,
    handleChange,
    createBenefit,
    findBenefit,
    deleteFile,
  } = useBenefits({ id: id })

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleSaveClick = async () => {
    console.log(benefit)
    
    const valid = validateBenefit(benefit, memoryFile)
    if (valid.isValid) {
      benefit.active = benefit.active ? 1 : 0
      benefit.is_promotion = benefit.is_promotion ? 1 : 0

      await createBenefit(benefit, memoryFile, "/api/admin/benefits/create")
      showAlertSuccess("Benefit has been created")
      history("/benefits")
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  useEffect(() => {
    if (id) findBenefit(id)
  }, [])

  const handleDelete = async (type) => {
    removeFile()
  }

  return (
    <>
      <Subheader
        title="Add Benefits"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>,
        ]}
      />
      <Container>
        <Box p={4}>
          <center>
            <ImageUploader
              label="Image*"
              imageUrl={
                benefit.image ||
                (memoryFile &&
                  memoryFile.image.url)
              }
              handleUpload={(file) =>
                handleUploadFile(
                  file,
                  "image"
                )
              }
              handleDelete={() =>
                handleDelete("image")
              }
            />
          </center>
          <BenefitForm
            benefit={benefit}
            handleChange={handleChange}
            editPage={false}
          />
          <Box py={2}>
            <Button
              startIcon={<Check />}
              color="primary"
              variant="contained"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default BenefitNew
