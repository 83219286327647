import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import queryString from "query-string"

const useQuery = ({ handleSearch, customUrl, ...props }) => {
  const location = useLocation()
  const searchParams = queryString.parse(
    location.search
  )

  const [query, setQuery] = useState(searchParams)

  const handleChange = (ev) => {
    console.log("handle change")
    const { name } = ev.target
    let value =
      ev.target.type === "checkbox"
        ? ev.target.checked
        : ev.target.value
    let newQuery = {
      ...query,
      [name]: value,
    }
    console.log(newQuery)
    console.log(customUrl)
    setQuery(newQuery)
    if (value === "") {
      handleSearch(newQuery, 1, false, customUrl == undefined ? "" : customUrl)
    }
  }

  const handleKeyPress = (event) => {
    console.log("handle key press")

    switch (event.key) {
      case "Enter":
        handleSearch(query, 1, false, customUrl == undefined ? "" : customUrl)
        return
      default:
        return
    }
  }

  const handleClear = () => {
    console.log("handle clear")

    setQuery({ search: "" })
    handleSearch({
      search: "",
      ...query,
    },      
    1,
    false,
    customUrl == undefined ? "" : customUrl)
  }

  return {
    query,
    handleClear,
    handleChange,
    handleKeyPress,
    setQuery,
  }
}

export default useQuery
