import React, { useState, useEffect } from "react"
import {
  Chip,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { get } from "lodash"
// import { CATEGORIES } from "lib/constants";

// export const CATEGORIES = [
//   { value: 1, label: "dribbling" },
//   { value: 2, label: "star" },
//   { value: 3, label: "control" },
//   { value: 4, label: "passing" },
//   { value: 5, label: "shooting" },
//   { value: 6, label: "defending" },
// ]

export default function MultiAutosuggest3({
  defaultValue,
  handleChange,
  name,
  label,
  placeholder,
  editPage,
  options
}) {
  // const {
  //   course,
  //   findCourse,
  // } = useCourses({ id: 1 })

  // useEffect(() => {
  //   if (id) {
  //     findCourse(id, '/api/course')
  //   }
  // }, [])

  // useEffect(() => {
  //   if(Array.isArray(options) && options.length > 0) {
  //     let tempPos = []
  //     options.forEach((opt, i) => {
  //       tempPos.push({
  //         value: get(opt, valueField),
  //         // value: `x${i}`,
  //         label: get(opt, labelField)
  //       })
  //     })
  //     setformattedOptions(tempPos)
  //   }
  // }, [options])

  const [defValue, setDefValue] = useState([])
  const [initial, setInital] = useState(true)

  useEffect(() => {
    if(editPage === true && initial === true && defValue.length === 0 && defaultValue.length !== 0) {
      setDefValue(defaultValue)
      setInital(false)
    }
  }, [defaultValue])

  const handleOnChange = (event, newValue) => {
    setDefValue(newValue)
    handleChange({
      target: {
        name: name,
        value: newValue.map((v) => v.value),
      },
    })
  }

  return (
    <Autocomplete
      sx={{ m: 1, width: 500 }}
      multiple
      options={options || []}
      getOptionLabel={(option) => option.label}
      // value={[{ value: 1, label: "dribbling" }]}
      value={defValue}
      onChange={handleOnChange}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          style={{backgroundColor: "#FFFFFF"}}
        />
      )}
    />
  );
}