import React from "react"
import { alpha, makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  root: {},
  inputBase: {
    width: "100%",
    "& input, & textarea": {
      webkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1, 1.5),
      minHeight: 28,
      transition: theme.transitions.create([
        "border-color",
        "box-shadow",
      ]),
      border: "1px solid #ced4da",
      fontSize: 15,
      "&:focus": {
        boxShadow: `${alpha(
          "#999999",
          0.25
        )} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.light,
      },
    },
  },
  IconButton: {
    padding: "5px",
  },
  icon: {
    fontSize: "20px",
    color: "#888",
  },
}))
