import React from "react"
import { List, makeStyles } from "@material-ui/core"
import { Label, LineItem, Rating } from "components"
import { SKILLS } from "lib/constants"

const LessonDetails = ({ lesson }) => {
  const classes = useStyles()

  // let whatYouNeed = []

  // for(const props in lesson.what_you_need) {
  //   if(object[property] !== null) {
  //     whatYouNeed.push 
  //   }
  // }


  console.log(lesson)
  console.log(typeof lesson)
  return (
    <List>
      <LineItem label="Title" value={lesson.title} />
      <LineItem
        label="Description"
        value={lesson.description}
      />
      <LineItem
        label="Category"
        value={lesson.category}
      />
      {/* <LineItem
        label="Difficulty"
        value={lesson.difficulty}
      /> */}
      <LineItem
        label="What You Need"
        value={lesson.what_you_need?.join(", ")}
      />
      {/* <LineItem
        label="Lesson number"
        value={lesson.position}
      />
      <LineItem
        label="Course"
        value={lesson.course?.title}
      />
      <LineItem
        label="Video"
        value={
          lesson?.videos && lesson.videos[0]?.title
        }
      /> */}
      {/* <LineItem
        label="JW Player ID"
        value={lesson?.jw_player_id}
      /> */}
      {/* {lesson.jw_captions &&
        Object.keys(lesson.jw_captions).map(
          (language, i) => (
            <LineItem
              key={i}
              label={`${language} captions`}
              value={lesson?.jw_captions[language]}
            />
          )
        )} */}
      {/* <LineItem
        label="What You Need"
        value={lesson?.what_you_need?.map(
          (label, i) => (
            <Label key={i} label={label} />
          )
        )}
      /> */}
      {/* <LineItem
        label="Premium"
        value={
          <Label
            label={lesson.premium ? "Yes" : "No"}
          />
        }
      /> */}
      
      {SKILLS.map((skill, i) => (
        <LineItem
          key={i}
          label={skill.label}
          value={
            <Rating value={lesson[skill.value]} />
          }
        />
      ))}
    </List>
  )
}

export default LessonDetails

const useStyles = makeStyles((theme) => ({
  captions: {
    whiteSpace: "pre-wrap",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}))
