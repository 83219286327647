import React, { useEffect, useState } from "react"
import api from "api"
import { Autosuggest } from "components"
import { makeStyles } from "@material-ui/core"
import { buildOptions } from "lib/utils"
import { get } from "lodash"

const AsyncAutosuggest = ({
  value,
  label,
  name,
  remoteUrl,
  singleUrl,
  query,
  displayField,
  handleChange,
  position,
  placeholder = "Search",
  editPage,
  ...props
}) => {
  // const [option, setOption] = useState({
  //   label: "",
  //   value: null,
  // })
  const [option, setOption] = useState(undefined)
  const [options, setOptions] = useState(undefined)
  
  const classes = useStyles()

  const findValue = async (value) => {
    if(singleUrl != undefined) {
      let resp = await api.get(`${singleUrl}/${value}`)
      setOption({
        value: resp.data.id,
        label: get(resp.data, displayField),
      })
    } else {
      // If we don't have URL for get by id try to get it from the options in memory
      // console.log('else find value')
      // setOption({
      //   value: value,
      //   label: options?.find((c) => c.value == value).label,
      // })

      setOption(value)
      // console.log(option)
    }
  }

  const findOptions = async (keywords) => {

    let resp = await api.get(remoteUrl, {
      params: {
        keywords: keywords,
        ...query,
      },
    })
    // console.log(resp.data.current_page == undefined ? resp.data : resp.data.data)
    let results = buildOptions(
      resp.data.current_page == undefined ? resp.data : resp.data.data,
      "id",
      displayField
    )
    // console.log(results)
    setOptions(results)
  }

  const handleInputChange = (newValue) => {
    findOptions(newValue)
  }

  useEffect(() => {
    findOptions()
  }, [])

  useEffect(() => {
    if (value) {
      findValue(value)
    }
  }, [value])
  // console.log(value)
  return (
    <Autosuggest
      label={label}
      name={name}
      value={option || []}
      options={options}
      placeholder={placeholder}
      handleChange={handleChange}
      editPage={editPage}
      handleInputChange={handleInputChange}
    />
  )
}

export default AsyncAutosuggest

const useStyles = makeStyles((theme) => ({
  container: {},
  label: {
    marginBottom: "7px",
  },
}))
