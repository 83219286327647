import React from "react"
import { Route } from "react-router"
import { Routes } from "react-router-dom"
import { AuthRoute } from "components"

//auth
import Login from "containers/auth/Login"
import ForgotPassword from "containers/auth/ForgotPassword"
import ResetPassword from "containers/auth/ResetPassword"
import VerifyPin from "containers/auth/VerifyPin"

//routes

//articles
// import ArticleNew from "containers/articles/ArticleNew"
// import ArticleShow from "containers/articles/ArticleShow"
// import ArticleEdit from "containers/articles/ArticleEdit"
// import ArticleList from "containers/articles/ArticleList"

//users
import UserNew from "containers/users/UserNew"
import UserShow from "containers/users/UserShow"
import UserEdit from "containers/users/UserEdit"
import UserList from "containers/users/UserList"

//benefits
import BenefitNew from "containers/benefits/BenefitNew"
import BenefitShow from "containers/benefits/BenefitShow"
import BenefitEdit from "containers/benefits/BenefitEdit"
import BenefitList from "containers/benefits/BenefitList"

//courses
import CourseNew from "containers/courses/CourseNew"
import CourseShow from "containers/courses/CourseShow"
import CourseEdit from "containers/courses/CourseEdit"
import CourseList from "containers/courses/CourseList"

//clubs
// import ClubNew from "containers/clubs/ClubNew"
// import ClubShow from "containers/clubs/ClubShow"
// import ClubEdit from "containers/clubs/ClubEdit"
// import ClubList from "containers/clubs/ClubList"

//lessons
import LessonNew from "containers/lessons/LessonNew"
import LessonShow from "containers/lessons/LessonShow"
import LessonEdit from "containers/lessons/LessonEdit"
import LessonList from "containers/lessons/LessonList"

//trainers
import TrainerNew from "containers/trainers/TrainerNew"
import TrainerShow from "containers/trainers/TrainerShow"
import TrainerEdit from "containers/trainers/TrainerEdit"
import TrainerList from "containers/trainers/TrainerList"

//topics
// import TopicNew from "containers/topics/TopicNew"
// import TopicShow from "containers/topics/TopicShow"
// import TopicEdit from "containers/topics/TopicEdit"
// import TopicList from "containers/topics/TopicList"

//editorials
// import EditorialNew from "containers/editorials/EditorialNew"
// import EditorialShow from "containers/editorials/EditorialShow"
// import EditorialEdit from "containers/editorials/EditorialEdit"
// import EditorialList from "containers/editorials/EditorialList"

//events
// import EventNew from "containers/events/EventNew"
// import EventShow from "containers/events/EventShow"
// import EventEdit from "containers/events/EventEdit"
// import EventList from "containers/events/EventList"

//events
import VideoNew from "containers/videos/VideoNew"
import VideoShow from "containers/videos/VideoShow"
import VideoEdit from "containers/videos/VideoEdit"
import VideoList from "containers/videos/VideoList"
import ProtectedRoute from "components/ProtectedRoute"
import CoachFeedbackList from "containers/coach-feedback/CoachFeedbackList"
import CoachFeedbackEdit from "containers/coach-feedback/CoachFeedbackRate"
import CoachFeedbackRate from "containers/coach-feedback/CoachFeedbackRate"

const checkAuth = (component) => {
  return component
}

const RoutesF = () => (
  <Routes>
    <Route path="/" element={ <Login/> } exact />
    <Route path="/login/:token" element={ <Login/> } />
    <Route path="/login" element={ <Login/> } />
    <Route
      path="/forgot_password"
      element={ <ForgotPassword/> }
      exact
    />
    <Route
      path="/verify_pin"
      element={ <VerifyPin/> }
      exact
    />
    <Route
      path="/reset_password"
      element={ <ResetPassword/> }
      exact
    />
    <Route
      path="/users/passwords/edit"
      element={ <ResetPassword/> }
      exact
    />

    {/* Auth routes start */}

    {/* <Route
      path="/articles/new"
      element={
        <ProtectedRoute>
          <ArticleNew/>
        </ProtectedRoute>
      }
      exact
    />

    <Route
      path="/articles/:id/edit"
      element={
        <ProtectedRoute>
          <ArticleEdit/>
        </ProtectedRoute>
      }
      exact
    />
    
    <Route
      path="/articles"
      element={
        <ProtectedRoute>
          <ArticleList/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/articles/:id"
      element={
        <ProtectedRoute>
          <ArticleShow/>
        </ProtectedRoute>
      }
      exact
    /> */}

    <Route
      path="/users/new"
      element={
        <ProtectedRoute>
          <UserNew/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/users/:id/edit"
      element={
        <ProtectedRoute>
          <UserEdit/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/users"
      element={
        <ProtectedRoute>
          <UserList/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/users/:id"
      element={
        <ProtectedRoute>
          <UserShow/>
        </ProtectedRoute>
      }
      exact
    />

    <Route
      path="/benefits/new"
      element={
        <ProtectedRoute>
          <BenefitNew/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/benefits/:id/edit"
      element={
        <ProtectedRoute>
          <BenefitEdit/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/benefits"
      element={
        <ProtectedRoute>
          <BenefitList/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/benefits/:id"
      element={
        <ProtectedRoute>
          <BenefitShow/>
        </ProtectedRoute>
      }
      exact
    />
{/* 
    <Route
      path="/clubs/new"
      element={
        <ProtectedRoute>
          <ClubNew/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/clubs/:id/edit"
      element={
        <ProtectedRoute>
          <ClubEdit/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/clubs"
      element={
        <ProtectedRoute>
          <ClubList/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/clubs/:id"
      element={
        <ProtectedRoute>
          <ClubShow/>
        </ProtectedRoute>
      }
      exact
    /> */}

    <Route
      path="/courses/new"
      element={
        <ProtectedRoute>
          <CourseNew/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/courses/:id/edit"
      element={
        <ProtectedRoute>
          <CourseEdit/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/courses"
      element={
        <ProtectedRoute>
          <CourseList/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/courses/:id"
      element={
        <ProtectedRoute>
          <CourseShow/>
        </ProtectedRoute>
      }
      exact
    />

    {/* <Route
      path="/editorials/new"
      element={
        <ProtectedRoute>
          <EditorialNew/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/editorials/:id/edit"
      element={
        <ProtectedRoute>
          <EditorialEdit/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/editorials"
      element={
        <ProtectedRoute>
          <EditorialList/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/editorials/:id"
      element={
        <ProtectedRoute>
          <EditorialShow/>
        </ProtectedRoute>
      }
      exact
    /> */}

    <Route
      path="/lessons/new"
      element={
        <ProtectedRoute>
          <LessonNew/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/lessons/:id/edit"
      element={
        <ProtectedRoute>
          <LessonEdit/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/lessons"
      element={
        <ProtectedRoute>
          <LessonList/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/lessons/:id"
      element={
        <ProtectedRoute>
          <LessonShow/>
        </ProtectedRoute>
      }
      exact
    />

    <Route
      path="/trainers/new"
      element={
        <ProtectedRoute>
          <TrainerNew/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/trainers/:id/edit"
      element={
        <ProtectedRoute>
          <TrainerEdit/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/trainers"
      element={
        <ProtectedRoute>
          <TrainerList/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/trainers/:id"
      element={
        <ProtectedRoute>
          <TrainerShow/>
        </ProtectedRoute>
      }
      exact
    />

    {/* <Route
      path="/topics/new"
      element={
        <ProtectedRoute>
          <TopicNew/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/topics/:id/edit"
      element={
        <ProtectedRoute>
          <TopicEdit/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/topics"
      element={
        <ProtectedRoute>
          <TopicList/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/topics/:id"
      element={
        <ProtectedRoute>
          <TopicShow/>
        </ProtectedRoute>
      }
      exact
    /> */}

    {/* <Route
      path="/events/new"
      element={
        <ProtectedRoute>
          <EventNew/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/events/:id/edit"
      element={
        <ProtectedRoute>
          <EventEdit/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/events"
      element={
        <ProtectedRoute>
          <EventList/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/events/:id"
      element={
        <ProtectedRoute>
          <EventShow/>
        </ProtectedRoute>
      }
      exact
    /> */}

    {/* <Route
      path="/videos/new"
      element={
        <ProtectedRoute>
          <VideoNew/>
        </ProtectedRoute>
      }
      exact
    /> */}
    <Route
      path="/videos/:id/edit"
      element={
        <ProtectedRoute>
          <VideoEdit/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/videos"
      element={
        <ProtectedRoute>
          <VideoList/>
        </ProtectedRoute>
      }
      exact
    />
    <Route
      path="/videos/:id"
      element={
        <ProtectedRoute>
          <VideoShow/>
        </ProtectedRoute>
      }
      exact
    />

    <Route
      path="/feedback"
      element={
        <ProtectedRoute>
          <CoachFeedbackList/>
        </ProtectedRoute>
      }
      exact
    /> 
    <Route
      path="/feedback/:id/rate"
      element={
        <ProtectedRoute>
          <CoachFeedbackRate/>
        </ProtectedRoute>
      }
      exact
    /> 

    {/* Auth routes end */}
  </Routes>
)

export default RoutesF
