import { isEmpty } from "validations"

export const validateLesson = (lesson, files = null) => {
  let isValid = true
  let messages = []

  if (isEmpty(lesson.title)) {
    isValid = false
    messages.push("Title is required")
  }

  if (isEmpty(lesson.category)) {
    isValid = false
    messages.push("Category is required")
  }

  if (isEmpty(lesson.description)) {
    isValid = false
    messages.push("Description is required")
  }

  if (isEmpty(lesson.difficulty)) {
    isValid = false
    messages.push("Difficulty is required")
  }

  if (isEmpty(lesson.what_you_need)) {
    isValid = false
    messages.push("What you need is required")
  }

  if (isEmpty(lesson.position)) {
    isValid = false
    messages.push("Position is required")
  }

  if (isEmpty(lesson.course_id)) {
    isValid = false
    messages.push("Course is required")
  }

  if (isEmpty(lesson.premium)) {
    isValid = false
    messages.push("Premium is required")
  }

  // Media related queries
  if(files?.lesson_video == undefined) {
    isValid = false
    messages.push("Lesson video is required")
  }

  if(files?.lesson_preview_video == undefined) {
    isValid = false
    messages.push("Lesson preview video is required")
  }

  let validation = {
    isValid,
    messages,
  }

  return validation
}
