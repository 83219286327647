import React, { useEffect, useState } from "react"
import { useAlerts, useAuth } from "hooks"
import {
  useNavigate,
  useParams,
} from "react-router-dom"
import {
  Box,
  Button,
  Container,
  Grid,
} from "@material-ui/core"
import { PinInput, Subheader, TextInput } from "components"
import { validatePassword } from "validations/users"

const ResetPassword = ({ ...props }) => {
  const params = useParams()
  const history = useNavigate()
  const [pin, setPin] = useState()
  const [password, setPassword] = useState()
  const [
    passwordConfirmation,
    setPasswordConfirmation,
  ] = useState()

  const { showAlertError, showAlertSuccess } =
    useAlerts()

  const { resetPassword } = useAuth()

  const handleSubmit = async () => {
    let valid = validatePassword(
      password,
      passwordConfirmation
    )
    if (valid.isValid) {
      let resp = await resetPassword(password, passwordConfirmation, pin)
      console.log(resp)
      if (resp && resp?.id) {
        window.location.href = "/login"
      } else {
        showAlertError(
          "Please enter a valid password"
        )
      }
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  const handlePasswordChange = ({ target }) => {
    setPassword(target.value)
  }

  const handleConfirmationChange = ({ target }) => {
    setPasswordConfirmation(target.value)
  }

  const handlePinChange = ({ target }) =>
    setPin(target.value)

  return (
    <div>
      <Subheader title="Reset Password" />
      <Container maxWidth="xs">
        <Box my={10}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <PinInput
                fullWidth
                required={true}
                name="pin"
                label="Enter 6-digit PIN"
                handleChange={handlePinChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                name="password"
                label="Password"
                handleChange={handlePasswordChange}
                type="password"
                placeholder="Password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                name="password_confirmation"
                label="Confirm password"
                handleChange={
                  handleConfirmationChange
                }
                type="password"
                placeholder="Confirm password"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                size="large"
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                Reset your password
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}

export default ResetPassword
