import React, { useEffect, useState } from "react"
import {
  useCourses,
  useAlerts,
  useNavigation,
  useSelected,
} from "hooks"
import {
  Layout,
  SortableTableHeader,
  Column,
  Subheader,
  Page,
  Placeholder,
  SearchInput,
} from "components"
import Pagination from "@material-ui/lab/Pagination"
import {
  Checkbox,
  Container,
  Box,
  Paper,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Toolbar,
} from "@material-ui/core"
import { Add, Search } from "@material-ui/icons"
import CourseItem from "containers/courses/CourseItem"
import CourseToolbar from "containers/courses/CourseToolbar"
// import SearchForm from "containers/courses/SearchForm"
import SearchForm from "components/SearchForm"
import { useNavigate } from 'react-router-dom';

const TABLE_HEADERS = [
  {
    value: "image_url",
    label: "Image",
    sortable: true,
    hidden: false,
  },

  {
    value: "title",
    label: "Title",
    sortable: true,
    hidden: false,
  },
  {
    value: "subtitle",
    label: "Subtitle",
    sortable: true,
    hidden: true,
  },

  {
    value: "description",
    label: "Description",
    sortable: true,
    hidden: true,
  },

  {
    value: "categories",
    label: "Categories",
    sortable: false,
    hidden: false,
  },

  {
    value: "difficulty",
    label: "Difficulty",
    sortable: true,
    hidden: false,
  },

  {
    value: "trainer_id",
    label: "Trainer",
    sortable: true,
    hidden: false,
  },

  {
    value: "featured",
    label: "Featured",
    sortable: true,
    hidden: false,
  },
  {
    value: "published",
    label: "Published",
    sortable: true,
    hidden: false,
  },

  {
    value: "recommended",
    label: "Recommended",
    sortable: true,
    hidden: false,
  },

  {
    value: "premium",
    label: "Premium",
    sortable: true,
    hidden: false,
  },
]

const CourseList = ({ ...props }) => {
  const history = useNavigate()
  const {
    isLoading,
    isLoaded,
    isEmpty,
    courses,
    findCourses,
    deleteCourse,
    paginate,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    reloadCourses,
    sortKey,
    sortDirection,
    handleSort,
  } = useCourses({})

  const {
    handleClick,
    handleEditClick,
    handleAddClick,
  } = useNavigation({
    history,
    url: "/courses",
  })

  const {
    selected,
    selectedIds,
    handleSelect,
    handleClear,
    handleSelectAll,
    totalSelected,
  } = useSelected()

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const [searchQuery, setSearchQuery] = useState("")

  const handleDeleteClick = async (course) => {
    await deleteCourse(course, '/api/admin/courses')
    showAlertSuccess("Course has been deleted")
    reloadCourses("/api/courses/browse")
  }

  const handleSearch = (e) => {
    console.log(e)
    console.log("search")
  }

  useEffect(() => {
    console.log(query)

    findCourses(
      {
        ...query,
        sort_key: sortKey,
        sort_direction: sortDirection,
      },
      page,
      false,
      "/api/courses/browse"
    )

    
  }, [query, page, sortKey, sortDirection])

  console.log(courses)
  
  return (
    <>
      <Subheader
        title="Courses"
        buttons={[
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleAddClick}
            color="primary"
          >
            Course
          </Button>,
        ]}
      />
      <Container maxWidth="lg">
        <>
          <Paper>
            <SearchForm
              isLoading={isLoading}
              query={query}
              handleSearch={findCourses}
              customUrl={"/api/courses/browse"}
            />

            {/* <Box p={1} px={2}>
              <SearchInput
                fullWidth
                name="keywords"
                // onKeyPress={handleKeyPress}
                value={searchQuery}
                handleChange={handleSearch}
              />
            </Box> */}
            {!isEmpty ? (
              <TableContainer
                component={Paper}
                elevation={0}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEADERS.filter(
                        (h) => !h.hidden
                      ).map((header, idx) => (
                        <SortableTableHeader
                          key={idx}
                          handleSort={handleSort}
                          value={`courses.${header.value}`}
                          sortable={header.sortable}
                          sortKey={sortKey}
                          sortDirection={
                            sortDirection
                          }
                        >
                          {header.label}
                        </SortableTableHeader>
                      ))}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {typeof courses} */}
                    {courses?.map((course, idx) => (
                      <CourseItem
                        key={idx}
                        course={course}
                        isSelectable={false}
                        selectedIds={selectedIds}
                        handleSelect={handleSelect}
                        handleClick={handleClick}
                        handleDeleteClick={
                          handleDeleteClick
                        }
                        handleEditClick={
                          handleEditClick
                        }
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Placeholder
                icon={<Search />}
                title="No courses"
                subtitle="Please try another search"
              />
            )}
          </Paper>
          {!isEmpty && (
            <Box my={1}>
              <Pagination
                onChange={(ev, page) =>
                  paginate(page, "/api/courses/browse")
                }
                color="primary"
                size="small"
                shape="rounded"
                page={page}
                count={numPages}
              />
            </Box>
          )}
        </>
        <CourseToolbar
          selected={selected}
          selectedIds={selectedIds}
          handleClear={handleClear}
          totalSelected={totalSelected}
        />
      </Container>
    </>
  )
}

export default CourseList
