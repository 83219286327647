import React, { useEffect } from "react"
import {
  useVideos,
  useAlerts,
  useNavigation,
  useSelected,
} from "hooks"
import {
  SortableTableHeader,
  Subheader,
  Placeholder,
} from "components"
import Pagination from "@material-ui/lab/Pagination"
import {
  Container,
  Box,
  Paper,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Tabs,
  Tab,
} from "@material-ui/core"
import { Add, Search } from "@material-ui/icons"
import VideoItem from "containers/videos/VideoItem"
// import SearchForm from "containers/videos/SearchForm"
import SearchForm from "components/SearchForm"
import { useNavigate } from 'react-router-dom';
import { useState } from "react"
import useCoachFeedback from "hooks/useCoachFeedback"
import CoachFeedbackItem from "./CoachFeedbackItem"
import api from "api"

const TABLE_HEADERS = [
  {
    value: "image_url",
    label: "Image",
    sortable: false,
    hidden: false,
  },
  {
    value: "user",
    label: "User",
    sortable: false,
    hidden: false,
  },
  // {
  //   value: "feedback_status",
  //   label: "Feedback Status",
  //   sortable: false,
  //   hidden: false,
  // },
]

const CoachFeedbackList = ({ ...props }) => {
  const history = useNavigate()
  const {
    isLoading,
    isLoaded,
    isEmpty,
    videos,
    findVideos,
    deleteCoachFeedback,
    paginate,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    reloadAllCoachFeedback,
    sortKey,
    sortDirection,
    handleSort,
    findAllFeedbackFilter,
    allFeedbackFiltered,
    allCoachFeedback,
    findAllCoachFeedback,
    setPage
  } = useCoachFeedback({})

  const {
    handleClick,
    handleRateClick,
    handleAddClick,
  } = useNavigation({
    history,
    url: "/feedback",
  })

  const {
    selected,
    selectedIds,
    handleSelect,
    handleClear,
    handleSelectAll,
    totalSelected,
  } = useSelected()

  const [tabValue, setTabValue] = useState(0)
  // const [allCoachFeedback, setAllCoachFeedback] = useState({})

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleDeleteClick = async (feedback) => {
    console.log(feedback)
    // showLoading()
    await api.delete(`/api/admin/video/feedback/${feedback.captured_video_id}`)
    
    // setResource({ data: {} })
    // hideLoading()
    
    // await deleteCoachFeedback(feedback, "/api/admin/video/feedback")
    showAlertSuccess("Coach feedback has been deleted")
    reloadAllCoachFeedback("/api/admin/feedback")
  }

  // useEffect(async () => {
  //   const filter = tabValue == 0 ? "pending" : "rated"
  //   setAllCoachFeedback(await findAllFeedbackFilter(filter, page))
  // }, [query, page, sortKey, sortDirection, tabValue])
  console.log(videos)

  const handleTabChange = (event, newValue) => {
    setPage(1)
    setTabValue(newValue)
  }

  // useEffect(() => {
  //   const filter = tabValue == 0 ? "pending" : "rated"
  //   findAllFeedbackFilter(filter, page)

  //   console.log(allFeedbackFiltered)
  //   console.log("change")
  // }, [tabValue])

  useEffect(() => {
    const filter = tabValue == 0 ? "pending" : "rated"
    findAllCoachFeedback(
      {
        ...query,
        // sort_key: sortKey,
        // sort_direction: sortDirection,
        filter: filter,
        per_page: 10
      },
      page,
      false,
      "/api/admin/feedback"
    )
  }, [query, page, sortKey, sortDirection, tabValue])

  console.log(tabValue)
  console.log(allCoachFeedback)
  return (
    <>
      <Subheader
        title="Coach feedback"
        // buttons={[
        //   <Button
        //     variant="contained"
        //     startIcon={<Add />}
        //     onClick={handleAddClick}
        //     color="primary"
        //   >
        //     Video
        //   </Button>,
        // ]}
      />
      <Container maxWidth="lg">
        <>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
          >
            <Tab label="Pending" value={0} />
            <Tab label="Rated" value={1} />
          </Tabs>

          <Paper>
            {/* <SearchForm
              isLoading={isLoading}
              query={query}
              handleSearch={findVideos}
            /> */}
            {!isEmpty ? (
              <TableContainer
                component={Paper}
                elevation={0}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEADERS.filter(
                        (h) => !h.hidden
                      ).map((header, idx) => (
                        <SortableTableHeader
                          key={idx}
                          handleSort={handleSort}
                          value={`videos.${header.value}`}
                          sortable={header.sortable}
                          sortKey={sortKey}
                          sortDirection={
                            sortDirection
                          }
                        >
                          {header.label}
                        </SortableTableHeader>
                      ))}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allCoachFeedback?.map((feedback, idx) => (
                      <CoachFeedbackItem
                        key={idx}
                        feedback={feedback}
                        isSelectable={false}
                        selectedIds={selectedIds}
                        handleSelect={handleSelect}
                        tabValue={tabValue}
                        handleClick={() => {}}
                        handleDeleteClick={
                          handleDeleteClick
                        }
                        handleEditClick={
                          handleRateClick
                        }
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Placeholder
                icon={<Search />}
                title="No feedback"
                subtitle="Please try another search"
              />
            )}
          </Paper>
          {!isEmpty && (
            <Box my={1}>
              <Pagination
                onChange={(ev, page) =>
                  paginate(page)
                }
                color="primary"
                size="small"
                shape="rounded"
                page={page}
                count={numPages}
              />
            </Box>
          )}
        </>
      </Container>
    </>
  )
}

export default CoachFeedbackList
