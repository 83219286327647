import React from "react"
import {
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import { LineItem, Image } from "components"

const TrainerDetails = ({ trainer }) => (
  <List>
    <ListItem>
      <Image
        src={trainer.image_url}
        variant="rounded"
        size={240}
      />
    </ListItem>
    <LineItem label="Name" value={`${trainer.first_name} ${trainer.last_name}`} />
    <LineItem
      label="Description"
      value={trainer.description}
    />
    <LineItem
      label="Featured"
      value={trainer.featured ? "Yes" : "No"}
    />
  </List>
)

export default TrainerDetails
