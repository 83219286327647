import React from "react"
import { useNavigate } from "react-router-dom"

const useNavigation = ({ url = "" }) => {
  const history = useNavigate()

  const handleShowClick = (resource) =>
    history(`${url}/${resource.id}`)
  const handleEditClick = (resource) =>
    history(`${url}/${resource.id}/edit`)
  const handleAddClick = () =>
    history(`${url}/new`)
  const handleRateClick = (resource) =>
    history(`${url}/${resource.captured_video_id}/rate`)

  return {
    handleClick: handleShowClick,
    handleShowClick,
    handleEditClick,
    handleAddClick,
    handleRateClick,

    toShow: handleShowClick,
    toEdit: handleEditClick,
    toAdd: handleAddClick,
  }
}

export default useNavigation
