import React, { useContext } from "react"
import { AppContext } from "context"
import { Navigate } from "react-router-dom"

const ProtectedRoute = ({ children }) => {
  const { authenticated } = useContext(AppContext)

  if(authenticated === false) {
    return (<Navigate to="/login" />)
  } 

  return children
}

export default ProtectedRoute
