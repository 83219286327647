import React, { useEffect } from "react"
import {
  useLessons,
  useAlerts,
  useNavigation,
  useSelected,
  useCourses,
} from "hooks"
import {
  Layout,
  SortableTableHeader,
  Column,
  Subheader,
  Page,
  Placeholder,
  CourseAutosuggest,
} from "components"
import Pagination from "@material-ui/lab/Pagination"
import {
  Checkbox,
  Container,
  Box,
  Paper,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Toolbar,
} from "@material-ui/core"
import { Add, Search } from "@material-ui/icons"
import LessonItem from "containers/lessons/LessonItem"
import LessonToolbar from "containers/lessons/LessonToolbar"
// import SearchForm from "containers/lessons/SearchForm"
import SearchForm from "components/SearchForm"
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from "react"

const TABLE_HEADERS = [
  // {
  //   value: "image_url",
  //   label: "Image",
  //   sortable: true,
  //   hidden: false,
  // },
  {
    value: "position",
    label: "Position",
    sortable: true,
    hidden: false,
  },

  {
    value: "title",
    label: "Title",
    sortable: true,
    hidden: false,
  },

  // {
  //   value: "category",
  //   label: "Category",
  //   sortable: true,
  //   hidden: false,
  // },

  {
    value: "difficulty",
    label: "Difficulty",
    sortable: true,
    hidden: false,
  },

  // {
  //   value: "course_id",
  //   label: "Course",
  //   sortable: true,
  //   hidden: false,
  // },
  {
    value: "premium",
    label: "Premium",
    sortable: true,
    hidden: false,
  },
]

const LessonList = ({ ...props }) => {
  const history = useNavigate()
  const {
    isLoading,
    isLoaded,
    isEmpty,
    lessons,
    findLessons,
    deleteLesson,
    paginate,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    reloadLessons,
    sortKey,
    sortDirection,
    handleSort,
  } = useLessons({})

  const {
    handleClick,
    handleEditClick,
    handleAddClick,
  } = useNavigation({
    history,
    url: "/lessons",
  })

  const {
    selected,
    selectedIds,
    handleSelect,
    handleClear,
    handleSelectAll,
    totalSelected,
  } = useSelected()

  const { id } = useParams()

  const [ course, setCourse ] = useState([]);

  const handleChangeCourse = (e) => {
    setCourse(e.target.value);
  }

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleDeleteClick = async (lesson) => {
    await deleteLesson(lesson, '/api/admin/lessons')
    showAlertSuccess("Lesson has been deleted")
    reloadLessons(`/api/lessons?course_id=${course}`)
  }

  useEffect(() => {
    console.log(course)
    findLessons(
      {
        ...query,
        // sort_key: sortKey,
        // sort_direction: sortDirection,
      },
      page,
      false,
      `/api/lessons?course_id=${course}`
    )
  }, [query, page, sortKey, sortDirection, course])
  console.log(lessons)
  console.log((Array.isArray(lessons) && lessons.length > 0))
  return (
    <>
      <Subheader
        title="Lessons"
        buttons={[
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleAddClick}
            color="primary"
          >
            Lesson
          </Button>,
        ]}
      />
      <Container maxWidth="lg">
        <>
          <Paper>
            <div style={{padding: "8px 16px"}}>
              <CourseAutosuggest
                value={course?.id}
                label="Course"
                name="course_id"
                placeholder="Course"
                handleChange={handleChangeCourse}
              />
            </div>

            <SearchForm
              isLoading={isLoading}
              query={query}
              handleSearch={findLessons}
              customUrl={`/api/lessons?course_id=${course}`}
            />
            {!isEmpty && Array.isArray(lessons) && lessons.length > 0 ? (
              <TableContainer
                component={Paper}
                elevation={0}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEADERS.filter(
                        (h) => !h.hidden
                      ).map((header, idx) => (
                        <SortableTableHeader
                          key={idx}
                          handleSort={handleSort}
                          value={`lessons.${header.value}`}
                          sortable={header.sortable}
                          sortKey={sortKey}
                          sortDirection={
                            sortDirection
                          }
                        >
                          {header.label}
                        </SortableTableHeader>
                      ))}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(lessons) && (lessons.map((lesson, idx) => (
                      <LessonItem
                        key={idx}
                        lesson={lesson}
                        isSelectable={false}
                        selectedIds={selectedIds}
                        handleSelect={handleSelect}
                        handleClick={handleClick}
                        handleDeleteClick={
                          handleDeleteClick
                        }
                        handleEditClick={
                          handleEditClick
                        }
                      />
                    )))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Placeholder
                icon={<Search />}
                title="No lessons"
                subtitle="Please try another search"
              />
            )}
          </Paper>
          {!isEmpty && Array.isArray(lessons) && lessons.length > 0 && (
            <Box my={1}>
              <Pagination
                onChange={(ev, page) =>
                  paginate(page)
                }
                color="primary"
                size="small"
                shape="rounded"
                page={page}
                count={numPages}
              />
            </Box>
          )}
        </>
        <LessonToolbar
          selected={selected}
          selectedIds={selectedIds}
          handleClear={handleClear}
          totalSelected={totalSelected}
        />
      </Container>
    </>
  )
}

export default LessonList
