import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import {
  useTrainers,
  useForm,
  useAlerts,
} from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { ImageUploader, Subheader } from "components"
import { Check } from "@material-ui/icons"
import TrainerForm from "containers/trainers/TrainerForm"
import { validateTrainer } from "validations/trainers"

const TrainerEdit = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const [image, setImage] = useState()

  const {
    trainerId,
    trainerDTO,
    isLoading,
    isLoaded,
    trainer,
    handleChange,
    updateTrainer,
    findTrainer,
    uploadFile,
    deleteFile,
  } = useTrainers({ id: id })

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleSaveClick = async () => {
    const valid = validateTrainer(trainer, {
      image: image
    })
    if (valid.isValid) {
      trainer.featured = trainer.featured == true ? 1 : 0

      let tempTrainer = JSON.parse(JSON.stringify(trainer));

      for(const prop in trainer) {
        if(prop != "id"){
          // Remove props if there is no change and update DTO obj if props change
          if(trainer[prop] == trainerDTO[prop]) {
            // console.log(prop)
            delete tempTrainer[prop]
          } else if((Array.isArray(trainer[prop]) || typeof trainer[prop] === 'object') && JSON.stringify(trainer[prop]) == JSON.stringify(trainerDTO[prop])) {
            // console.log(prop)
            delete tempTrainer[prop]
          } else {
            tempTrainer[prop] = trainer[prop]
          }
        }
      }

      await updateTrainer(tempTrainer, "/api/admin/trainers/update")
      showAlertSuccess("Trainer has been updated")
      // console.log("remove this from here")
      history(`/trainers/${id}`)
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  const handleUpload = async (file) => {
    setImage(file.preview)
    trainer.image = file
    // await uploadFile(file, "image_url")
    // findTrainer(id)
  }

  const handleDeleteImage = async (file) => {
    setImage(undefined)
    // await deleteFile("image_url")
    // findTrainer(id)
  }

  useEffect(() => {
    if (id) findTrainer(id, "/api/trainer")
  }, [])

  useEffect(() => {
    setImage(trainer.image_url)
  }, [trainer])

  console.log(trainer)

  return (
    <>
      <Subheader
        title="Edit Trainer"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>,
        ]}
      />
      <Container>
        <Paper>
          <Box p={4}>
            <center>
              <ImageUploader
                label="Upload image (2:3)"
                imageUrl={image}
                handleUpload={(file) =>
                  handleUpload(file)
                }
                handleDelete={() =>
                  setImage(undefined)
                }
              />
            </center>
            <TrainerForm
              trainer={trainer}
              handleChange={handleChange}
            />
            <Box py={2}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Check />}
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default TrainerEdit
