import React from "react"
import api from "api"
import {
  useAlerts,
  useLoaders,
  useResource,
} from "hooks"

const useCourses = ({ id, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } =
    useAlerts()

  const {
    resourceId: courseId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: course,
    resourceDTO: courseDTO,
    resources: courses,
    findOne: findCourse,
    findMany: findCourses,
    update: updateCourse,
    create: createCourse,
    save: saveCourse,
    destroy: deleteCourse,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    reload: reloadCourse,
    reloadMany: reloadCourses,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
  } = useResource({
    id: id,
    url: "/api/courses",
    name: "course",
  })

  const resortLessons = (lessons) => {
    const sorted = lessons.map((item) => ({
      id: item.id,
      position: item.position,
    }))
    api.post(
      `/api/v1/admin/courses/${course.id}/resort`,
      {
        lessons: sorted,
      }
    )
    return sorted
  }

  return {
    courseId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    course,
    courseDTO,
    courses,
    findCourse,
    findCourses,
    saveCourse,
    updateCourse,
    createCourse,
    deleteCourse,
    uploadFile,
    deleteFile,
    handleChange,
    handleChangePage,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    reloadCourse,
    reloadCourses,
    resortLessons,
  }
}

export default useCourses
