import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import {
  useVideos,
  useAlerts,
  useUploadFileInMemory,
} from "hooks"
import {
  Box,
  Button,
  Container,
} from "@material-ui/core"
import { Check } from "@material-ui/icons"
import { CourseAutosuggest, ImageUploader, LessonAutosuggest, Subheader } from "components"
import VideoForm from "containers/videos/VideoForm"

const VideoNew = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()
  const [tags, setTags] = useState()

  const { memoryFile, removeFile, handleUploadFile } =
    useUploadFileInMemory()

  const {
    video,
    handleChange,
    createVideo,
    findVideo,
    findVideoTags,
    deleteFile,
  } = useVideos({
    id: id,
  })

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const [ course, setCourse ] = useState([]);
  const [ lesson, setLesson ] = useState([]);

  const handleFindVideoTags = async () => {
    let resp = await findVideoTags()
    let videoTags = resp.map((tag) => ({
      label: tag,
      value: tag,
    }))
    setTags(videoTags)
  }

  const handleSaveClick = async () => {
    await createVideo(video, memoryFile)
    showAlertSuccess("Video has been created")
    console.log('REMOVE THIS FROM HERE')
    // history("/videos")
  }

  useEffect(() => {
    handleFindVideoTags()
  }, [])

  const handleDelete = async (type) => {
    removeFile()
  }

  const handleChangeCourse = (e) => {
    setCourse(e.target.value);
  }

  const handleChangeLesson = (e) => {
    setLesson(e.target.value);
  }

  return (
    <>
      <Subheader
        title="Add Videos"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>,
        ]}
      />
      <Container>
        <Box p={4}>
          <CourseAutosuggest
            value={course.id}
            label="Course"
            name="course_id"
            placeholder="Course"
            handleChange={handleChangeCourse}
          />
          <LessonAutosuggest
            value={video.lesson_id}
            label="Lesson"
            name="lesson_id"
            placeholder="Select lesson"
            handleChange={handleChangeLesson}
          />
          <VideoForm
            video={video}
            tags={tags}
            handleChange={handleChange}
          />
          <Box py={2}>
            <Button
              startIcon={<Check />}
              color="primary"
              variant="contained"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default VideoNew
