import { isEmpty } from "validations"

export const validateBenefit = (benefit, files = null) => {
  let isValid = true
  let messages = []

  if (isEmpty(benefit.title)) {
    isValid = false
    messages.push("Title is required")
  }

  if (isEmpty(benefit.body)) {
    isValid = false
    messages.push("Body is required")
  }

  // if (isEmpty(benefit.image)) {
  //   isValid = false
  //   messages.push("Image is required")
  // }

  // if (isEmpty(benefit.category)) {
  //   isValid = false
  //   messages.push("Category is required")
  // }

  if (isEmpty(benefit.is_promotion)) {
    isValid = false
    messages.push("Promotion is required")
  }

  if (isEmpty(benefit.active)) {
    isValid = false
    messages.push("Active is required")
  }

  // if (isEmpty(benefit.url)) {
  //   isValid = false
  //   messages.push("Url is required")
  // }

    // Media related queries
    if(files?.image == undefined) {
      isValid = false
      messages.push("Image is required")
    }

  let validation = {
    isValid,
    messages,
  }

  return validation
}
