import React from "react"
import { List, makeStyles } from "@material-ui/core"
import { Label, Rating, LineItem } from "components"

const CourseDetails = ({ course }) => {
  const classes = useStyles()
  return (
    <List>
      <LineItem label="Title" value={course.title} />
      <LineItem
        label="Subtitle"
        value={course.subtitle}
      />
      <LineItem
        label="Trainer"
        value={course.trainer}
      />
      <LineItem
        label="Description"
        value={course.description}
      />
      {Array.isArray(course.category) && (
        <LineItem
          label="Categories"
          value={course?.category?.map((label, i) => (
            <Label key={i} label={label} />
          ))}
        />
      )}
      <LineItem
        label="What You Need"
        value={course?.what_you_need?.map(
          (label, i) => (
            <Label key={i} label={label} />
          )
        )}
      />
      {course.jw_captions &&
        Object.keys(course.jw_captions).map(
          (language, i) => (
            <LineItem
              key={i}
              label={`${language} captions`}
              value={course?.jw_captions[language]}
            />
          )
        )}
      {/* <LineItem
        label="JW Player ID"
        value={course?.jw_player_id}
      /> */}
      <LineItem
        label="Difficulty"
        value={<Rating value={course.difficulty} />}
      />
      <LineItem
        label="Featured"
        value={
          <Label
            label={course.featured ? "Yes" : "No"}
          />
        }
      />
      <LineItem
        label="Suggested training"
        value={
          <Label
            label={course.suggested_training ? "Yes" : "No"}
          />
        }
      />
      {/* <LineItem
        label="Recommended"
        value={
          <Label
            label={course.recommended ? "Yes" : "No"}
          />
        }
      /> */}
      <LineItem
        label="Premium"
        value={
          <Label
            label={course.premium ? "Yes" : "No"}
          />
        }
      />
      <LineItem
        label="Active"
        value={
          <Label
            label={course.active ? "Yes" : "No"}
          />
        }
      />
    </List>
  )
}

export default CourseDetails

const useStyles = makeStyles((theme) => ({
  captions: {
    whiteSpace: "pre-wrap",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}))
