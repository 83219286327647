import React from "react"
import { useMenu } from "hooks"
import {
  Hidden,
  Button,
  IconButton,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Avatar,
  Checkbox,
} from "@material-ui/core"
import { Image } from "components"
import { MoreHoriz, Person } from "@material-ui/icons"

const LessonItem = ({
  lesson,
  isSelectable,
  selectedIds = [],
  handleSelect,
  handleClick,
  handleEditClick,
  handleDeleteClick,
  ...props
}) => {
  const {
    open,
    anchorEl,
    handleToggleMenu,
    handleClose,
  } = useMenu()

  const renderMenu = () => (
    <Menu
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
    >
      <MenuItem
        onClick={(e) => handleEditClick(lesson, e)}
      >
        Edit
      </MenuItem>
      <MenuItem
        onClick={(e) => handleDeleteClick(lesson, e)}
      >
        Delete
      </MenuItem>
    </Menu>
  )

  const isSelected = selectedIds.includes(lesson.id)

  return (
    <TableRow>
      {isSelectable && (
        <TableCell>
          <Checkbox
            checked={isSelected ? true : false}
            onChange={() => handleSelect(lesson)}
            name="lesson"
            color="primary"
          />
        </TableCell>
      )}
      {/* <TableCell>
        <Button onClick={() => handleClick(lesson)}>
          <Image
            src={lesson?.thumbnail_url}
            variant="rounded"
            size={80}
          />
        </Button>
      </TableCell> */}
      <TableCell>
        <Button onClick={() => handleClick(lesson)}>
          {lesson?.position?.name}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(lesson)}>
          {lesson?.title}
        </Button>
      </TableCell>
      {/* <TableCell>
        <Button onClick={() => handleClick(lesson)}>
          {lesson?.category?.split("_").join(" ")}
        </Button>
      </TableCell> */}
      <TableCell>
        <Button onClick={() => handleClick(lesson)}>
          {lesson?.difficulty}
        </Button>
      </TableCell>
      {/* <TableCell>
        <Button onClick={() => handleClick(lesson)}>
          {lesson?.course?.title}
        </Button>
      </TableCell> */}
      <TableCell>
        <Button onClick={() => handleClick(lesson)}>
          {lesson.premium ? "Yes" : "No"}
        </Button>
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={(e) => handleToggleMenu(e)}
        >
          <MoreHoriz />
          {renderMenu()}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default LessonItem
