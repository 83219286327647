import React from "react"
import { Grid } from "@material-ui/core"
import {
  MultiAutosuggest,
  UserAutosuggest,
  TextInput,
  LessonAutosuggest,
} from "components"

const CoachFeedbackForm = ({ feedback, handleChange }) => (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={12}>
      <TextInput
        rows={3}
        multiline
        value={feedback?.coachFeedback}
        label="Feedback"
        name="description"
        // placeholder="Feedback"
        handleChange={handleChange}
      />
    </Grid>

    <Grid item xs={12} sm={12}>
      <TextInput
        value={feedback?.feedback?.sho}
        label="Shooting"
        name="sho"
        // placeholder="SHO"
        handleChange={handleChange}
      />
    </Grid>

    <Grid item xs={12} sm={12}>
      <TextInput
        value={feedback?.feedback?.dri}
        label="Dribbling"
        name="dri"
        // placeholder="DRI"
        handleChange={handleChange}
      />
    </Grid>

    <Grid item xs={12} sm={12}>
      <TextInput
        value={feedback?.feedback?.con}
        label="Control"
        name="con"
        // placeholder="CON"
        handleChange={handleChange}
      />
    </Grid>

    <Grid item xs={12} sm={12}>
      <TextInput
        value={feedback?.feedback?.phy}
        label="Physical"
        name="phy"
        // placeholder="PHY"
        handleChange={handleChange}
      />
    </Grid>

    <Grid item xs={12} sm={12}>
      <TextInput
        value={feedback?.feedback?.pas}
        label="Passing"
        name="pas"
        // placeholder="PAS"
        handleChange={handleChange}
      />
    </Grid>

    <Grid item xs={12} sm={12}>
      <TextInput
        value={feedback?.feedback?.def}
        label="Defensive"
        name="def"
        // placeholder="DEF"
        handleChange={handleChange}
      />
    </Grid>
  </Grid>
)

export default CoachFeedbackForm
