import React, { useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useBenefits, useAlerts } from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import { Subheader } from "components"
import BenefitDetails from "containers/benefits/BenefitDetails"

const BenefitShow = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const {
    benefitId,
    isLoading,
    isLoaded,
    benefit,
    findBenefit,
  } = useBenefits({
    id: id,
  })

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleEditClick = () =>
    history(`/benefits/${id}/edit`)

  useEffect(() => {
    if (id) findBenefit(id, '/api/benefit')
  }, [])

  return (
    <>
      <Subheader
        title={
          isLoading
            ? "Loading..."
            : `Benefit ${benefit.id}`
        }
        buttons={[
          <Button
            onClick={handleEditClick}
            color="primary"
            variant="contained"
          >
            Edit
          </Button>,
        ]}
      />
      <Container>
        <Paper>
          <Box p={4}>
            <BenefitDetails benefit={benefit} />
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default BenefitShow
