import React from "react"
import {
  Avatar,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core"
import { Photo } from "@material-ui/icons"

const VideoItem = ({
  video,
  handleClick,
  ...rest
}) => {
  return (
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <Avatar
          size={80}
          variant="rounded"
          src={video.image_url}
        >
          <Photo />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={video.title}
        secondary={video.description}
      />
    </ListItem>
  )
}

export default VideoItem
