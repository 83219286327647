import React from "react"
import { Grid } from "@material-ui/core"
import {
  MultiAutosuggest,
  UserAutosuggest,
  TextInput,
  LessonAutosuggest,
} from "components"

const VideoForm = ({ tags, video, handleChange }) => (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={12}>

    </Grid>
    
    <Grid item xs={12} sm={12}>
      <TextInput
        value={video.url}
        label="Name"
        name="name"
        placeholder="Name"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput
        value={video.description}
        label="Description"
        name="description"
        placeholder="Description"
        handleChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      {tags?.length > 0 && (
        <MultiAutosuggest
          options={tags}
          value={video.tags || []}
          label="Tags"
          name="tags"
          placeholder="Tags"
          handleChange={handleChange}
        />
      )}
    </Grid>
  </Grid>
)

export default VideoForm
