import React from "react"
import api from "api"
import {
  useAlerts,
  useLoaders,
  useResource,
} from "hooks"
import { validatePosition } from "validations/trainers"

const useFilterTag = ({ id, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } =
    useAlerts()

  const {
    resourceId: filterTagId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: filterTag,
    resources: filterTags,
    findOne: findFilterTag,
    findMany: findFilterTags,
    update: updateFilterTag,
    create: createFilterTag,
    save: saveFilterTag,
    destroy: deleteFilterTag,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    refresh: reloadFilterTag,
    refreshMany: reloadFilterTags,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
  } = useResource({
    id: id,
    url: "/api/positions",
    name: "position",
  })

  return {
    filterTagId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    filterTag,
    filterTags,
    findFilterTag,
    findFilterTags,
    saveFilterTag,
    updateFilterTag,
    createFilterTag,
    deleteFilterTag,
    uploadFile,
    deleteFile,
    handleChange,
    handleChangePage,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    reloadFilterTag,
    reloadFilterTags,
  }
}

export default useFilterTag
