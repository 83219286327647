import React from "react"
import api from "api"
import {
  useAlerts,
  useLoaders,
  useResource,
} from "hooks"
import { validateTrainer } from "validations/trainers"

const useTrainers = ({ id, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } =
    useAlerts()

  const {
    resourceId: trainerId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: trainer,
    resourceDTO: trainerDTO,
    resources: trainers,
    findOne: findTrainer,
    findMany: findTrainers,
    update: updateTrainer,
    create: createTrainer,
    save: saveTrainer,
    destroy: deleteTrainer,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    refresh: reloadTrainer,
    refreshMany: reloadTrainers,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
  } = useResource({
    id: id,
    url: "/api/trainers?per_page=99999",
    name: "trainer",
  })

  return {
    trainerId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    trainer,
    trainerDTO,
    trainers,
    findTrainer,
    findTrainers,
    saveTrainer,
    updateTrainer,
    createTrainer,
    deleteTrainer,
    uploadFile,
    deleteFile,
    handleChange,
    handleChangePage,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    reloadTrainer,
    reloadTrainers,
  }
}

export default useTrainers
