import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useCourses, useAlerts } from "hooks"
import {
  Box,
  Button,
  Container,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core"
import {
  VideoUploader,
  ImageUploader,
  Subheader,
} from "components"
import { Check } from "@material-ui/icons"
import CourseForm from "containers/courses/CourseForm"
import { validateCourse } from "validations/courses"
import { FormatJsonMultiSelectFieldValues } from "lib/helpers";
import usePositions from "hooks/usePositions";
import useCategories from "hooks/useCategories";
import { get } from "lodash";
import useFilterTag from "hooks/useFilterTag";

const CourseEdit = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const [tabValue, setTabValue] = useState(0)

  const {
    course,
    courseDTO,
    handleChange,
    updateCourse,
    findCourse,
    uploadFile,
    deleteFile,
  } = useCourses({ id: id })

  const [imageUrl, setImageUrl] = useState()
  const [heroImageUrl, setHeroImageUrl] = useState()
  const [previewVideoUrl, setPreviewVideoUrl] = useState()

  const [selectedCategories, setSelectedCategories] = useState([])

  const { showAlertSuccess, showAlertError } =
    useAlerts()

    const {
      categories,
      findCategories
    } = useCategories({ id: id })

    const {
      positionId,
      positions,
      findPositions,
    } = usePositions({
      id: id,
    })

    const {
      filterTagId,
      isLoading,
      isLoaded,
      filterTags,
      findFilterTags,
    } = useFilterTag({
      id: id,
    })

  const handleSaveClick = async () => {
    // console.log(course)
    console.log("COURSE WAS UPDATED")

    const valid = validateCourse(course, {
      image: imageUrl,
      hero_image: heroImageUrl,
      preview_video: previewVideoUrl
    })

    if (valid.isValid) {
      // Makes sure you do not json stringify an already serialized string, useful for multiselect fields
      // course.what_you_need = FormatJsonMultiSelectFieldValues(course.what_you_need)
      // course.what_you_need = JSON.stringify(course.what_you_need)
      // if(Array.isArray(course.what_you_need)) {
      //   course.what_you_need = course.what_you_need.join(',')
      // }
      // course.category = FormatJsonMultiSelectFieldValues(course.category)

      course.what_you_need = Array.isArray(course) ? course?.what_you_need.join(',') : course.what_you_need
      // course.category = FormatJsonMultiSelectFieldValues(course.category)
      // course.category = Array.isArray(course) ? course?.category.join(',') : course.category

      course.active = course.active ? 1 : 0
      course.premium = course.premium ? 1 : 0
      course.suggested_training = course.suggested_training ? 1 : 0
      course.featured = course.featured ? 1 : 0

      let categoriesArr = []

      if(Array.isArray(course.category)) {
        for(let i = 0; i < course.category.length; i++) {
          // console.log("call here")
          categoriesArr[`category[${i}]`] = course.category[i]
        }
      } 




      // course.suggested_training = course.suggested_training === 'true'
      // course.featured = course.featured === 'true'
      // course.premium = course.premium === 'true'
      // course.active = course.active === 'true'

      // delete course.trainer
      // delete course.created_at
      // delete course.updated_at
      // delete course.lessons
      // delete course.position
      // delete course.lesson_count
      // delete course.user_enrolled
      // delete course.skills_course
      // delete course.image_url
      // delete course.hero_image_url
      // delete course.preview_video_url

      let tempCourse = JSON.parse(JSON.stringify(course));

      for(const prop in course) {
        if(prop != "id"){
          // Remove props if there is no change and update DTO obj if props change
          if(course[prop] == courseDTO[prop]) {
            // console.log(prop)
            delete tempCourse[prop]
          } else if((Array.isArray(course[prop]) || typeof course[prop] === 'object') && JSON.stringify(course[prop]) == JSON.stringify(courseDTO[prop])) {
            // console.log(prop)
            delete tempCourse[prop]
          // } else if(courseDTO[prop] != undefined) {
          } else {
            tempCourse[prop] = course[prop]
          }
        }
      }

      console.log("check this")
      console.log(tempCourse)
      console.log(course)

      let x = {
        ...tempCourse,
        ...categoriesArr
      }

      delete x.category

      // console.log(x)
      
      await updateCourse(x, '/api/admin/courses/update')
      showAlertSuccess("Course has been updated")
      // console.log('REMOVE THIS COMMENT')
      history(`/courses/${id}`)
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleUpload = async (file, type) => {
    if(type == "image") {
      setImageUrl(file.preview)
      course.image = file
    } else if(type == "hero_image") {
      setHeroImageUrl(file.preview)
      course.hero_image = file
    } else if(type == "preview_video") {
      course.preview_video = file
    }

    // await uploadFile(file, type)
    // findCourse(id)
  }

  // console.log(course)

  const handleDelete = async (type) => {
    setImageUrl(undefined)
  }

  useEffect(() => {
    if (id) {
      findCourse(id, '/api/course')
      // setSelectedCategories([{ value: 1, label: "dribbling" }])
    }

    findCategories()
    findPositions()
    findFilterTags()
  }, [])

  useEffect(() => {
    setImageUrl(course.image_url)
    setHeroImageUrl(course.hero_image_url)
    setPreviewVideoUrl(course.preview_video_url)

    if(Array.isArray(course.categories) && course.categories.length > 0) {
      let tempPos = []
      course.categories.forEach((opt) => {
        tempPos.push({
          value: get(opt, "id"),
          label: get(opt, "name")
        })
      })
      // console.log(value)
      // console.log(tempPos)
      setSelectedCategories(tempPos)
      // setformattedValue(tempPos)
    }
  }, [course])

  // 
  // useEffect(() => {
  //   if(course.what_you_need != undefined) {
  //     try {
  //       console.log(course.what_you_need)
  //       course.what_you_need = JSON.parse(course.what_you_need);
  //     } catch(e) {
  //       console.log(e)
  //     }
  //   }
  // }, [course])
  // console.log(imageUrl)
  const images = [
    { value: "image", label: "Image" },
    { value: "hero_image", label: "Hero image" },
    // { value: "image2_url", label: "image 2" },
    // { value: "image3_url", label: "image 3" },
    // { value: "image4_url", label: "image 4" },
    // { value: "web_image_url", label: "web image 1" },
    // { value: "web_image2_url", label: "web image 2" },
    // { value: "web_image3_url", label: "web image 3" },
    // { value: "web_image4_url", label: "web image 4" },
    // { value: "web_image5_url", label: "web image 5" },
  ]
  // console.log(course)
  // console.log(course)

  return (
    <>
      <Subheader
        title="Edit Course"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>,
        ]}
      />
      <Container>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab label="Details" value={0} />
          <Tab label="Media" value={1} />
        </Tabs>
        <Paper>
          <Box p={4}>
            {tabValue == 1 ? (
              <div>
                <ImageUploader
                  label={`Upload image`}
                  imageUrl={imageUrl}
                  handleUpload={(file) =>
                    handleUpload(file, "image")
                  }
                  handleDelete={() =>
                    setImageUrl(undefined)
                  }
                />

                <ImageUploader
                  label={`Upload hero image`}
                  imageUrl={heroImageUrl}
                  handleUpload={(file) =>
                    handleUpload(file, "hero_image")
                  }
                  handleDelete={() =>
                    setHeroImageUrl(undefined)
                  }
                />

                {/* {images.map((image, index) => (
                  <ImageUploader
                    key={index}
                    label={`Upload ${image.label}`}
                    imageUrl={course[`${image.value}_url`]}
                    handleUpload={(file) =>
                      handleUpload(file, [
                        image.value,
                      ])
                    }
                    handleDelete={() =>
                      handleDelete(`${image.value}_url`)
                    }
                  />
                ))} */}

                <VideoUploader
                  label="Upload preview video"
                  videoUrl={previewVideoUrl}
                  handleUpload={(file) =>
                    handleUpload(file, "preview_video")
                  }
                  handleDelete={() =>
                    setPreviewVideoUrl(undefined)
                  }
                />
              </div>
            ) : (
              <div>
                <CourseForm
                  course={course}
                  handleChange={handleChange}
                  editPage={true}
                  filterTags={filterTags}
                  categories={categories}
                  selectedCategories={selectedCategories}
                />
              </div>
            )}
            <Box py={2}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Check />}
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default CourseEdit
