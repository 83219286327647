import React, {
  useContext,
  useEffect,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { useAlerts, useLoaders } from "hooks"
import api from "api"
import { AppContext } from "context"

const useAuth = (props) => {
  const history = useNavigate()

  const { showLoading, hideLoading } = useLoaders()

  const { showAlertError, showAlertSuccess } =
    useAlerts()

  const {
    authenticated,
    setAuthenticated,
    currentUser,
    setCurrentUser,
    setToken,
  } = useContext(AppContext)

  const [user, setUser] = useState({})

  const handleChange = (ev) => {
    const { name } = ev.target
    let value =
      ev.target.type === "checkbox"
        ? ev.target.checked
        : ev.target.value
    setUser({
      ...user,
      [name]: value,
    })
  }

  const fetchMe = async () => {
    try {
      let resp = await api.get('/api/user/profile')
      if (resp && resp.data) {
        setCurrentUser(resp.data)
      }
      return resp.data
    } catch (e) {
      if (e?.status === 401 || e === undefined) {
        showAlertError("Please sign in to continue.")
        localStorage.removeItem("token")
        window.location.href = "/"
      }
    }
  }

  const updateMe = async (user) => {
    showLoading()
    let resp = await api.put(
      `/api/v1/users/${user.id}`,
      {
        user: user,
      }
    )
    setCurrentUser(resp.data)
    hideLoading()
    return resp.data
  }

  const loginUser = async (user) => {
    // console.log(setAuthenticated());

    try {
      showLoading()
      let resp = await api.post("/api/auth/login", {
        email: user.email,
        password: user.password
      })
      
      if (resp.data) {
        if(resp.data.role != "admin") {
          showAlertError(
            "There is no admin account with these credentials"
          )
          hideLoading()

          return false
        }

        setCurrentUser(resp.data)
        setAuthenticated(true)
        await setTokens(resp.token)
        window.location.href = "/users"
        return resp.data
      } else {
        return false
      }
      hideLoading()
    } catch (e) {
      showAlertError(
        "Your email or password is incorrect"
      )
      hideLoading()
    }
  }

  const signupUser = async (user) => {
    try {
      showLoading()
      let resp = await api.post("/api/v1/signup", {
        user: user,
      })
      hideLoading()
      if (resp.data) {
        setCurrentUser(resp.data)
        setAuthenticated(true)
        setTokens(resp.data)
        return resp.data
      } else {
        return false
      }
    } catch (e) {
      showAlertError(
        "A user with this email may already exist"
      )
      hideLoading()
    }
  }

  const resetPassword = async (
    password,
    passwordConfirmation,
    pin
  ) => {
    console.log(password, passwordConfirmation, pin)
    try {
      showLoading()
      let resp = await api.post(
        "/api/auth/reset-password",
        {
          code: pin,
          password: password,
          password_confirmation: passwordConfirmation,
        }
      )

      console.log(resp)
      hideLoading()
      if (resp.data) {
        setCurrentUser(resp.data)
        setAuthenticated(true)
        setTokens(resp.data)
        return resp.data
      } else {
        return false
      }
    } catch (e) {
      showAlertError(
        "There was an error resetting your password"
      )
      hideLoading()
    }
  }

  const verifyPin = async (pin, email) => {
    let verified = false
    try {
      showLoading()
      let resp = await api.post(
        "/api/v1/verify_pin",
        {
          user: {
            pin,
            email,
          },
        }
      )
      if (resp.data?.id) {
        setCurrentUser(resp.data)
        setAuthenticated(true)
        setTokens(resp.data)
        return resp.data
      } else {
        return false
      }
      hideLoading()
      return verified
    } catch (e) {
      hideLoading()
      return verified
    }
  }

  const forgotPassword = async (email) => {
    try {
      showLoading()
      let resp = await api.post(
        "/api/auth/forgot-password",
        {
          email: email,
        }
      )
      setCurrentUser(resp.data)
      hideLoading()
      return resp.data
    } catch (e) {
      showAlertError(
        "A user with that email could not be found"
      )
      hideLoading()
    }
  }

  const logoutUser = () => {
    setCurrentUser(null)
    setAuthenticated(null)
    localStorage.removeItem("token")
    window.location.href = "/"
  }

  const authenticateFromToken = (token) => {
    if (token) {
      setAuthenticated(true)
      setToken(token)
    } else {
      return false
    }
  }

  const setTokens = (token) => {
    setToken(token)
    localStorage.setItem("token", token)
  }

  useEffect(() => {
    if (currentUser && !authenticated) {
      setTokens(currentUser)
      setAuthenticated(true)
    }
  }, [currentUser])

  return {
    user,
    handleChange,
    fetchMe,
    updateMe,
    loginUser,
    signupUser,
    logoutUser,
    verifyPin,
    resetPassword,
    forgotPassword,
    authenticateFromToken,
  }
}

export default useAuth
