import React from "react"
import { useMenu } from "hooks"
import {
  Hidden,
  Button,
  IconButton,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Avatar,
  Checkbox,
} from "@material-ui/core"
import { Image } from "components"
import { MoreHoriz, Person } from "@material-ui/icons"

const UserItem = ({
  user,
  isSelectable,
  selectedIds = [],
  handleSelect,
  handleClick,
  handleEditClick,
  handleDeleteClick,
  ...props
}) => {
  const {
    open,
    anchorEl,
    handleToggleMenu,
    handleClose,
  } = useMenu()

  const renderMenu = () => (
    <Menu
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
    >
      <MenuItem
        onClick={(e) => handleEditClick(user, e)}
      >
        Edit
      </MenuItem>
      <MenuItem
        onClick={(e) => handleDeleteClick(user, e)}
      >
        Delete
      </MenuItem>
    </Menu>
  )

  const isSelected = selectedIds.includes(user.id)

  return (
    <TableRow>
      {isSelectable && (
        <TableCell>
          <Checkbox
            checked={isSelected ? true : false}
            onChange={() => handleSelect(user)}
            name="user"
            color="primary"
          />
        </TableCell>
      )}
      {/* <TableCell>
        <Button onClick={() => handleClick(user)}>
          <Image
            src={user.image_url}
            variant="rounded"
            size={80}
          />
        </Button>
      </TableCell> */}
      <TableCell>
        <Button onClick={() => handleClick(user)}>
          {user.first_name}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(user)}>
          {user.last_name}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(user)}>
          {user.email}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(user)}>
          {user.role}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(user)}>
          {user.featured ? "Yes" : "-"}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(user)}>
          {user.paid ? "Yes" : "-"}
        </Button>
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={(e) => handleToggleMenu(e)}
        >
          <MoreHoriz />
          {renderMenu()}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default UserItem
