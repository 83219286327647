import React, { useEffect } from "react"
import {
  useVideos,
  useAlerts,
  useNavigation,
  useSelected,
} from "hooks"
import {
  SortableTableHeader,
  Subheader,
  Placeholder,
} from "components"
import Pagination from "@material-ui/lab/Pagination"
import {
  Container,
  Box,
  Paper,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core"
import { Add, Search } from "@material-ui/icons"
import VideoItem from "containers/videos/VideoItem"
// import SearchForm from "containers/videos/SearchForm"
import SearchForm from "components/SearchForm"
import { useNavigate } from 'react-router-dom';

const TABLE_HEADERS = [
  {
    value: "image_url",
    label: "Image",
    sortable: false,
    hidden: false,
  },
  {
    value: "description",
    label: "Description",
    sortable: false,
    hidden: false,
  },
  {
    value: "tags",
    label: "Tags",
    sortable: false,
    hidden: false,
  },
]

const VideoList = ({ ...props }) => {
  const history = useNavigate()
  const {
    isLoading,
    isLoaded,
    isEmpty,
    videos,
    findVideos,
    deleteVideo,
    paginate,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    reloadVideos,
    sortKey,
    sortDirection,
    handleSort,
  } = useVideos({})

  const {
    handleClick,
    handleEditClick,
    handleAddClick,
  } = useNavigation({
    history,
    url: "/videos",
  })

  const {
    selected,
    selectedIds,
    handleSelect,
    handleClear,
    handleSelectAll,
    totalSelected,
  } = useSelected()

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleDeleteClick = async (video) => {
    await deleteVideo(video, "/api/admin/video/captured")
    showAlertSuccess("Video has been deleted")
    reloadVideos("/api/video/captured")
  }

  useEffect(() => {
    findVideos(
      {
        ...query,
        sort_key: sortKey,
        sort_direction: sortDirection,
      },
      page,
      false,
      "/api/video/captured"
    )
  }, [query, page, sortKey, sortDirection])
  console.log(videos)

  return (
    <>
      <Subheader
        title="Videos"
        // buttons={[
        //   <Button
        //     variant="contained"
        //     startIcon={<Add />}
        //     onClick={handleAddClick}
        //     color="primary"
        //   >
        //     Video
        //   </Button>,
        // ]}
      />
      <Container maxWidth="lg">
        <>
          <Paper>
            {/* <SearchForm
              isLoading={isLoading}
              query={query}
              handleSearch={findVideos}
            /> */}
            {!isEmpty ? (
              <TableContainer
                component={Paper}
                elevation={0}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEADERS.filter(
                        (h) => !h.hidden
                      ).map((header, idx) => (
                        <SortableTableHeader
                          key={idx}
                          handleSort={handleSort}
                          value={`videos.${header.value}`}
                          sortable={header.sortable}
                          sortKey={sortKey}
                          sortDirection={
                            sortDirection
                          }
                        >
                          {header.label}
                        </SortableTableHeader>
                      ))}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {videos.map((video, idx) => (
                      <VideoItem
                        key={idx}
                        video={video}
                        isSelectable={false}
                        selectedIds={selectedIds}
                        handleSelect={handleSelect}
                        handleClick={handleClick}
                        handleDeleteClick={
                          handleDeleteClick
                        }
                        handleEditClick={
                          handleEditClick
                        }
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Placeholder
                icon={<Search />}
                title="No videos"
                subtitle="Please try another search"
              />
            )}
          </Paper>
          {!isEmpty && (
            <Box my={1}>
              <Pagination
                onChange={(ev, page) =>
                  paginate(page)
                }
                color="primary"
                size="small"
                shape="rounded"
                page={page}
                count={numPages}
              />
            </Box>
          )}
        </>
      </Container>
    </>
  )
}

export default VideoList
