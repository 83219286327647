import { useState } from "react"

const useUploadFileInMemory = () => {
  const [memoryFile, setFile] = useState()

  const handleUploadFile = async (
    file,
    attributeName
  ) => {
    // console.log(file)
    // console.log(memoryFile)
    const url = await getUrlFromFile(file)
    // console.log(url)
    setFile({
      ...memoryFile,
      [attributeName]: {
        file,
        url: url,
      },
    })
  }

  const removeFile = () => setFile()

  return {
    memoryFile,
    removeFile,
    handleUploadFile,
  }
}

export default useUploadFileInMemory

function getUrlFromFile(file) {
  return new Promise((response) => {
    const reader = new FileReader()
    reader.addEventListener("load", () => {
      response(reader.result)
    })
    reader.readAsDataURL(file)
  })
}
