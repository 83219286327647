import api from "api"
import React, { useState } from "react"

const useNationalities = (props) => {
  const [nationalities, setNationalities] = useState()

  const findNationalities = async () => {
    const res = await api.get(`/api/nationalities`)
    if (res.data) {
      // Map response from {id: "", name: ""} to {value: "", label: ""}
      const mapped = []
      for(let i = 0; i < res.data.length; i++) 
      {
        mapped.push({
          value: res.data[i].id,
          label: res.data[i].name
        })
      }
      setNationalities(mapped)
    }
  }

  return {
    nationalities,
    findNationalities
  }
}

export default useNationalities
