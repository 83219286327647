import React, { useState } from "react"
import api from "api"
import {
  useAlerts,
  useLoaders,
  useResource,
} from "hooks"
import { validateVideo } from "validations/videos"

const useCoachFeedback = ({ id, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } =
    useAlerts()
  // const [allFeedbackFiltered, setAllFeedbackFiltered] = useState([])
  const {
    resourceId: coachFeedbackId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: coachFeedback,
    resources: allCoachFeedback,
    findOne: findCoachFeedback,
    findMany: findAllCoachFeedback,
    update: updateCoachFeedback,
    create: createCoachFeedback,
    save: saveCoachFeedback,
    destroy: deleteCoachFeedback,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    refresh: reloadCoachFeedback,
    reloadMany: reloadAllCoachFeedback,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    paginate,
    setPage
  } = useResource({
    id: id,
    url: "/api/video/captures",
    name: "video",
  })

  /**
   * Gets data entered by the user on the web app when requesting feedback
   * @returns 
   */
  const findFeedback = async (id) => {
    let resp = await api.get(`/api/admin/video/feedback/${id}`)
    console.log(resp)
    return resp.data
  }

  const rateFeedback = async (resource) => {
    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //   },
    // }

    // const res = await api.post(
    //   "/api/admin/feedback",
    //   formData,
    //   config
    // )






    try {
      showLoading()
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      }

      let formData = new FormData()
      for (const item in resource) {
        formData.append(
          item,
          resource[item]
        )
      }
      // if (files && Object.keys(files).length) {
      //   for (const key in files) {
      //     formData.append(
      //       key,
      //       files[key].file
      //     )
      //   }
      // }
      
      // for (var key of formData.entries()) {
      //   console.log(key);
      // }

      const res = await api.post(
        "/api/admin/feedback",
        formData,
        config
      )

      hideLoading()
      return res.data
    } catch (e) {
      showAlertError("There was an issue updating")
    }
  }

  /**
   * 
   * @param {*} filter 
   * @param {*} page 
   * @returns 
   */
  // const findAllFeedbackFilter = async (filter, page = 1) => {
  //   let resp = await api.get(`/api/admin/feedback?filter=${filter}&page=${page}&per_page=3`)
  //   setAllFeedbackFiltered(resp.data.data);
  // }

  return {
    coachFeedbackId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    coachFeedback,
    allCoachFeedback,
    findCoachFeedback,
    findAllCoachFeedback,
    saveCoachFeedback,
    updateCoachFeedback,
    createCoachFeedback,
    deleteCoachFeedback,
    uploadFile,
    deleteFile,
    findFeedback,
    handleChange,
    rateFeedback,
    handleChangePage,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    reloadCoachFeedback,
    reloadAllCoachFeedback,
    paginate,
    setPage
    // findAllFeedbackFilter,
    // allFeedbackFiltered
  }
}

export default useCoachFeedback
