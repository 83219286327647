import { Paper } from "@material-ui/core"
import { List } from "immutable"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import {
  DragDropContext,
  Draggable,
  Droppable,
} from "react-beautiful-dnd"

const SortableList = ({
  id,
  list,
  ListContainer = List,
  ListItem,
  onDragEnd,
}) => {
  const sortListContainer = useRef(null)

  const _onDragEnd = (result) => {
    if (!result.destination) {
      return list
    }
    const items = reorder(
      list,
      result.source.index,
      result.destination.index
    )
    onDragEnd(items)
  }

  return (
    <DragDropContext onDragEnd={_onDragEnd}>
      <Droppable droppableId={id}>
        {(provided) => {
          return (
            <Paper
              ref={sortListContainer}
              style={{
                minHeight:
                  sortListContainer?.current
                    ?.clientHeight,
              }}
            >
              <ListContainer
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {list.map((item, i) => (
                  <Draggable
                    key={item.id}
                    draggableId={String(item.id)}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps
                            .style
                        )}
                      >
                        <ListItem item={item} />
                      </div>
                    )}
                  </Draggable>
                ))}
              </ListContainer>
            </Paper>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

SortableList.propTypes = {
  id: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      [PropTypes.string]: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]),
    })
  ),
  ListContainer: PropTypes.node,
  ListItem: PropTypes.node.isRequired,
  onDragEnd: PropTypes.func,
}

export default SortableList

const getItemStyle = (_, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
})

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result.map((item, index) => ({
    ...item,
    position: index + 1,
  }))
}
