import React from "react"
import { useMenu } from "hooks"
import {
  Hidden,
  Button,
  IconButton,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Avatar,
  Checkbox,
} from "@material-ui/core"
import { Image, Label } from "components"
import { MoreHoriz } from "@material-ui/icons"

const VideoItem = ({
  video,
  isSelectable,
  selectedIds = [],
  handleSelect,
  handleClick,
  handleEditClick,
  handleDeleteClick,
  ...props
}) => {
  const {
    open,
    anchorEl,
    handleToggleMenu,
    handleClose,
  } = useMenu()

  const renderMenu = () => (
    <Menu
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
    >
      {/* <MenuItem
        onClick={(e) => handleEditClick(video, e)}
      >
        Edit
      </MenuItem> */}
      <MenuItem
        onClick={(e) => handleDeleteClick(video, e)}
      >
        Delete
      </MenuItem>
    </Menu>
  )

  const isSelected = selectedIds.includes(video.id)
      console.log(video)
  return (
    <TableRow>
      {isSelectable && (
        <TableCell>
          <Checkbox
            checked={isSelected ? true : false}
            onChange={() => handleSelect(video)}
            name="video"
            color="primary"
          />
        </TableCell>
      )}
      <TableCell>
        <Button onClick={() => handleClick(video)}>
          <Image
            src={video.thumbnail_url}
            variant="rounded"
            size={80}
          />
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(video)}>
          {video.description}
        </Button>
      </TableCell>
      <TableCell>
        {video?.tags?.map((tag, i) => (
          <Label key={i} label={tag.name} />
        ))}
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={(e) => handleToggleMenu(e)}
        >
          <MoreHoriz />
          {renderMenu()}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default VideoItem
