import React from "react"
import { Badge, colors } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  online: {
    height: 8,
    width: 8,
    backgroundColor: colors.green[500],
  },
  offline: {
    height: 8,
    width: 8,
    backgroundColor: colors.purple[500],
  },
}))

const OnlineStatusBadge = ({
  online = "true",
  children,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Badge
      {...props}
      overlap="rectangular"
      variant="dot"
      classes={{
        dot:
          online === "false"
            ? classes.offline
            : classes.online,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      color="secondary"
    >
      {children && children}
    </Badge>
  )
}

export default OnlineStatusBadge
