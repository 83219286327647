import React from "react"
import {
  Box,
  Typography,
  withWidth,
  makeStyles,
} from "@material-ui/core"
import { useLocation } from "react-router-dom"

const Subheader = ({
  title,
  buttons,
  width,
  ...props
}) => {
  const classes = useStyles()
  const isMobile = ["xs", "sm"].includes(width)

  const path = useLocation()

  return (
    <Box className={classes.box} mb={4} p={1}>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box ml={isMobile ? 6 : 3} mt={1.5}>
          <Typography
            color="textPrimary"
            variant="h4"
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        display="flex"
        justifyContent="flex-end"
      >
        {buttons &&
          buttons.map((button, i) => (
            <Box m={1} key={i}>
              {button}
            </Box>
          ))}
      </Box>
    </Box>
  )
}
export default withWidth()(Subheader)

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.common.border}`,
  },
}))
