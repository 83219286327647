import React, { useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useTrainers, useAlerts } from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import { Subheader } from "components"
import TrainerDetails from "containers/trainers/TrainerDetails"

const TrainerShow = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const {
    trainerId,
    isLoading,
    isLoaded,
    trainer,
    findTrainer,
  } = useTrainers({
    id: id,
  })

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleEditClick = () =>
    history(`/trainers/${id}/edit`)

  useEffect(() => {
    if (id) findTrainer(id, "/api/trainer")
  }, [])
  console.log(trainer)
  return (
    <>
      <Subheader
        title={
          isLoading ? "Loading..." : trainer.name
        }
        buttons={[
          <Button
            onClick={handleEditClick}
            color="primary"
            variant="contained"
          >
            Edit
          </Button>,
        ]}
      />
      <Container>
        <Paper>
          <Box p={4}>
            <TrainerDetails trainer={trainer} />
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default TrainerShow
