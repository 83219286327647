import React, { useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useUsers, useForm, useAlerts } from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { Check } from "@material-ui/icons"
import { Subheader } from "components"
import UserForm from "containers/users/UserForm"
import { validatePassword, validateUser } from "validations/users";

const UserNew = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const {
    userId,
    isLoading,
    isLoaded,
    user,
    handleChange,
    createUser,
    findUser,
    countries,
    findCountries,
    nationalities,
    findNationalities
  } = useUsers({ id: id })

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleSaveClick = async () => {
    const valid = validateUser(user)
    const validPassword = validatePassword(user.password, user.password_confirmation)

    if(validPassword.isValid == false) {
      validPassword.messages.map((m) => showAlertError(m))
    } else if (valid.isValid) {
      user.agent = user.agent ? 1 : 0
      user.contract = user.contract ? 1 : 0
      console.log(user)
      await createUser(user)
      showAlertSuccess("User has been created")
      // console.log('REMOVE THIS')
      history("/users")
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  useEffect(() => {
    if (id) findUser(id)
    findCountries()
    findNationalities()
  }, [])

  return (
    <>
      <Subheader
        title="Add Users"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>,
        ]}
      />
      <Container>
        <Box p={4}>
          <UserForm
            user={user}
            countries={countries}
            nationalities={nationalities}
            handleChange={handleChange}
            editPage={false}
          />
          <Box py={2}>
            <Button
              startIcon={<Check />}
              color="primary"
              variant="contained"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default UserNew
