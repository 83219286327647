import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCourses,
  useForm,
  useAlerts,
  useUploadFileInMemory,
} from "hooks"
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core"
import { Check } from "@material-ui/icons"
import {
  FileUploadInput,
  ImageUploader,
  Subheader,
  VideoUploader,
} from "components"
import CourseForm from "containers/courses/CourseForm"
import { validateCourse } from "validations/courses"
import Axios from "axios";
import { FormatJsonMultiSelectFieldValues } from "lib/helpers";
import usePositions from "hooks/usePositions";
import useCategories from "hooks/useCategories";
import useFilterTag from "hooks/useFilterTag";

const CourseNew = ({ ...props }) => {
  const history = useNavigate()
  const { id } = useParams()

  const { memoryFile, removeFile, handleUploadFile } =
    useUploadFileInMemory()

  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const {
    course,
    handleChange,
    createCourse,
    findCourse,
  } = useCourses({ id: id })

  const {
    categories,
    findCategories
  } = useCategories({ id: id })

  // const handleSaveClick = async () => {
  //   const valid = validateCourse(course)
  //   if (valid.isValid) {
  //     course.what_you_need = JSON.stringify(course.what_you_need)
  //     course.category = JSON.stringify(course.category)
  //     await createCourse(course, "/api/admin/courses/create")
  //     showAlertSuccess("Course has been created")
  //     console.log("REMOVE THE COMMENT FROM HERE")
  //     // history("/courses")
  //   } else {
  //     valid.messages.map((m) => showAlertError(m))
  //   }
  // }

  const [courseDTO, setCourseDTO] = useState()

  const {
    positionId,
    isLoading,
    isLoaded,
    positions,
    findPositions,
  } = usePositions({
    id: id,
  })

  const {
    filterTagId,
    filterTags,
    findFilterTags,
  } = useFilterTag({
    id: id,
  })

  const handleSaveClick = async () => {
    const valid = validateCourse(course, memoryFile)
    console.log(course)

    console.log(memoryFile)

    if (valid.isValid) {
    // if (true) {
      
      course.what_you_need = Array.isArray(course.what_you_need) ? course?.what_you_need.join(',') : course.what_you_need
      // course.category = FormatJsonMultiSelectFieldValues(course.category)
      let categoriesArr = []

      if(Array.isArray(course.category)) {

        for(let i = 0; i < course.category.length; i++) {
          console.log("call here")
          categoriesArr[`category[${i}]`] = course.category[i]

        }
      } 


      // course.category = Array.isArray(course) ? course?.category.join(',') : course.category

      // course.suggested_training = course.suggested_training === 'true'
      // course.featured = course.featured === 'true'
      // course.premium = course.premium === 'true'
      // course.active = course.active === 'true'

      // Mandatory to make FormData and Laravel API work together for boolean values
      course.suggested_training = course.suggested_training ? 1 : 0
      course.featured = course.featured ? 1 : 0
      course.premium = course.premium ? 1 : 0
      course.active = course.active ? 1 : 0

      // delete courseDTO.category
      let x = {
        ...courseDTO,
        ...categoriesArr
      }

      delete x.category

      console.log(x)

      setCourseDTO(x)
      console.log(courseDTO)

      await createCourse(x, memoryFile, "/api/admin/courses/create")
      showAlertSuccess("Course has been created")
      // console.log("REMOVE THE COMMENT FROM HERE")
      history("/courses")
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  useEffect(() => {
    if (id) findCourse(id)
    findPositions()
    findCategories()
    findFilterTags()
  }, [])

  useEffect(() => {
    setCourseDTO(course)
  }, [course])

  // useEffect(() => {
  //   if(Array.isArray(positions) && positions.length > 0) {
  //     let tempPos = []
  //     positions.forEach((position) => {
  //       tempPos.push({
  //         value: position.id,
  //         label: position.name
  //       })
  //     })

  //     setformattedPositions(tempPos)
  //   }
  //   console.log('caaall')
  //   console.log(positions)
  //   console.log(formattedPositions)
  // }, [positions])

  const handleDelete = async () => {
    removeFile()
  }

  const images = [
    { value: "image", label: "Image" },
    { value: "hero_image", label: "Hero Image" },
    // { value: "preview_video", label: "Video Preview" },
    // { value: "image_url", label: "Image 1" },
    // { value: "image2_url", label: "Image 2" },
    // { value: "image3_url", label: "Image 3" },
    // { value: "image4_url", label: "Image 4" },
    // { value: "web_image_url", label: "Web Image 1" },
    // { value: "web_image2_url", label: "Web Image 2" },
    // { value: "web_image3_url", label: "Web Image 3" },
    // { value: "web_image4_url", label: "Web Image 4" },
    // { value: "web_image5_url", label: "Web Image 5" },
  ]

  console.log(course)
  console.log(positions)

  return (
    <>
      <Subheader
        title="Add Courses"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>,
        ]}
      />
      <Container>
        <Box p={4}>
          <center>
            {images.map((image, index) => (
              <ImageUploader
                key={index}
                label={`Upload ${image.label}*`}
                imageUrl={
                  course[image.value] ||
                  (memoryFile &&
                    memoryFile[image.value]?.url)
                }
                handleUpload={(file) =>
                  handleUploadFile(file, image.value)
                }
                handleDelete={() =>
                  handleDelete(image.value)
                }
              />
            ))}
            <VideoUploader
              label="Upload video*"
              videoUrl={
                course.preview_video ||
                memoryFile?.preview_video?.url
              }
              handleUpload={(file) =>
                handleUploadFile(file, "preview_video")
              }
              handleDelete={() =>
                handleDelete("preview_video")
              }
            />
          </center>

          <CourseForm
            course={course}
            handleChange={handleChange}
            positions={positions}
            categories={categories}
            filterTags={filterTags}
          />
          <Box py={2}>
            <Button
              startIcon={<Check />}
              color="primary"
              variant="contained"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default CourseNew
