import React from "react"
import {
  Avatar,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core"
import { Photo } from "@material-ui/icons"

const CourseItem = ({
  course,
  handleClick,
  ...rest
}) => {
  return (
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <Avatar
          size={80}
          variant="rounded"
          src={course.image_url}
        >
          <Photo />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={course.title}
        secondary={course.category}
      />
    </ListItem>
  )
}

export default CourseItem
