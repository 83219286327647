import React, { useEffect, useState } from "react"
import {
  alpha,
  Typography,
  InputBase,
  makeStyles,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

const Autosuggest = ({
  value,
  options,
  label,
  name,
  placeholder = "Select",
  multiselect = false,
  handleChange,
  handleInputChange,
  editPage,
  ...props
}) => {
  const classes = useStyles()
  // const [selected, setSelected] = useState({
  //   label: "",
  //   value: null,
  // })
  
  const [selected, setSelected] = useState(undefined)
  const [initial, setInitial] = useState(true)
  const handleOnChange = (event, newValue) => {
    // console.log("handleonchange/setinitial(false)")
    setSelected(newValue)
    handleChange({
      target: {
        name: name,
        value: newValue?.value,
      },
    })
  }

  // useEffect(() => {
  //   if (typeof value != "object" && initial == true && options != undefined) {
  //     console.log("first if");
  //     if(selected !== undefined && selected.label != null)
  //     setSelected(options?.find((c) => c.value == value))
  //     // setInitial(false)
  //   } else {
  //     console.log('second if')
  //     // setSelected(value)
  //   }
  // }, [value])


    // useEffect(() => {
    //   console.log('useEffect')
    //   console.log(value)
    //   if(value !== undefined) {
    //     setSelected(options?.find((c) => c.value == value))
    //   }
    // }, [value])


    // useEffect(() => {
    //   if (typeof value != "object" && value != undefined && initial == true) {
    //     /**
    //      * Sometimes options is not fetched on time and is evaluated as undefined.
    //      * Not the best solution but works for now.
    //      */
  
    //     setSelected(
    //       options?.find((c) => c.value == value)
    //     )

    //     if(selected != undefined) {
    //       setInitial(false);
    //     }
        
    //   } else {
    //     setSelected(value)
    //   }
    // }, [value])

    // This useEffect is used only for edit pages to update fields with values from db
    useEffect(() => {
      // console.log('useEffect')
      // console.log(value)
      // console.log(value !== undefined)
      // console.log(value !== "")
      // console.log(Array.isArray(value) === false)
      // console.log(editPage == true)
      // console.log(initial == true)
      // console.log(Array.isArray(options) && options.length > 0)
      // console.log(options)
      if(value !== undefined && value !== "" && Array.isArray(value) === false && editPage == true && initial == true && options !== undefined && Array.isArray(options) && options.length > 0) {
        // console.log('inside if')
        // console.log(value)
        // console.log(options?.find((c) => c.value == value))
        // console.log(options)
        // console.log(typeof value)
        setSelected(
          options?.find((c) => c.value == value)
        )
        // console.log(options)
        setInitial(false)
      }
    }, [options, value]);
  // console.log(value)
  return (
    <div className={classes.container}>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.label}
      >
        {label}
      </Typography>
      <Autocomplete
        multiple={multiselect}
        disableCloseOnSelect={multiselect}
        classes={{
          paper: classes.paper,
          option: classes.option,
          popperDisablePortal:
            classes.popperDisablePortal,
        }}
        // value={selected || null}
        value={selected || []}
        onChange={(event, newValue) => {
          handleOnChange(event, newValue)
        }}
        onInputChange={(event, newInputValue) => {
          handleInputChange &&
            handleInputChange(newInputValue)
        }}
        noOptionsText="No options"
        clearOnBlur
        handleHomeEndKeys
        options={!options ? [{label:"Loading...", value:-1}] : options }
        getOptionLabel={(option) => option.label ? option.label : ''}
        getOptionSelected={(option, value) =>
          option?.label == value?.label
        }
        renderInput={(params) => (
          <InputBase
            placeholder={placeholder}
            ref={params.InputProps.ref}
            inputProps={{
              ...params.inputProps,
              autoComplete: "none",
            }}
            className={classes.inputBase}
          />
        )}
      />
    </div>
  )
}

export default Autosuggest

const useStyles = makeStyles((theme) => ({
  root: {},
  iconSelected: {
    height: 20,
    width: 20,
  },
  inputBase: {
    padding: 0,
    width: "100%",
    "& input": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      appearance: "none",
      padding: theme.spacing(1.5, 1),
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      transition: theme.transitions.create([
        "border-color",
        "box-shadow",
      ]),
      border: "1px solid #ced4da",
      fontSize: 15,
      "&:focus": {
        boxShadow: `${alpha(
          "#999999",
          0.25
        )} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.light,
      },
    },
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(1),
    color: "#586069",
    fontSize: 15,
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  label: {
    marginBottom: 0,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
}))
