import React from "react"
import { AsyncAutosuggest } from "components"

const CourseAutosuggest = ({
  value,
  label,
  handleChange,
  editPage
}) => (
  <AsyncAutosuggest
    name="course_id"
    label={label}
    value={value}
    displayField="title"
    remoteUrl="/api/courses/browse?per_page=9999"
    placeholder="Select"
    handleChange={handleChange}
    editPage={editPage}
  />
)

export default CourseAutosuggest
