import React from "react"
import {
  Button,
  TableHead,
  TableCell,
} from "@material-ui/core"
import {
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons"

const SortableTableHeader = ({
  value,
  sortKey,
  sortable = true,
  sortDirection,
  handleSort,
  children,
}) => {
  let isActive = sortKey === value ? true : false

  const renderIcon = () => {
    if (!sortable || !isActive) return null
    return sortDirection == "asc" ? (
      <ExpandMore />
    ) : (
      <ExpandLess />
    )
  }
  // console.log(value)
  return (
    <TableCell align="left" sortDirection="asc">
      <Button
        color="primary"
        active={sortKey === value}
        endIcon={renderIcon()}
        onClick={() => sortable && handleSort(value)}
      >
        {children}
      </Button>
    </TableCell>
  )
}

export default SortableTableHeader
